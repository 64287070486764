import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { apiFetch } from '../helpers/APIHelper';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';
import SpecialFieldEditor from '../components/SpecialFieldEditor';
import FizzLogoOptions from '../components/FizzLogoOptions';
import Spinner from '../components/Spinner';
import { relativeTimeThreshold } from 'moment';


export default class EditFizzTextModal extends Component {

	constructor(props) {
		super(props);

		this.state={
			loading:true,
			error: '',
			selectedLogo: null,		// Set in callback from FizzLogoOptions _selectedLogoChange
			contactFields: [], 		// Set in callback from FizzLogoOptions _selectedLogoChange
			hasLogoContacts: false, // Set in getData + Set in callback from FizzLogoOptions _selectedLogoChange
		}
	}

	componentWillMount() {
		this.getData();
	}
	componentWillReceiveProps(nextProps){
		if( this.props.fizzId != nextProps.fizzId ) {
			this.getData();
		}
	}

	getData() {
		this.setState({
			loading:true
		})
		apiFetch('/rest/fizz/'+this.props.fizzId+'/edit?cache='+Math.round(Math.random()*100000000),{
			method: 'GET',
		}, (json)=>{
			this.setState({
				loading:false,

				savedValue: JSON.parse(JSON.stringify(json)),
				logo: json.logo,
				specialFields: json.specialFields,
				specialFieldsToSend: this.getSpecialFields(json.specialFields),

				hasLogoContacts: json.hasLogoContacts
			});
		}, (error)=>{
			console.log(error)
			this.setState({
				error: error.message
			})

		});
	}


	save = () => {
		var fields = this.state.specialFieldsToSend;
		if( this.state.selectedLogo && this.state.hasLogoContacts && this.state.contactFields ) {
			fields = fields.concat( this.getSpecialFields(this.state.contactFields) );
		}

		this.setState({
			actionLoading: true,
			actionStatus: "Saving..."
		});
		apiFetch('/rest/fizz/'+this.props.fizzId,{
			method: 'PUT',
			data:{
				specialFields: fields,
      			logoTheme: this.state.selectedLogo ? this.state.selectedLogo.id : null,
				themeAnimation: this.state.taId,
				pt:this.state.pt
			}
		}, (json) => {

			this.setState({
				actionLoading: false,
				actionStatus: null,

				savedValue: JSON.parse(JSON.stringify({
					logo: this.state.logo,
					specialFields: this.state.specialFields,
					hasLogoContacts: this.state.hasLogoContacts
				}))
			});

			if( this.props.onSave ) {
				this.props.onSave(json);
			}

		}, (error) => {
			this.setState({
				actionLoading: false,
				actionStatus: error+""
			});
		});
	}



	getSpecialFields(groups) {
		var fields = [];
		for (var i = 0; i < groups.length; i++) {
			var group = groups[i];
			fields = fields.concat( group.items );
		}
		return fields;
	}

	onSpecialFieldsChange = ( groups )=> {
		this.setState({
			specialFieldsToSend: this.getSpecialFields(groups)
		});
	}

	selectedLogoChange = ( obj ) => {
		console.log( "selectedLogoChange", obj );
		this.setState({
			selectedLogo: obj.selectedLogo,
			contactFields: obj.contactFields,
			hasLogoContacts: obj.hasLogoContacts,
		});
	}

	cancel = () => {


		var old = JSON.parse(JSON.stringify(this.state.savedValue));
		this.setState({
			//Revert
			logo: old.logo,
			specialFields: old.specialFields,
			hasLogoContacts: old.hasLogoContacts

		})
		if( this.props.onClose ) {
			this.props.onClose();
		}
	}

	render() {

		return (
			<Modal show={this.props.show} onHide={this.cancel} >
				<Modal.Body>

					{this.state.loading ?
						<div className="text-center">
							<Spinner className="black"/>
						</div>
					:
						<div>
							<SpecialFieldEditor 
								specialFields={this.state.specialFields} 
								onSpecialFieldsChange={this.onSpecialFieldsChange.bind(this)} 
								/>
							<FizzLogoOptions 
								hasLogoContacts={this.state.hasLogoContacts} 
								availableLogos={this.state.logo} 
								onSelectedLogoChange={this.selectedLogoChange} 
								/>
						</div>
					}
				</Modal.Body>
				{ !this.state.loading &&
					<Modal.Footer>
						<span>{this.state.actionStatus}</span>
						<Button disabled={this.state.actionLoading} variant="primary" onClick={this.save}  >
							Save Changes
						</Button>
						<Button variant="secondary" onClick={this.cancel} >
							Cancel
						</Button>
					</Modal.Footer>
				}
			</Modal>
		)

	}
}