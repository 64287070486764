import React, { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import LoadingBar from "../components/LoadingBar";
import { apiFetch, generateDeviceId, saveSession  } from "../helpers/APIHelper";
import { getInviteFizzUUID, isMobile } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import LandingVideo from "../components/LandingVideo";
import PreferAnApp from "../components/PreferAnApp";
import ProgressUploader from "../helpers/ProgressUploader";
import Plyr from "react-plyr";
import StandardModal from "../modal/StandardModal";
import BannerBar from "../components/BannerBar";
import Constants from "../helpers/Constants";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import Notify from "../components/Notify";
import { toastManager } from "../components/Toaster";
import { DropdownToggle } from "reactstrap";
import AddButtonGroup from "../components/AddButtonGroup";
              
export default class ViewInvite extends Component {


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      fizzId: null,
      message: '',
      isLocked: false,
      uploadingList:[],
      hasErrors: false,
      errorsCleared: false
    }
  }

  componentDidMount() {
    this._getData();

    document.title = 'Contribute to a Video | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "Upload photos or a video message to contribute to a group video for your friend or loved one.";
  }

  _getData = () => {
    this.setState({
      loading: true
    });

    var fizzUUID = getInviteFizzUUID();
    generateDeviceId();

    apiFetch(`/rest/invite/${fizzUUID}/fizz?cache=${Math.round(Math.random() * 100000000)}`, {
      method: 'GET',
    }, (json) => {
      console.log(json);
      this.setState({
        loading: false,
        fizzId: json.id,
        fizzImg: json.img,
        message:json.message,
        name: json.name,
        isLocked: json.isLocked,
      });

      saveSession(json);

      TrackingHelper.trackPage();
    }, (error) => {
      this.setState({
        loading: false,
        error: error+""
      })
      TrackingHelper.trackPage();
    });
  }


  onFileUploadChange = (event) => {
    var acceptedFiles = Array.from(event.target.files);
    console.log("Select", acceptedFiles);
    this.startUpload(acceptedFiles);
    event.target.value = null;
  };

  oneDone = (uploader) => {
    // If Error uploading, inform user of error
    if ( uploader.response.status != 'success' ){

      console.log("Error: ", uploader.response );
      this.setState( { hasErrors: true } );
    } else {
      console.log("Upload Done: ", uploader.response);
    }

    var allDone = true;
    for (var i = 0; i < this.state.uploadingList.length; i++) {
      if ( !this.state.uploadingList[i].isDone() ) {
        allDone = false;
        break;
      }
    }
    this.setState({
      uploadingList: this.state.uploadingList,
    });

    if( !allDone ) {
      this.startNext();
    } else {
      this.allDone();
    }
  }

  allDone = ()=>{
    if( !this.state.hasErrors ){
      window.location.href = "/view-invite-thank-you";
    }
  }



  startNext = ()=>{
    console.log("startNext")
    for (var i = 0; i < this.state.uploadingList.length; i++) {
      if (this.state.uploadingList[i].status == "idle") {
        // Start one that is idle
        console.log("startNext", this.state.uploadingList[i])

        var started = this.state.uploadingList[i].start("POST", `/rest/fizz/${this.state.fizzId}/add-clip?type=invite&uuid=${getInviteFizzUUID()}` );
        console.log( "Started", started);
        started.then(this.oneDone)
          .catch(this.oneDone);
        break;
      }
    }
  }

  startUpload = (files) => {

    this.setState({
      isUploading:true
    })


    var uploads = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      let uploader = new ProgressUploader(file, (uploader) => {
        // Progress
        console.log(uploader.getPercent());
        // Call set state to refresh
        this.setState({
          uploadingList: this.state.uploadingList
        });
      });
      uploader.key = Math.random();
      uploader.loadImage();
      uploads.push(uploader);
    }

    this.setState({
      uploadingList: this.state.uploadingList.concat(uploads),
    },()=>{
      if( this.state.uploadingList.length>0 ) {
        this.startNext();
      }
    });
  };

  onHideUpload( uploader ) {
    let updatedList = [];
    if( this.state.uploadingList.length > 1 ){
      for( var h = 0; h < this.state.uploadingList.length; h++ ){
        if ( uploader === this.state.uploadingList[h] ){
          this.state.uploadingList.splice( h, 1 )
          updatedList = this.state.uploadingList;
         
        }
      }
    } 

    this.setState({ 
      errorsCleared: true,
      isUploading: false,
      hasErrors: false,
      uploadingList: updatedList
    });

  }

  openHowToVideo=()=>{
    this.setState({popVideo:"https://static.hallmarkvideogreetingcards.com/pub/how-to-video-greeting-card-v1-30cq.mp4"})
  }

  render() {

    let title = (
      <h1 className="headline-text">You’re invited!</h1>
    );
    let content = (
      <>
        <h5 className="subheadline mb-3">{this.state.message}</h5>
        
        { !this.state.isUploading && 

          <AddButtonGroup
            fizzId={this.state.fizzId}
            onFileUploadChange={this.onFileUploadChange.bind(this)}
            isInvite={true}
            primaryText={"ADD PHOTOS OR VIDEO"}
            errorsCleared={this.state.errorsCleared}
            />
        }

        {this.state.uploadingList.map((upload, index) => {
          {

            return (
                <div key={`upload-${upload.key}`} className="row align-items-center heading_video">
                  <div className="col-lg-10 col-8">
                    { upload.getStatusDisplay() != 'Error Uploading' ?
                      <>
                        <p>{upload.getStatusDisplay()}</p>
                        <LoadingBar percent={upload.getPercent()} />
                      </>
                    :
                      <Notify message={ upload.response.message } error="true" onHide={this.onHideUpload.bind(this, upload ) } />
                    }
                  </div>
                  <div
                    className="col-lg-2 col-4 text-right notSelectable"
                    key={`clip-${upload.key}`}
                  >
                    <div className="card card-img-top" >
                      <div
                        className="notSelectable box-480"
                        // style={
                        //   editVideos.id === clip.id
                        //     ? styles.selectBorder
                        //     : null
                        // }
                        unselectable="on"
                      >
                        {upload.img && (
                            <img 
                              className="box-480-item nonDraggableImage notSelectable uploading-video"
                              src={upload.img}/>
                        )}
                        {upload.video && (
                            <video 
                              className="box-480-item nonDraggableImage notSelectable uploading-video"
                              draggable={false}
                              unselectable="on"
                            >
                              <source src={upload.video} />
                            </video>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
            );
          }
        })}

      </>
    );

    let lockedTitle = (
      <>
        <h1 className="headline-text">This Video Greeting has already been finished</h1>

        <p className="subheadline">
          We’re sorry. This Video Greeting has been finished, and photos and videos can no longer be added.
        </p>
      </>
    );
    let lockedContent = (
      <>
        <div className="" >



        </div>
      </>
    );

    if( this.state.loading ) {
      return (
        <div className="screen-wrap" >
          <NavLogo />
          <BannerBar />
          <MainContent className="container">
            <Spinner float={true} />
          </MainContent>
          <div className="simple-footer">
            <Footer hideMobile={false} />
          </div>
        </div>
      );
    }


    return (
      <div className="screen-wrap" >
        <NavLogo />
        <BannerBar />
        <MainContent className="container pb-5 pt-3">


          { this.state.isLocked ? lockedTitle : title }

          <div className="row mt-3" >
            <div className="col-md-6 mb-4" >
                  { this.state.error ?
                    <p className="alert alert-danger">{this.state.error}</p>
                  :
                    ( this.state.isLocked ? lockedContent : content)
                  }
            </div>
            <div className="col-md-6" >
              <h2 className="headline-text mb-2">
                See how it works
              </h2>
              <div className="video-thumb w-100" onClick={this.openHowToVideo}  >
                <img className="w-100" 
                  src="https://static.hallmarkvideogreetingcards.com/pub/how-to-video-greeting-card-v1.jpg" />
              </div>
            </div>
          </div>

        </MainContent>

        <StandardModal
          size='lg'
          show={!!this.state.popVideo}
          onHide={()=>{ this.setState({popVideo:null}) }}
          closeBtnText={false} >
            <Plyr
              type="video" // or "vimeo"
              url={this.state.popVideo}
              options={{playsinline:true}}
              autoplay
              
            />
        </StandardModal>

        <LandingVideo />
        <div className="simple-footer">
          <Footer hideMobile={false} />
        </div>
      </div>
    );
  }
}
