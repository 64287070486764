import React, { Component } from 'react';
import MailIcon from "../assets/images/HMK-Icons/Icons_Mail_Regular.svg";
import SMSIcon from "../assets/images/HMK-Icons/Icons_Share_Share_iOS_Bold.svg";
import ShareIcon from "../assets/images/HMK-Icons/Icons_External_Link_Bold.svg";
import DownloadIcon from "../assets/images/HMK-Icons/Icons_Download_File_Regular.svg";
import MenuCloseIcon from "../assets/images/HMK-Icons/Icons_Menu_Close_Bold.svg";
import TextIcon from "../assets/images/HMK-Icons/Icons_Chat_Regular.svg";
import { Button, Modal } from "react-bootstrap";
import { getMailToLink, getSMSLink, isMobile, canShare, share } from "../helpers/URLHelper";
import { toastManager } from "../components/Toaster";



class SharePopup extends Component {

	constructor(props, info) {
		super(props, info);
	}

	onHide = () => {
		if( this.props.onHide ) {
			this.props.onHide();
		}
	}
  

	copyToClipboard=()=>{
		navigator.clipboard.writeText(this.props.info.shareUrl);
		toastManager.showToast({
			message: 'Copied to Clipboard!'
		})
	}

	shareAny=()=>{
		navigator.share({
		 	url: this.props.info.shareUrl
		}).then(() => {
		  // alert("Sent");
		}).catch((e)=>{
		  // Canceled or something else
		  // alert("Error"+e);
		})
	}


	render() {


		if( !this.props.show ||  !this.props.info) {
			return null;
		}

		var hasClipboard = navigator && navigator.clipboard && navigator.clipboard.writeText;

		var generalURL = this.props.info.shareUrl;

		var videoUrl = this.props.info.video;
		var downloadUrl = generalURL+"/download";

		var shareUrl = generalURL;
		
	    let smsLink = getSMSLink({body:shareUrl});
	    let emailLink = getMailToLink({body:shareUrl});


		return (
			<Modal
            show={this.props.show}
            onHide={this.onHide}
          	>
	            <Modal.Header className="text-left">
	              <div className="icon-box" onClick={this.onHide}>
	                <span className="close">
	                  <img src={MenuCloseIcon} alt="Close" />
	                </span>
	              </div>

	              <h3 className="headline">Let’s send your video!</h3>
	            </Modal.Header>

	            <Modal.Body>
					<div>


	                  { canShare() ?

	                      <button className="link blue-link btn-padded" onClick={this.shareAny}>
	                        <i className="icon-mask" style={{ WebkitMaskImage:"url("+ShareIcon+")"}} alt="Share Icon"></i>
	                        Share this video
	                      </button>
	                    :
	                    (<>

	                      <a href={emailLink} className="blue-link btn-padded">
			                <i className="icon-mask" style={{ WebkitMaskImage:"url("+MailIcon+")"}} alt="Email Icon"></i>
			                Email
			              </a>

	                      { smsLink && 

						  	<div>
							    <a className="blue-link btn-padded" href={smsLink}>
									<i className="icon-mask" style={{ WebkitMaskImage:"url("+TextIcon+")"}} alt="Copy Icon"></i>
									Text message
							    </a>
		                    </div>
	                      }
		              
						  { hasClipboard &&
						  	<div>
			                    <button className="link blue-link btn-padded" onClick={this.copyToClipboard} >
			                      <i className="icon-mask" style={{ WebkitMaskImage:"url("+ShareIcon+")"}} alt="Copy Icon"></i>
			                      Copy link to paste anywhere
			                    </button>
		                    </div>
		                  }


	                    </>)
	                  }

			              <div>
			              <a href={downloadUrl} download="Video.mp4" className="blue-link btn-padded tag-download-user">
			                <i className="icon-mask" style={{ WebkitMaskImage:"url("+DownloadIcon+")"}} alt="Download Icon"></i>
			                Download
			              </a>
			              </div>

		            </div>

	            </Modal.Body>
				<Modal.Footer>
					<button
					className="btn btn-outline-primary btn-block"
					onClick={this.onHide}
					>
					CANCEL
					</button>
				</Modal.Footer>
			</Modal>
		);
	}
 }

 export default SharePopup;