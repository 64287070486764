import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MainContent from "../components/MainContent";
import TrackingHelper from "../helpers/TrackingHelper";
import { Link } from "react-router-dom";
import loginImg from "../assets/images/video-greetings-digital-sign-in.jpg";

export class NotFound extends Component {

  componentDidMount(){

    document.title = 'Not Found | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    TrackingHelper.trackPage();
  }
  
  render() {
    return(
      <div className="screen-wrap">
        <NavBar/>
        <MainContent className="container bg-margin">


          <div className="row">
            <div className="col-md-6">
                
              <h1 className="headline-text">Page not found</h1>

              <h6 className="subheadline mb-3">We’re sorry, we couldn’t find the page you’re looking for.</h6>

              <Link
                to="/"
                className="btn btn-primary btn-block btn-lg-inline-block"
              >
                Go to the homepage
              </Link>

            </div>

            <div className="col-md-6 text-center d-none d-md-block">
              {/* { !this.state.isQRCard ?
                <img className="w-100 mt-4" src={loginImg} alt="Make Moments Last A Lifetime"/>
                :
                <img className="w-100 mt-4" src={loginImgPhysical} alt="Make Moments Last A Lifetime"/>
              } */}
              <img className="w-100 mt-4" src={loginImg} alt="Make Moments Last A Lifetime"/>
            </div>
          </div>



        </MainContent>
        <Footer hideMobile={true} />
      </div>
    )
  }

}

export default NotFound;