



export function getQRKey() {
  var match = window.location.pathname.match(/\/qr\/([a-zA-Z0-9]+)/)
  if( match && match.length==2 ) {
    var qrKey = match[1];
    return qrKey;
  }

  var match = window.location.pathname.match(/\/qr-setup\/([a-zA-Z0-9]+)/)
  if( match && match.length==2 ) {
    var qrKey = match[1];
    return qrKey;
  }
  return null;
}

export function getBaseURL( path ){
  var key = getQRKey();
  if( key ){
    return '/qr-setup/' + key + path;
  }
  return path;
}

export function getInviteFizzUUID() {
  var match = window.location.pathname.match(/\/in\/([a-zA-Z0-9]+)/)
  if( match && match.length==2 ) {
    var uuid = match[1];
    return uuid;
  }
  return null;
}

export function getFizzUUID() {
  var match = window.location.pathname.match(/\/vid\/([a-zA-Z0-9]+)/)
  if( match && match.length==2 ) {
    var uuid = match[1];
    return uuid;
  }
  return null;
}

export function isIOS() {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return true;
  } else {
    return navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform);
  }
}

export function isIpadOS() {
  return navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform);
}

export function isAndroid() {
  var isAndroid = false;
  if( navigator ) {
    isAndroid = /(android)/i.test(navigator.userAgent);
  }
  return !!isAndroid;
}

export function isMobile() {

  var iOS = null;
  var android = null;
  if( navigator ) {
    iOS = isIOS();
    android = isAndroid();
  }
  return iOS || android;
}

export function getSMSLink({body}) {

  var iOS = null;
  var android = null;
  if( navigator ) {
    iOS = isIOS();
    android = isAndroid();
  }

  var link = null;
  if( iOS ) {
    link = 'sms:&body=' + encodeURIComponent(body);
  }
  if( android )  {
    link = "sms:?body=" + encodeURIComponent(body);
  }
  return link;
  
}

export function getMailToLink({to='', subject, body}) {

  var params = [];
  if( subject ) {
    params.push( 'subject='+encodeURIComponent(subject) );
  }
  if( body ) {
    params.push( 'body='+encodeURIComponent(body) );
  }
  return `mailto:${to}?${params.join("&")}`;
}

export function canCopyText() {
  return !!navigator.clipboard;
}
export function copyText(text) {
  navigator.clipboard.writeText(text);
}

export function canShare() {
  if( !isMobile() ){
    return false;
  }
  return !!navigator.share
}

export function share(obj) {
//   if (navigator.share) {
//   navigator.share({
//     title: 'web.dev',
//     text: 'Check out web.dev.',
//     url: 'https://web.dev/',
//   })
//     .then(() => console.log('Successful share'))
//     .catch((error) => console.log('Error sharing', error));
// }
  return navigator.share(obj)
}

export function isBadQRReaderApp() {
  var userAgent = navigator.userAgent;
  if (userAgent.endsWith( 'AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148' ) ) {

    if( window.innerHeight < 670 ){
      
      return true;
    }
  } 


  return false;
  
}
