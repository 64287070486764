import React from 'react';


export default class NewAlertBubble extends React.Component {

  constructor(props) {
    super(props);


    var show = false;
    if( localStorage && localStorage.getItem ) {
      show = !localStorage.getItem(this.props.oneTimeKey);
    }


    this.state = {
      show:show
    }
  }

  click = ()=>{

    if( this.props.oneTimeKey ) {
      if( localStorage && localStorage.setItem ) {
        localStorage.setItem(this.props.oneTimeKey, true);
      }
    }
    this.setState({
      show:false
    })
    if( this.props.onClose ) {
      this.props.onClose();
    }
  }

  
  render() {

    if( !this.state.show ){
      return null;
    }

    return (
      <span className={'new-alert-bubble '+(this.props.align=='right'?'new-alert-bubble-right':'') + (this.props.className||'') } onClick={this.click} >
      	{this.props.children}
      </span>
    )
  }
}