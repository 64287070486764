import React, { useState, useEffect } from "react";
import { apiFetch } from "../helpers/APIHelper";
import { getQRKey, getBaseURL } from "../helpers/URLHelper";
import { Link } from "react-router-dom";

import NavLogo from "../components/NavLogo";
import MainContent from "../components/MainContent";
import QuickSignup from "../components/QuickSignup";
import StandardModal from "../modal/StandardModal";

const QRBoxAdd = (props) => {

  const [boxCardUserEmail, setBoxCardUserEmail] = useState(null);
  const [previousBoxCardFizzId, setPreviousCardFizzId] = useState(null);
  const [qrKey, setQrKey] = useState(null);
  const [themeAnimationId, setThemeAnimationId] = useState(null);
  const [showQuickSignupModal, setShowQuickSignupModal ] = useState(false);

  useEffect(() => {
    _getData();
  }, []);

  const _getData = () => {
    console.log(props);
    setQrKey(props.match.params.qrKey);
    setThemeAnimationId(props.match.params.themeAnimationId);
    apiFetch(`/rest/qr/box-set-add?qrKey=${qrKey}`, {
      method: 'GET',
    },
    json => {

      console.log(json);
      setBoxCardUserEmail(json.boxCardUserEmail);
      setPreviousCardFizzId(json.previousBoxCardFizzId);
    })
  }
  
  
  const onSignupSuccess = (props) => {
    setBoxCardUserEmail(props.email);
    setShowQuickSignupModal( false )
  }

  return (
    <div className="screen-wrap" >
      <NavLogo />
      <MainContent className="container bg-margin">
        <h3 className="headline-text text-md-center mt-5 mb-5">Start a new Video Greeting, or link an existing one to this boxed set.</h3>
        <div className="qr-box-cta-wrapper d-flex flex-column align-items-center py-3 mx-auto">
          <Link 
            className="btn btn-primary w-100" 
            to={{
              pathname: getBaseURL(`/create/${themeAnimationId}`),
              state: {isBoxSet: true}
            }}
          >
            Start a new video greeting
          </Link>
          <Link className="btn btn-outline-primary w-100" to={{pathname: getBaseURL("/list") }}>Link an existing video greeting</Link>
        </div>

        <div className="d-flex flex-column align-items-center my-4">
          { boxCardUserEmail ? ( 
            <small>You're signed in as: {boxCardUserEmail}</small>
          ) : (
            <small>You are not signed in. Sign in if you want to link this to an existing box set.</small>
          )}

          <button className="btn btn-link pl-0 blue-link" onClick={()=> setShowQuickSignupModal( true )}>
            <small>{ boxCardUserEmail && 'Not you? ' }Sign in to your account.</small>
          </button>
        </div>

          {/* QuickSign Modal */}
          <StandardModal
            show={showQuickSignupModal }
            onHide={()=>setShowQuickSignupModal(false)}
            closeBtnText={false}
            size="lg"
          >
            <QuickSignup successCallback={(props) => onSignupSuccess(props)} preventRedirect = {true} from='QRBoxAdd'/>
          </StandardModal>
        </MainContent>
    </div>
  )
}

export default QRBoxAdd;