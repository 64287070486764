import React, { useEffect, useState, useRef } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Modal, Button } from 'react-bootstrap';
import { apiFetch } from "../helpers/APIHelper";
import CommentForm from './CommentForm';

const UserComments = (props) => {

  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(true);
  const [activeParent, setActiveParent] = useState(null); //UUID
  const [activeComment, setActiveComment] = useState(null); //UUID
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [replyMode, setReplyMode] = useState(false);
  const parentRef = useRef(null);

  const actions = [
    {iconLabel: "fa-heart-o", text: "240"},
    {iconLabel: "fa-commenting-o", text: "Comment"},
    {iconLabel: "fa-share-square-o", text: "Share"},
    {iconLabel: "fa-download", text: "Download"}
  ];

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    if (activeParent) {
      parentRef.current = activeParent;
    }
  }, [activeParent]);

  const getComments = async () => {
    apiFetch(
      `/rest/fizz/${props.fizzUUID}/comment`,
      {
        method: "GET"
      },
      (json) => {
        if (json.status === "success") {
          setComments(json.comments);
        }
      }
    )
  }

  const postComment = async (comment) => {
    const payload = {
      method: "POST",
      data: comment
    };
    apiFetch(
      `/rest/fizz/${props.fizzUUID}/comment`,
      payload,
      (json) => {
        getComments();
        if (activeParent) {
          const prevParentElement = document.getElementById(`reply-container-${activeParent}`);
          unmountComponentAtNode(prevParentElement);
        }
        setReplyMode(false);
      }
    )
  }

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleReplyPrompt = (parentUUID, rootCommentUUID) => {
    setReplyMode(true);
    if (activeParent) {
      const prevParentElement = document.getElementById(`reply-container-${activeParent}`);
      unmountComponentAtNode(prevParentElement);
    }
    setActiveParent(rootCommentUUID);
    render(
      <CommentForm 
        parentUUID={parentUUID} 
        handleSubmit={postComment} 
        type="reply" 
        handleReplyCancel={handleReplyCancel} 
      />, 
      document.getElementById(`reply-container-${rootCommentUUID}`)
    );
  }

  const handleDeletePrompt = (commentUUID, isRoot) => {
    setActiveComment({
      uuid: commentUUID,
      isRoot: isRoot
    });
    setShowDeleteModal(true);
  }

  const deleteComment = async () => {
    apiFetch(
      `/rest/fizz/${props.fizzUUID}/comment/${activeComment.uuid}`,
      {
        method: "DELETE"
      },
      (json) => {
        getComments();
        setShowDeleteModal(false);
        setActiveComment(null);
      }
    )
  }

  const handleDisableComments = (commentsFlag) => {
    setShowComments(commentsFlag);
    if (commentsFlag) {
      getComments();
    }
    else {
      setComments([]);
    }
  }

  const handleReplyCancel = () => {
    if (parentRef.current) {
      const prevParentElement = document.getElementById(`reply-container-${parentRef.current}`);
      unmountComponentAtNode(prevParentElement);
    }
    setActiveParent(null);
    setReplyMode(false);
  }

  return (
    <div className="">
      <div className="d-flex justify-content-end">
        <button 
          className="btn btn-link disable-comments p-2 mt-2"
          onClick={() => {handleDisableComments(!showComments)}}
        >
          {showComments ? "Disable" : "Enable"} all comments
        </button>
      </div>
      <div className="comments-container">
        {comments.map((comment) => {
          return comment && (
            <div key={comment.uuid} className="comment-top-level my-3">
              <span className="d-flex align-items-center">
                <i className="fa fa-play-circle" style={{color: '#F4A56C'}} aria-hidden="true">
                </i>
                <div className="comment-author headline-text">
                  {comment.firstName} {comment.lastName}
                </div>
              </span>
              <p className="my-0">{comment.body}</p>
              <div className="d-flex ml-3">
                <button 
                  className="btn btn-link p-0 mr-3 reply-button"
                  onClick={() => {handleReplyPrompt(comment.uuid, comment.uuid)}}
                >
                  Reply
                </button>
                <button 
                  className="btn btn-link p-0 remove-button"
                  onClick={() => {handleDeletePrompt(comment.uuid, true)}}
                >
                  Remove
                </button>
              </div>
              {comment.childComments.map((reply) => {
                return reply && (
                  <div key={reply.uuid} className="comment-reply m-3">
                    <span className="d-flex align-items-center">
                      <i 
                        className="fa fa-play-circle" 
                        style={{color: '#F4A56C'}} 
                        aria-hidden="true"
                      >
                      </i>
                      <div className="comment-author headline-text">
                        {reply.firstName} {reply.lastName}
                      </div>
                    </span>
                    <p className="my-0">{reply.body}</p>
                    <div className="d-flex ml-3">
                      <button 
                        className="btn btn-link p-0 mr-3 reply-button"
                        onClick={() => {handleReplyPrompt(reply.uuid, comment.uuid)}}
                      >
                        Reply
                      </button>
                      <button 
                        className="btn btn-link p-0 remove-button"
                        onClick={() => {handleDeletePrompt(reply.uuid, false)}}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                )
              })}
              <div className="reply-container" id={`reply-container-${comment.uuid}`}></div>
            </div>
          )
        })}
      </div>
      <hr/>
      {!replyMode && 
        <CommentForm 
          handleSubmit={postComment} 
          type="comment" 
          handleReplyCancel={handleReplyCancel} 
        />
      }
      <Modal 
        size="lg" 
        dialogClassName="remove-comment-modal" 
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
      >
        <Modal.Header className="py-2">
          <h3 className="headline-text">Remove this comment?</h3>
        </Modal.Header>
        <Modal.Body className="py-2">
          <p>
            By tapping remove, this comment {
              (activeComment && activeComment.isRoot) && "and all its replies"
            } will no longer be visible to any user.
          </p>
          <div className="d-flex justify-content-center w-100">
            <Button variant="primary" className="mx-3" onClick={deleteComment}>
              Remove
            </Button>
            <Button 
              variant="outline-primary" 
              className="mx-3" 
              onClick={handleCloseDeleteModal}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default UserComments;