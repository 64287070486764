import React, { Component } from "react";

export default class SpecialField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error:"",
    };
  }

  onInputChange = (field, e) => {
    field.value = e.target.value;

    if( field.required==1 ){
      field.invalid = field.value? (field.value.length===0) : true;
    }

    this.setState({});
    if (this.props.onFieldChange) {
      this.props.onFieldChange(field);
    }
  };

  render() {
    var styles = {
      inputBox: {
        width: "100%",
        backgroundColor: "#efefef",
        color: "#343a40",
        borderRadius: "8px",
        border: "none",
        padding: "4px",
        paddingLeft: "8px",
        marginBottom: "15px",
      },
      changeMusicBox: {
        width: "95%",
        borderRadius: "0",
        border: "0.5px solid black",
      },
    };


    var title = this.props.fieldGroup.title;
    var items = this.props.fieldGroup.items;
    return (

      <div>
        {/* <div className="mb-4">
          <b>{this.state.title}</b>
        </div> */}
        {/* <br /> */}
        {items.map((field, index) => (
          <div className={"form-group "+(field.invalid?"invalid":"")} style={{ marginTop: "0rem" }}>
            <span className="has-float-label">
              <label>{title + (field.required==1?"*":"")}</label>

              {/* // <input
              //   type="text"
              //   // style={styles.inputBox}
              //   key={field.id}
              //   // placeholder={field.placeholder}
              //   onChange={this.onInputChange.bind(this, field)}
              //   value={field.value}
              // /> */}

              <input
                className="form-control"
                // type="text"
                onKeyPress={event => (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)}
                key={field.id}
                onChange={this.onInputChange.bind(this, field)}
                value={field.value}
                placeholder={field.placeholder}
                required={field.required==1}
                maxLength={255}
              />
            </span>
            {field.invalid && 
              <small className="text-danger">This field is required</small>
            }
            <small className="text-danger">{this.state.error}</small>
          </div>
        ))}
      </div>
    );
  }
}
