import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import menuclose from "../assets/images/HMK-Icons/Icons_Menu_Close_Bold.svg";
import Plyr from "react-plyr";


function LandingMailer ( props ) {

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      return (

        <>
          <section className="mailer-segment container-fluid container-pall1 container-fluid"> 
            <div className="text-center"> 
              <h3 className="headline-text mb-3">
                Did you receive this card in the mail?
              </h3>

              <h5 className="subheadline">
                Check out a special Video Greeting just for you!
              </h5>

              <button
                type="button"
                className="btn btn-mailer"
                onClick={ handleShow }
              >
                WATCH VIDEO
              </button>
            </div>
          </section>

          <Modal
            show={show}
            onHide={handleClose}
          >
          <Modal.Header className="text-left">
            <div className="icon-box" onClick={handleClose}>
              <span className="close">
                <img src={menuclose} alt="menuclose" />
              </span>
            </div>

          </Modal.Header>
          <Modal.Body>
            <Plyr
                type="video" // or "vimeo"
                url={props.previewVideo}
                autoplay
              />
          </Modal.Body>
          </Modal>
        </>
      );
}

export default LandingMailer;