import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { apiFetch, isLoggedIn } from "../helpers/APIHelper";
import { getMailToLink, getSMSLink, isAndroid, isIOS, canShare, isMobile } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import { toastManager } from "../components/Toaster";
import BackLink from "../components/BackLink";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import NextButtonGroup from "../components/NextButtonGroup";
import StandardModal from "../modal/StandardModal";
import QuickSignup from "../components/QuickSignup";


export default class InviteOthers extends Component {


  constructor(props) {
    super(props);
    // this.clickedInviteLink = this.clickedInviteLink.bind( this );

    this.state = {
      loading: true,
      error: null,
      fizzId: this.props.match.params.fizzId,
      inviteMessage:"",
      inviteLink: "",
      shareLink: "",
      showQuickSignupModal: false
    }
  }

  componentDidMount() {
    this._getData();

    document.title = 'Invite others | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
  }

  _getData = () => {
    this.setState({
      loading: true
    });

    apiFetch('/rest/fizz/' + this.state.fizzId + '?screen=inviteCode', {
      method: 'GET',
    }, (json) => {
      console.log(json);
      this.setState({
          loading: false,
          fizz: json.item,
          inviteMessage: json.item.inviteSampleNoLink,
          inviteLink: json.item.inviteLink,
          linkToSend : json.item.inviteSampleNoLink + '\n\n' + json.item.inviteLink
      });

      TrackingHelper.trackPage();

    }, (error) => {
      console.log(error);
      this.setState({
        loading: false,
        error: ((error&&error.message)||error)+""
      })

      TrackingHelper.trackPage();
    });
  }

  onInviteChange=(e)=>{
    this.setState({
      inviteMessage:e.target.value,
      linkToSend: e.target.value + '\n\n' + this.state.inviteLink
    })
  }

  copyToClipboard=()=>{
    navigator.clipboard.writeText(this.state.linkToSend);
    toastManager.showToast({
			message: 'Copied to Clipboard!'
    });
    this.trackInviteClick();
  }
  
  shareAny=()=>{
    this.trackInviteClick();
    navigator.share({
      text: this.state.linkToSend
    }).then(() => {
      // alert("Sent");
    }).catch((e)=>{
      // Canceled or something else
      // alert("Error"+e);
    });
  }

  trackInviteClick = () => {

    if( !isLoggedIn() ){
      this.toggleQuickSignupMoal();
    }
    
    apiFetch('/rest/tracking/best/invite-intent?fizzId=' + this.state.fizzId, {
      method: 'POST',
      data: { value: 1, },
    }, (json) => {

      //fire and forget
    
    }, (error) => {
    
      console.log(error);
    
    });
  }

  toggleQuickSignupMoal = () => {

    this.setState({
      showQuickSignupModal: !this.state.showQuickSignupModal
    })
  }

  render() {

    var hasClipboard = navigator && navigator.clipboard && navigator.clipboard.writeText;

    let smsLink = getSMSLink({body:this.state.linkToSend});
    let emailLink = getMailToLink({body:this.state.linkToSend, subject:"Help me create a surprise group video"});

    return (
      <div className="screen-wrap">
        <NavBar title="Invite Others" />
        <MainContent className="container bg-margin">

          <BackLink
            to={"/edit/"+this.state.fizzId }
            title="Back"
          />
            
          <h1 className="headline-text">Invite Friends to Contribute</h1>

          <h6 className="subheadline mb-3">Make it a group surprise by collecting photos and videos from others. Edit the message below, and remember to let them know when it’s due.</h6>


          <div className="row pt-3 align-items-center justify-content-center ">
        

          <div className="col-lg-8 text-center">
              
              {this.state.error ? (
                <div className=" text-center alert alert-danger">
                  {this.state.error}
                </div>
              ):(
                <>

                  {this.state.loading && 
                    <Spinner float={true} />
                  }
                  <textarea className="invite-info" value={this.state.inviteMessage} onChange={this.onInviteChange} />

                  {!this.state.loading && 
                    <>
                        { hasClipboard &&
                          <div className="mt-4">
                            <button className="link blue-link" onClick={this.copyToClipboard} >
                              Copy Invite and paste anywhere
                            </button>
                          </div>
                        }
        
                      <div className="pinned-bottom-bar">
                      <div className="row pt-3 align-items-center justify-content-center">
                        <div className="col-md-6 text-right">
                          { canShare() && isMobile() ?
                            <>
                              { isAndroid() ?
                                <>
                                  <div>
                                    <a className="btn btn-primary btn-block btn-lg-inline-block" onClick={this.trackInviteClick} href={smsLink}>
                                      Send This Invite
                                    </a>
                                  </div>
                                  <button className="btn btn-primary btn-block btn-lg-inline-block" onClick={this.shareAny}>
                                    More Sending Options
                                  </button>
                                </>
                                :
                                // isIOS
                                <>
                                  <button className="btn btn-primary btn-block btn-lg-inline-block" onClick={this.shareAny}>
                                    Send This Invite
                                  </button>
                                </>
                              }
                            </>
                          :
                            <>
                              { smsLink && 
                                <div>
                                  <a className="btn btn-primary btn-block btn-lg-inline-block" onClick={this.trackInviteClick} href={smsLink}>
                                    Text This Invite
                                  </a>
                                </div>
                              }
                              <div>
                                <a className="btn btn-primary btn-block btn-lg-inline-block" onClick={this.trackInviteClick} href={emailLink}>
                                  Email This Invite
                                </a>
                              </div>

                            </>
                          }
                        </div>

                        <div className="col-md-6">

                            <NextButtonGroup
                              textOverride = {'BACK' }
                              moveToNext = { ()=>{ window.location = "/edit/"+this.state.fizzId }}
                            />

                        </div>
                      </div>
                      </div>
                    </>
                  }
                </>
              )}
              
            </div>
          </div>

        {/* QuickSign Modal */}
        <StandardModal
            show={this.state.showQuickSignupModal }
            onHide={this.toggleQuickSignupMoal}
            closeBtnText={false}
            size="lg"
          >
            <QuickSignup successCallback={this.toggleQuickSignupMoal} preventRedirect = {true} from={'EditFizz'} fullButton={true}/>
        </StandardModal>

        </MainContent>
        <Footer className="footer-w-pinned-bottom-bar" hideMobile={true} />
      </div>
    );
  }
}
