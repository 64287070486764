import React, { Component } from "react";
import { Link } from "react-router-dom";
import settings from "../assets/images/HMK-Icons/Icons_Settings_Regular.svg";
import arrowright from "../assets/images/HMK-Icons/Icons_Arrows_Caret_Right_Bold.svg";
import { isLoggedIn, logOut } from "../helpers/APIHelper";
import Footer from "./Footer";
import signouticon from "../assets/images/HMK-Icons/Icons_Sign_Out_Regular.svg";
import signInIcon from "../assets/images/HMK-Icons/Icons_Person_Regular.svg";
import contactIcon from "../assets/images/HMK-Icons/Icons_Phone_Smartphone_Regular.svg";
import messageIcon from "../assets/images/HMK-Icons/Icons_Message.svg";
import { Navbar,  Nav } from 'react-bootstrap';
import QuickSignup from "../components/QuickSignup";
import StandardModal from "../modal/StandardModal";


const listeners = {
  onToggle:null
};

export const onToggle = (fun)=>{
  listeners.onToggle = fun;
}

class NavBar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isExpanded:false,
      showQuickSignupModal: false
    };
  }

  componentWillReceiveProps(props) {
    if ( props.allowSignUpAlert ) {
      this.setState({
        allowSignUpAlert : props.allowSignUpAlert
      });
    }
  }

  onToggle = ()=>{
    this.setExpanded(!this.state.isExpanded);
  }

  closeFromClick = ()=>{
    this.setExpanded(false);
  }

  setExpanded = (isExpanded)=>{

    this.setState({
      isExpanded:isExpanded
    })

    if( listeners.onToggle ) {
      listeners.onToggle(isExpanded)
    }
  }

  closeQuickSignupModal = () => {

    if( localStorage && localStorage.setItem ) {
      localStorage.setItem("hasSeenQuickSignUp", true );
    }

    this.setState({
      showQuickSignupModal: false
    });
  }

  onSignupSuccess = () => {

    if( localStorage && localStorage.setItem ) {
      localStorage.setItem("hasSeenQuickSignUp", true );
    }


    this.setState({
      showQuickSignupModal: false,
      allowSignUpAlert: false
    });
  }

  openQuickSignUp = () => {
    this.setState({
      showQuickSignupModal: true
    });
  }



  render() {
    return (
      <>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="" expanded={this.state.isExpanded} >
          <div className="container-lg" >

            <div className="nav-top-section nav-mobile-shadow">
              <Navbar.Toggle aria-controls="responsive-navbar-nav"  onClick={this.onToggle}   />
              <Navbar.Brand > 
                <Link to="/" onClick={this.closeFromClick} >
                  <img
                    src={process.env.PUBLIC_URL + "/img/hmk-logo-horizontal.svg"}
                    alt="Hallmark Video Greeting Cards"
                    height="65"
                  />
                </Link>
              </Navbar.Brand>
              <div className ="d-lg-none nav-alert-zone">
                { this.state.allowSignUpAlert &&
                  <button className="message-button" onClick={this.openQuickSignUp } >
                    <img
                      src={messageIcon}
                      alt="messageicon"
                      className="message-icon"
                      />
                  </button>
                }
              </div>
            </div>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
              {/* <ul className="navbar-expand-lg  navbar-nav"> */}
                <li className="nav-item ">
                  <Nav.Link href="/list"  onClick={this.closeFromClick} >
                    My Video Greetings
                    <span className="arrow">
                      <img
                        src={arrowright}
                        alt="arrowrighticon"
                        className="arrowright-icon"
                      />
                    </span>
                  </Nav.Link>
                </li>
                
                
                { this.state.allowSignUpAlert &&
                  <li className="nav-item d-none d-lg-block"> 
                    <button className="message-button" onClick={this.openQuickSignUp} >
                      <img
                        src={messageIcon}
                        alt="messageicon"
                        className="message-icon"
                      />
                    </button>
                  </li>
                }

                { isLoggedIn() ? (
                  <li className="nav-item d-none d-lg-block"> 
                    <button className="nav-link black-link" onClick={logOut}>
                      <img
                        src={signouticon}
                        alt="signouticon"
                        className="signout-icon"
                      />
                      Sign out
                    </button>
                  </li>
                ):(
                  <li className="nav-item d-none d-lg-block">
                    <Link to="/login" className="nav-link black-link d-none d-lg-block" onClick={this.closeFromClick}  >
                      <img
                        src={signInIcon}
                        alt="signInIcon"
                        className="signin-icon"
                        />
                      Sign In
                    </Link>
                  </li>
                )}

                <li className="nav-item d-lg-none"> 
                  <Nav.Link  href="/faq" className="nav-link d-lg-none" onClick={this.closeFromClick}>
                    FAQ
                    <span className="arrow">
                      <img
                        src={arrowright}
                        alt="arrowrighticon"
                        className="arrowright-icon"
                      />
                    </span>
                  </Nav.Link >
                </li>
            
                <li className="nav-item d-lg-none"> 
                  <Nav.Link  href="/tutorials" className="nav-link d-lg-none" onClick={this.closeFromClick}>
                    Watch a Tutorial
                    <span className="arrow">
                      <img
                        src={arrowright}
                        alt="arrowrighticon"
                        className="arrowright-icon"
                      />
                    </span>
                  </Nav.Link >
                </li>


                {/* </ul> */}
                <div className="banner-image" />
                  {isLoggedIn() ? (
                    <div className="d-block d-lg-none mob_account1">
                      <span className="navbar-text">
                        <Link to="/change-password" onClick={this.closeFromClick} >
                          <img
                            src={settings}
                            alt="settingsicon"
                            className="settings-icon"
                          />
                          Change password
                        </Link>
                      </span>
                      <br />
                      <span className="navbar-text">
                        <a href="tel:+18775808847" onClick={this.closeFromClick}>
                          <img
                            src={contactIcon}
                            alt="contactIcon"
                            className="settings-icon"
                          />
                          Contact us
                        </a>
                      </span>
                      <br />
                      <span className="navbar-text">
                        <button className="link" onClick={logOut}>
                          <img
                            src={signouticon}
                            alt="signouticon"
                          />
                          Sign out
                        </button>
                      </span>
                    </div>
                  ):(
                    <div className="d-block d-lg-none mob_account1">
                      <span className="navbar-text">
                        <a href="tel:+18775808847">
                          <img
                            src={contactIcon}
                            alt="contactIcon"
                            className="settings-icon"
                          />
                          Contact us
                        </a>
                      </span>
                      <br />
                      <span className="navbar-text">
                        <Link to="/login"  onClick={this.closeFromClick} >
                          <img
                            src={signInIcon}
                            alt="signInIcon"
                            />
                          Sign in
                        </Link>
                      </span>
                    </div>
                  )}

                  <Footer hideMobile={false} className="d-lg-none" />
            </Nav>
          </Navbar.Collapse>
          </div>
        </Navbar>


        {/* QuickSign Modal */}
        <StandardModal
          show={this.state.showQuickSignupModal }
          onHide={this.closeQuickSignupModal}
          closeBtnText={false}
          size="lg"
        >
          <QuickSignup successCallback={this.onSignupSuccess} preventRedirect = {true}/>
        </StandardModal>

      </>
    );
  }
}

export default NavBar;
