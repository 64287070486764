
import React, { useEffect, useState } from 'react';
import { apiFetch } from "../helpers/APIHelper";
import Share from "../assets/images/HMK-Icons/Icons_Share.svg"
import DownloadIcon from "../assets/images/HMK-Icons/Icons_Download.svg";
import DownloadIconOG from "../assets/images/HMK-Icons/Icons_Download_File_Regular.svg";
import LikeIcon from "../assets/images/HMK-Icons/Icons_Heart_Fill.svg";
import DisLikeIcon from "../assets/images/HMK-Icons/Icons_Heart_Outline.svg";
import moment from 'moment';
import { getMailToLink, getSMSLink, getFizzUUID, canShare } from "../helpers/URLHelper";
import MailIcon from "../assets/images/HMK-Icons/Icons_Mail_Regular.svg";
import TextIcon from "../assets/images/HMK-Icons/Icons_Chat_Regular.svg";

export default ( {shareUrl} ) => {

  const [viewCount, setViewCount] = useState();
  const [likeCount, setLikeCount] = useState();
  const [userLiked, setUserLiked] = useState();

  useEffect(() => {
    getVideoStats();
  }, []);


  const getVideoStats = () => {      
    apiFetch(`/rest/fizz/${getFizzUUID()}/stats`, {
      method: 'GET',
      }, (json) => {
        console.log(json);
        
        if ( json.viewCount ){
          setViewCount( json.viewCount );
          setLikeCount( json.likeCount );
          setUserLiked( json.userLiked );
        }

    }, (error) => {
      console.log( 'Error:' + error)
    });
  };


  const shareAny=()=>{
    navigator.share({
      url: shareUrl
    }).then(() => {
      // alert("Sent");
    }).catch((e)=>{
      // Canceled or something else
      // alert("Error"+e);
    })
  }

  const updateLikes = () => {
    
    apiFetch( `/rest/vid/${getFizzUUID()}/likes` , {
        method:'POST',
        data: { isAdd: !userLiked },
      }, (json) => {

        userLiked ? setLikeCount( likeCount - 1 ) : setLikeCount( likeCount + 1 );
        setUserLiked( !userLiked );
        
      }, (error) => {
        console.log(`Error::`, error);
    }); 
  }

  var downloadUrl = shareUrl+"/download";
  var emailLink = getMailToLink({body:shareUrl});
  var smsLink = getSMSLink({body:shareUrl});

  return (
    <div className="row"> 
      <div className= "video-extras pl-4 col-12" style={{ display: 'inline-flex'}}>
        <div className="video-likes mr-4" onClick = {updateLikes}>
          { userLiked ? (
            <i className="icon-mask" style={{ WebkitMaskImage:"url("+LikeIcon+")"}} alt="Like Icon"></i>
          ) : (
            <i className="icon-mask" style={{ WebkitMaskImage:"url("+DisLikeIcon+")"}} alt="UnLike Icon"></i>      
          )}

          {likeCount}
        </div>

        <div className="video-share mr-4 ">
          { canShare() ? (
            <button className="btn btn-link blue-link" onClick={shareAny} >
              <i className="icon-mask" style={{ WebkitMaskImage:"url("+Share+")"}} alt="Share Icon"></i>
              SHARE
            </button>

          ) : (
            
            <>
              { smsLink && 
                <div>
                  <a id="share-text" className="blue-link mb-2" href={smsLink} className="" >
                    <i className="icon-mask" style={{ WebkitMaskImage:"url("+TextIcon+")"}} alt="Message Icon"></i>
                    Text
                  </a>
                </div>
              }
              <div>
                <a id="share-email" href={emailLink} className="blue-link" >
                  <i className="icon-mask" style={{ WebkitMaskImage:"url("+MailIcon+")"}} alt="Email Icon"></i>
                  Email</a>
              </div>
            </>
          )}

        </div>

        <div className="video-download">
          <a href={downloadUrl} download="Video.mp4" className="blue-link btn-padded tag-download-user">
            <i className="icon-mask" style={{ WebkitMaskImage:"url("+DownloadIconOG+")"}} alt="Download Icon"></i>
            DOWNLOAD
          </a>
        </div>
        
        <div className="video-views">
          {viewCount} Views
        </div>
      </div>

    </div>
  );
}

