/*** Based on the codepen at https://codepen.io/psnoonan/pen/rNmrLLZ ****/

import React from 'react';
import { useEffect, useState, useRef } from 'react';

const Equalizer = (props) => {

    const [play, setPlay] = useState(false);
    let intervalHolder = null;
    let speed = 250;

    const equalizerRef = useRef();

    useEffect(() => {
        if (play !== props.play) {
            setPlay(props.play);
        }
    }, [props.play]);

    useEffect(() => {
        play ? start() : stop();
        return () => { // clean up between re-renders
            clearInterval(intervalHolder);
        }
    }, [play]);

    const stop = function () {
        clearInterval(intervalHolder);
    };

    const start = function () {
        intervalHolder = setInterval(() => {
            const refBars = equalizerRef.current.children
            for (const bar of refBars) {
                const height = (Math.random() * 100).toFixed(2) + "%";
                bar.firstChild.style.transform = `translate3d(0, -${height}, 0)`;
                bar.firstChild.style.transitionDuration = `${speed}ms`;
            }
        }, speed + 50);
    };

    return (
        // To make this reusable, the width and height of this element are set in %
        // Always use a wrapper around it with fixed width and height
        <div ref={equalizerRef} className="equalizer">
            <div className="bar"><span /></div>
            <div className="bar"><span /></div>
            <div className="bar"><span /></div>
            <div className="bar"><span /></div>
            <div className="bar"><span /></div>
        </div>
    )
}

export default Equalizer;