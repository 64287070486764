import React, { Component } from "react";
import { apiFetch, saveSession  } from "../helpers/APIHelper";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import LandingBanner from "../components/LandingBanner";
import LandingSteps from "../components/LandingSteps";
import LandingAssortment from "../components/LandingAssortment";
import LandingVideo from "../components/LandingVideo";
import MainContent from "../components/MainContent";
import ShopTheCardsBanner from "../components/ShopTheCardsBanner";
import LandingCustomBanner from "../components/LandingCustomBanner";
import TrackingHelper from "../helpers/TrackingHelper";

import step1 from "../assets/images/step-1-physical.jpg";
import step2 from "../assets/images/step-2-invite.jpg";
import step3 from "../assets/images/step-3-physical.jpg";
const stepArray = [
  {
    image: step1,
    imageAlt: 'Step 1',
    imageHeader: 'Scan the code inside the card',
    imageBody: 'We’ll give easy, step-by-step instructions to create your video.'
  },
  {
    image: step2,
    imageAlt: 'Step 2',
    imageHeader: 'Add videos and invite others',
    imageBody: "Collect photos and videos in a snap, and choose music. When you’re done, we’ll stitch them together."
  },
  {
    image: step3,
    imageAlt: 'Step 3',
    imageHeader: 'Mail for them to enjoy',
    imageBody: "When they scan the code, they'll be able to watch their video, save it, and even share on social."
  }
];

export class LandingPage extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount(){
    document.title = 'Video Greetings Online | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "Easily create a personalized video greeting alone or with friends. Add photos, video and music. Send instantly, like a Hallmark ecard—ready to save and share."
  }



  render() {
    return (
      <div>
        <NavBar />
        <MainContent>
          <LandingBanner /> 
          <LandingSteps stepArray={stepArray}/>
          <LandingVideo />

        </MainContent>
        <Footer />
      </div>
    );
  }
}

export default LandingPage;
