import React, { useEffect } from "react"
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import BackLink from "../components/BackLink";
import { getQRKey, getBaseURL } from "../helpers/URLHelper";
import NavLogo from "../components/NavLogo";
import { Link } from "react-router-dom";

export default function PrivacyPolicy () {

  useEffect( () => {

    document.title = 'Privacy Policy | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "Protecting your privacy is important to us. Read the full Hallmark prviacy and security policy to learn what data we collect and how it's used.";
    TrackingHelper.trackPage();
  }, []);



  var hmkraw = (
<div class="inner-wrapper">
  <h2 class="page-title">Hallmark Privacy Policy</h2>
  <p>Updated and Effective as of June 27, 2023</p>
  <section class="section" id="section-hallmark-privacy-statement">
    <p>Welcome to Hallmark! We strive to help people create a caring and more connected world, and to do this we use information collected from or about you. This Policy describes the collection, use,&nbsp;sharing, storage and other processing of information, both online and offline, of Hallmark Cards, Incorporated, and its family of companies (“Hallmark,” “we” or “us”), through hallmark.com, our corporately owned Hallmark Gold Crown stores, and any other website, product, feature, or service we offer that posts or provides this policy (collectively, the “Hallmark Properties”). We may combine all of the information we collect through our Hallmark Properties. By accessing or using the Hallmark Properties, you agree that your access and use are governed by this Privacy Policy and our <a href="/terms">Terms of Use</a>.</p>
    <h3 class="section-title=">Contents</h3>
    <ul class="has-border-left gutter-left-medium">
      <li><a href="#section-changes-to-policy">Changes to This Privacy Policy</a></li>
      <li><a href="#section-general-audience-sites">General Audience Sites</a></li>
      <li><a href="#section-info-we-collect">Information We Collect</a></li>
      <li><a href="#section-how-we-use-info">How We Use Your Information</a></li>
      <li><a href="#section-how-we-share-info">How We Share Your Information</a></li>
      <li><a href="#section-third-party-advertising">Third Party Advertising and Analytics</a></li>
      <li><a href="#section-do-not-track">“Do Not Track” and Global Privacy Control Signals</a></li>
      <li><a href="#section-your-choices">Your Choices about the Information We Collect</a></li>
      <li><a href="#section-security">Security</a></li>
      <li><a href="#section-third-party-content-links">Third Party Content and Links</a></li>
      <li><a href="#section-consent-to-transfer">Consent to Transfer</a></li>
      <li><a href="#section-contact-us">How to Contact Us</a></li>
      <li><a href="#section-canadian-residents">Notice to Canadian Residents</a></li>
      <li><a href="#section-nevada-opt-out">Notice to Nevada Residents</a></li>
      <li><a href="#section-notice-to-virginia-residents">Notice to Virginia Residents</a></li>
      <li><a href="#section-notice-to-callifornia-residents">Notice to California Residents</a></li>
      <li><a href="#section-notice-to-colorado-residents">Notice to Colorado Residents</a></li>
      <li><a href="#section-notice-to-connecticut-residents">Notice to Connecticut Residents</a></li>
      <li><a href="#section-notice-to-utah-residents">Notice to Utah Residents</a></li>
    </ul>
  </section>
  <section class="section" id="section-changes-to-policy">
    <h3 class="section-title">Changes to This Privacy Policy</h3>
    <p>To the extent allowed by applicable law, we reserve the right to change this Privacy Policy at any time without prior notice. If we make material changes to our privacy policy, we will update the effective date and post a notice on our online Hallmark Properties. We may also provide notice to you in other ways, such as through contact information you have provided, and provide you with the ability to opt-in or opt-out of the changes as required by law. Your continued use of any of the Hallmark Properties after the effective date of the revised Privacy Policy will constitute your consent to those changes to the fullest extent allowed by applicable law.</p>
  </section>
  <section class="section" id="section-general-audience-sites">
    <h3 class="section-title">General Audience Sites</h3>
    <p>The Hallmark Properties are not intended for use by individuals younger than 18. We do not knowingly collect personal information from individuals younger than the age of 18.</p>
  </section>
  <section class="section" id="section-info-we-collect">
    <h3 class="section-title">Information We Collect</h3>
    <p>We collect information about you directly through the Hallmark Properties, automatically when you visit the Hallmark Properties, and sometimes from third parties. Some of the information we collect may be considered “personal information” or “personal data,” and some of which may be considered “sensitive,” under applicable laws, such as your name, phone number, and email address. We will also treat other information, including IP addresses and cookie identifiers, as “personal information” where required by applicable law. Note that we may de-identify or pseudonymize your personal information so as to make it non-personal, either by combining it with information about other individuals and/or by hashing the information or otherwise removing characteristics that make the information personally identifiable to you. We will treat de-identified or pseudonymized information as non-personal to the fullest extent allowed by applicable law. We maintain and use de-identified data without attempting to re-identify it, except where permitted by applicable law, such as to determine whether our de-identification processes satisfy legal requirements. If we combine non-personal information with personal information, then we will treat the combined information as personal information under this Privacy Policy.</p>
    <p><strong>Information you provide directly to us.</strong> When you use the Hallmark Properties, we ask you to provide us with certain information. This information will depend on the Hallmark Properties you interact with, and may include without limitation:</p>
    <ul class="unordered-list gutter-bottom-large">
      <li>First and last name</li>
      <li>Email address, phone number and other contact information</li>
      <li>Shipping and billing addresses</li>
      <li>Demographic information, such as your date of birth and gender</li>
      <li>Account information, such as your Crown Rewards, or Keepsake Ornament Club (KOC) membership information</li>
      <li>Photographs and video, messages and handwriting, and other data provided by you for personalized cards and gifts, some of which may be considered biometric information</li>
      <li>Wish list, or other favorites information</li>
      <li>Information provided by you in responses to surveys, quizzes, or in community forums</li>
      <li>Information provided by you as you interact with the Customer Care team</li>
      <li>Information provided by you as you provide feedback through the Hallmark Properties on our products or services</li>
      <li>Information relating to transactions and products and services purchased, including payment information, address information (you or a recipient), return and exchange information, Hallmark Sign &amp; Send transactions, and Hallmark Reminders services</li>
    </ul>
    <p><strong>Information collected automatically.</strong> We, our service providers, business partners, and/or other third parties may automatically collect and/or store certain information when you visit or interact with the Hallmark Properties (“Usage Information”). This Usage Information may be stored and/or accessed from your computer, tablet, mobile phone, or other device whenever you visit or interact with the Hallmark Properties. Usage Information may include, but is not limited to:</p>
    <ul class="unordered-list gutter-bottom-large">
      <li>Your IP address, mobile device identifier and other unique identifier</li>
      <li>Device information, including device model, operating system version, device date and time, mobile network information</li>
      <li>Geolocation of your device</li>
      <li>How you use and interact with the Hallmark Properties, including your search terms, pages you visit, content you view, and your preferences</li>
    </ul>
    <p>Usage Information may be collected through the use of the following technologies:</p>
    <ul class="unordered-list gutter-bottom-large">
      <li><strong>Cookies and Local Storage.</strong>Cookies and local storage are data files placed within a browser on a device when it is used to visit the Hallmark Properties. Certain web browsers and browser add-ons may provide additional local data storage mechanisms. Cookies and local storage can be used for a variety of purposes, including to store a unique identifier for your device that recognizes your device as you visit the Hallmark Properties or other websites or online services, and to remember your preferences. Hallmark uses the One Trust cookie manager to permit you to adjust your cookie settings. Additionally, most browsers provide you with the ability to disable, decline, or clear cookies and local storage; please check your browser’s settings for further information. <a href="https://www.aboutcookies.org" target="_blank">Learn more&nbsp;about cookies</a></li>
      <li><strong>Web Tags.</strong> Small graphic images or other web programming code (also known as web beacons or pixel tags or 1x1 GIFs or clear GIFs) may be included in our web pages and email messages. These and similar technologies may be used for a number of purposes, including to count visitors to the Hallmark Properties, to monitor how users navigate Hallmark Properties, to count how many of our email messages were opened, or to count how many particular products were viewed. The information we collect through this technology may include the first date and time you open our email, and whether you click any links included in our email.</li>
      <li><strong>Embedded Scripts. </strong> An embedded script is programming code that is designed to collect information about your interactions with the Hallmark Properties, such as the links you click on. The code is temporarily downloaded onto your device from our server or a third-party service</li>
      <li>Hallmark may record session interactions with Hallmark.com user interfaces, including what pages you visit on the Hallmark Properties, how long you visit those pages, the links you click, and your path through the Hallmark Properties, for website analytics purposes, solely for our internal business purposes, to improve the Hallmark Properties and our products and services.</li>
    </ul>
    <p><strong>Location Data.</strong> In some cases, for example when you opt-in to collection of location data through our mobile applications or your web browser, we may directly collect location information through your device. You may be able to turn off the collection of location information through the settings on your device. However, please note that we may still be able to collect or infer your approximate location through other information we collect, such as IP address. In addition, some mobile service providers may also provide us or our third-party service providers with information regarding the physical location of the device used to access the Hallmark Properties.</p>
    <p><strong>Chat Boxes.</strong> Hallmark’s Chat With Care (Live Chat) and/or Hallmark’s Virtual Helper (Automated Chat Bot) can record, use, and retain your information provided, as well as the communications within the chat, for purposes of assisting you during the conversation. If you do not consent, please do not continue with chat assistance.</p>
    <p><strong>Information from Independent Gold Crown Stores.</strong> Many Hallmark Gold Crown stores are operated by independent retailers (“Independent Retailers”) who may use their own websites, apps, or other technology to interact with you, and who may provide information they collect from or about you to Hallmark, including in connection with administering your Crown Rewards account and for marketing purposes. When you shop or otherwise interact with an Independent Retailer, you are interacting with them directly, and this privacy policy does not apply – but it does cover any information that Hallmark receives from them.</p>
    <p><strong>Information from Other Third Parties.</strong> We may receive information about you from third parties, such as business partners (including companies that assist us with online advertising and analytics), data providers who enhance the information we have about you, sources of publicly available information, and other users of the Hallmark Properties (such as when they provide your information to send you one of our products or participate in a “refer a friend” or similar programs that we may offer). If you are an existing customer of Hallmark, or later become a customer of Hallmark, we may combine the information we receive about you from other users with other information we have about you to better understand your interests and preferences and better market our products and services to you.</p>
    <p><strong>Social Media.</strong> We may host or facilitate interactions with social media platforms, like Facebook, YouTube, Pinterest, TikTok and Instagram. You may also have the opportunity to log in through or otherwise connect your social media account. When you interact with social media through the Hallmark Properties, we will receive information about you from the social media service and you will be choosing to share information about your interactions with Hallmark with that social media service.</p>
    <p>In addition, the social media service may set their own cookies and collect information about your use of the Hallmark Properties, including your IP address or other device identifiers, and which pages you visit on the Hallmark Properties. These social media platforms may be able to collect certain information on your visits to the Hallmark Properties regardless of whether or not you affirmatively interact with the feature and whether or not you are logged into (or have) an account with the social media platform. If you are logged out or do not have an account and visit a page with a social plug-in, your browser sends a more limited set of information. Like other sites, the social media platform will receive information about the webpage you are visiting, the date and time of your visit, and other browser-related information. They may use that information to help them improve their products or for other purposes set forth in their privacy policy. We may also receive information about you if other users of social media give us access to their profiles and you are one of their connections or "friends."</p>
    <p>The information we collect is subject to this Privacy Policy. The information collected and stored by the third party remains subject to the third party’s privacy practices, including, without limitation, whether the third party continues to share information with us, the types of information shared, and your choices with regard to what is visible to others on that third-party website or service. The third party may allow you to remove the application or feature, in which case we will no longer collect information about you through the application or feature, but may retain the information previously collected.</p>
    <p>If you choose to post information to a third-party platform, that information may be public.</p>
  </section>
  <section class="section" id="section-how-we-use-info">
    <h3 class="section-title">How We Use Your Information</h3>
    <p>We work hard to get to know you so that we can customize and personalize our services to you.</p>
    <p>We may use non-personal information for any purpose allowable by law, including for advertising, research, and marketing purposes. We use information collected from or about you, including personal information and Usage Information, as disclosed in this privacy policy, including:</p>
    <ul class="unordered-list gutter-bottom-large">
      <li>Allowing you to use and interact with the Hallmark Properties and the features we offer, including processing your transactions, account registration, our Crown Rewards program, and personalized product offerings;</li>
      <li>Recognizing you across devices including in connection with online advertising;</li>
      <li>Identifying your preferences, learning and anticipating your needs, and tailoring our content, products, and services to you, including advertising, recommendations, promotions, and offers we display to you, both online and offline;</li>
      <li>Providing information on products/services we think you may be interested in, including special offers and promotions from us, our advertisers, and third-party partners;</li>
      <li>Responding to your requests for information and providing customer service;</li>
      <li>Verifying your identity, including verifying your email address is valid, for purposes such as product reviews and fraud prevention;</li>
      <li>Improving our products, services, and marketing endeavors;</li>
      <li>For internal business purposes, including operating the Hallmark Properties, improving our products, services, and marketing endeavors, and resolving technical issues reported about the Hallmark properties;</li>
      <li>Contacting you regarding your use of the services and, in our discretion, changes to our policies;</li>
      <li>Facilitating your job search, processing your employment application, and preparing related governmental and internal statistics reports;</li>
      <li>Complying with the law and protecting the safety, rights, property, or security of Hallmark, the Hallmark Properties, consumers, and the general public, including investigating and preventing fraud, illegal activities, and activities that violate our policies;</li>
      <li>Upon notice to you at the time of collection or use of a feature, or otherwise with your consent.</li>
    </ul>
    <p><strong>Personalized Product Services.</strong> You may be able to personalize the products you purchase from us or that we otherwise make available for that purpose. For instance, you may be able to add your own photo, video, handwritten note or other content (“Consumer Content”) in connection with a product purchase. You are fully responsible for your Consumer Content, which must comply with all laws, the Terms of Use, and any other applicable terms provided. We have the right, but not the obligation, to monitor Consumer Content and to refuse to include any Consumer Content that we believe violates law or our policies. Hallmark may permit you to store your Consumer Content as drafts or for re-use. By providing any Consumer Content, you agree that Hallmark may analyze such content to assist us in enhancing our products and services, getting to know you better, and to better market to you the Hallmark products and services we think you will most be interested in.</p>
  </section>
  <section class="section" id="section-how-we-share-info">
    <h3 class="section-title">How We Share Your Information</h3>
    <p>We may aggregate, de-identify, pseudonymize, and/or anonymize any information such that this information is no longer linked to your personal information. We may share this non-personal information with third parties for any purpose in our discretion and as permitted by law, including for advertising, research and marketing purposes..</p>
    <p>We may share the information we have collected about you, including personal information and Usage Information, as disclosed at the time you provide us with information or your consent, and as described in this Privacy Policy, including:</p>
    <p><strong>At Your Request and When You Post Content Publicly.</strong> We may share information when you direct us to do so. For example, you may be presented with an opportunity to receive marketing offers from a third party, or you may choose to write a product review, share a wish list, send a message, or engage with a social media service or third party application or feature, through which information about you is shared. Please note that we are not responsible for the privacy practices of third parties.</p>
    <p>In addition, we or a third-party social networking or review platform may offer features, such as message boards and other public areas, where you can choose to interact with others and/or submit content or post publicly. Content that you post publicly may be viewed, collected, and used by others, and the protections of this Privacy Policy will not apply.</p>
    <p><strong>Independent Retailers.</strong> If you are a Crown Rewards member and you choose to interact with an Independent Retailer and connect your Crown Rewards account, we may share your information for business purposes, such as fulfilling your orders, processing your transactions, assisting you with your Crown Rewards account, and facilitating localized marketing offers.</p>
    <p><strong>Affiliated Third Parties for Direct Marketing Purposes.</strong> We may share your information with our affiliates and subsidiaries for business, analytical, and operational purposes. We also share personal information with these affiliated companies so that they may contact you with their own direct marketing (we do not share your personal information with unaffiliated third parties for those third parties’ own direct marketing purposes unless you consent (opt-in or opt-out) to such sharing). You may opt out of sharing this information with our affiliated companies by contacting Hallmark Cards Consumer Care at 1-800-HALLMARK – as described in the “Your Choices About the Information We Collect” section below. Please note that if you are already receiving marketing communications from one of our affiliated companies, you will need to opt-out procedures directly with that company in order to stop receiving their messages.</p>
    <p><strong>Service Providers.</strong> We may use third party service providers to perform certain services on our behalf, or to assist us in providing our services to you, and we will disclose information in order for them to provide those services. Examples include using a payment processor, customer service provider, email marketing provider, delivery services, data analysis provider, streaming content platform, and other administrative services.</p>
    <p><strong>Sweepstakes, Contests and Promotions.</strong> We may offer sweepstakes, contests, or other promotions (any of which, a “Promotion”) that may require registration. By participating in a Promotion, you are agreeing to the provisions, conditions, or official rules that govern the Promotion, which may contain specific requirements of you (including, except where prohibited by law, allowing the sponsor(s) of the Promotion to use your name, voice, likeness, or other indicia of persona in advertising or marketing materials). If you choose to enter a Promotion, personal information may be disclosed to third parties or the public in connection with the administration of such Promotion, including in connection with winner selection, prize fulfillment, as required by law, or as permitted by the Promotion’s terms or official rules (such as on a winners’ list).</p>
    <p><strong>Employment Applications.</strong> If you apply for a job through the Hallmark Properties, we or our service provider(s) may ask you to provide self-identifying information such as your veteran status, gender, and ethnicity in conjunction with laws and regulations enforced by the Equal Employment Opportunity Commission and other federal, state, and local regulatory agencies. Providing such self-identifying information is voluntary, but if you do provide such information, we and/or our service provider may submit that information to the appropriate government or regulatory agencies to fulfill reporting requirements and use that information to defend against employment-related complaints. <em>Please note that we may utilize a careers portal that is operated by a third party, in which case, your registration and use of the careers portal will be subject to the third party’s privacy practices as disclosed on the portal.</em></p>
    <p><strong>Business Transitions.</strong> We may disclose your information in connection with a corporate transition such as a merger, acquisition, bankruptcy, or sale of all or a portion of our assets, including during the course of any due diligence process. By providing your personal information, you agree that we may transfer such information to the other entity in such a transaction without your further consent.</p>
    <p><strong>For Administrative and Legal Reasons.</strong> We reserve the right to use or disclose any information as needed to satisfy or fulfill our obligations under any law, regulation, or legal request; to protect the integrity of the Hallmark Properties; to fulfill your requests; to cooperate in a law enforcement investigation, an investigation on a public safety matter, or an investigation into claims of intellectual property infringement; to protect and defend the legal rights and/or property of Hallmark and any of our corporate parents, subsidiaries, affiliates, and shareholders, or the Hallmark Properties, any of its users, or any other party; or, in an emergency, to protect the health and safety of Users or the general public.</p>
  </section>
  <section class="section" id="section-third-party-advertising">
    <h3 class="section-title">Third Party Advertising and Analytics</h3>
    <p>Hallmark works with third party business partners, including online advertising companies, ad agencies, and analytics providers to serve Hallmark ads online, and to provide us with information regarding the use of the Hallmark Properties and the effectiveness of our advertisements. These third parties may set and access their own tracking technologies (like cookies and web tags) on your device, and they may otherwise collect or have access to Usage Information and other information about you, some of which may be personal information or personal data under various state laws. Some of these parties may collect personal information over time when you visit the Hallmark Properties or other online websites and services. We may share information, typically information that has been aggregated or de-identified, pseudonymized, Usage Information, and location information with third party advertising companies, analytics providers, and other third parties, including for the purpose of serving you more relevant ads on and off the Hallmark Properties.</p>
    <p>We use a variety of third parties to perform advertising and analytics services, and some of these companies may be members of the Network Advertising Initiative (“NAI”) or Digital Advertising Alliance (“DAA”). The NAI provides information regarding targeted advertising and the <a href="https://optout.networkadvertising.org" target="_blank">opt-out procedures of NAI members</a>, including opt-out mechanisms for web browsers, mobile and other connected devices, and a tool to opt out from participating NAI members using your hashed email address for interest-based advertising. You may also want to visit the <a href="https://optout.aboutads.info/" target="_blank">DAA's website</a>, which provides information regarding targeted advertising and offers an opt-out for DAA-participating companies.</p>
    <p>Mobile devices may contain settings that allow you to disable tracking advertising and/or analytics. In addition, the digital advertising industry has provided the ability for mobile users to register at <a href="https://youradchoices.com/appchoices" target="_blank">AppChoices</a> for their intent not to receive targeted advertising .</p>
    <p>We use Google Analytics and Adobe Analytics, which use cookies and similar technologies to collect and analyze information about the use of the Hallmark Properties and report on activities and trends. These services may also collect information about the use of other websites, apps, and online services. You can <a href="https://policies.google.com/technologies/partner-sites" target="_blank">learn about Google's practices</a>, and opt out of them by downloading the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">Google Analytics opt-out browser add-on.</a>&nbsp;<a href="https://www.adobe.com/privacy.html" target="_blank">Learn about Adobe's practices here.</a>&nbsp;</p>
    <p>Please note that opting out through these mechanisms does not opt you out of being served advertising, and that you will continue to receive generic ads while online.</p>
    <p>If you are a California resident, please see our NOTICE TO CALIFORNIA RESIDENTS regarding additional rights you have, including how to exercise your “Do Not Sell” right.</p>
  </section>
  <section class="section" id="section-do-not-track">
    <h3 class="section-title">“Do Not Track” and Global Privacy Control Signals</h3>
    <p>Do Not Track (“DNT”) signals offered by some web browsers are settings that request that a web application disable its tracking of an individual user. When you choose to turn on the DNT setting in your browser, your browser sends a special signal to websites, analytics companies, ad networks, plug in providers, and/or other web services you encounter while browsing to stop tracking your activity. You can learn more about and <a href="http://www.allaboutdnt.com" target="_blank">set up Do Not Track here</a>.</p>
    Global Privacy Control (“GPC”) is a technical specification in your browser settings that you can use to automatically inform websites of your privacy preferences with regard to third party online tracking. You can find out more about and <a href="https://globalprivacycontrol.org/#about" target="_blank">set up GPC here</a>.&nbsp;
    <p>&nbsp;</p>
  </section>
  <section class="section" id="section-your-choices">
    <h3 class="section-title">Your Choices about the Information We Collect</h3>
    <p>We may provide you with the option to update your information or to opt out of marketing communications through the profile and/or preferences section of your account. In addition, you may opt-out of marketing communications as set forth below.</p>
    <p><strong>Email.</strong> You may unsubscribe from marketing emails we send you through the opt-out mechanism included in each such email. Note, however, that we reserve the right to send you certain communications relating to your use of the Hallmark Properties, notices of changes to this Privacy Policy or other terms, or similar administrative and transactional messages.</p>
    <p><strong>Text Messages.</strong> If you sign up to receive SMS, MMS or RCS messages from Hallmark or one of our brands, you may unsubscribe by replying “STOP” to the short code.</p>
    <p><strong>Push Notifications.</strong> We may send promotional and non-promotional push notifications or alerts to your mobile device, and may in some cases do so based on your device’s location or physical proximity to a beacon. You can elect to stop receiving those messages at any time by changing the notification settings on your mobile device.</p>
    <p><strong>Access/Delete.</strong> We offer our users the ability to request access to personal information we have collected about you or that we delete personal information. To make a request to access, click here: <a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Know-Right-to-Access" target="_blank">Request to Access — All U.S. States.</a>. To make a request to delete, click here: <a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Delete" target="_blank">Request to Delete — All U.S. States</a>. We will take reasonable steps to accommodate your request, but we will need to verify your identity before doing so. If you are a resident of a state with specific rights, please see additional information below.</p>
    <p><strong>Other State Privacy Rights.</strong> If you are a California, Nevada, Virginia, Colorado, Connecticut or Utah resident, please see the following notices for additional information about how to exercise your privacy rights.&nbsp;</p>
    <ul>
      <li><a href="#section-notice-to-callifornia-residents">California</a></li>
      <li><a href="#section-notice-to-colorado-residents">Colorado</a></li>
      <li><a href="#section-notice-to-connecticut-residents">Connecticut</a></li>
      <li><a href="#section-nevada-opt-out">Nevada</a></li>
      <li><a href="#section-notice-to-utah-residents">Utah</a></li>
      <li><a href="#section-notice-to-virginia-residents">Virginia</a></li>
    </ul>
  </section>
  <section class="section" id="section-security">
    <h3 class="section-title">Security</h3>
    <p>Hallmark takes commercially reasonable steps designed to secure your personal information; however no data transmission over the Internet, wireless transmission, or electronic storage of information can be guaranteed to be 100% secure. Hallmark cannot ensure or warrant the security of any information we collect. You use the Hallmark Properties and provide us your information at your own risk.</p>
  </section>
  <section class="section" id="section-third-party-content-links">
    <h3 class="section-title">Third Party Content and Links</h3>
    <p>The Hallmark Properties may contain content that is served by someone else or links to third-party content or websites. Hallmark does not control this content or these sites. The third-party operators and content providers use their own cookies or other tracking technologies, and they may independently collect information from or about you. Hallmark is not responsible for the privacy practices employed by any third party.</p>
  </section>
  <section class="section" id="section-consent-to-transfer">
    <h3 class="section-title">Consent to Transfer</h3>
    <p>Hallmark is based in the United States. Please be aware that your connection will be through and to servers located in the U.S. and other countries. By using the Hallmark Properties or providing us with any information, you fully understand and unambiguously consent to this transfer to, and processing, usage, sharing and storage of your information in the United States and in other jurisdictions, for which the privacy laws may not be as comprehensive as those in the country where you reside and/or are a citizen. As a result, this information may be subject to access requests from governments, courts, or law enforcement in the United States and other countries according to the laws in those jurisdictions. Your information will also be disclosed to third parties as described in the “How We Share Your Information” section above.</p>
  </section>
  <section class="section" id="section-contact-us">
    <h3 class="section-title">How to Contact Us</h3>
    <p>You may contact us by using the <a href="https://care.hallmark.com/s/contactsupport">Email Us </a>or Chat links on our <a href="https://care.hallmark.com/s/">Consumer Care site</a>, or calling us at 1-800-HALLMARK. The information you provide is used to respond directly to your questions or comments. We may also file your comments and share them with our consumer care team to improve our service in the future. If you still have questions or concerns about our privacy policy, please write us at:</p>
    <p>Hallmark Consumer Care<br/>
      P.O. Box 419034<br/>
      Kansas City, MO 64141</p>
  </section>
  <section class="section" id="section-canadian-residents">
    <h3 class="section-title">Notice to Canadian Residents</h3>
    <p>We make reasonable efforts to notify you of the purposes for which personal information will be used or disclosed and if the initial purpose changes. Please note, you may withdraw consent at any time (subject to legal or contractual restrictions and reasonable notice). Subject to certain limits set out in the applicable laws, Canadian residents also have the right to request access to the personal information that we collect and to update or correct personal information if it is inaccurate.</p>
    <p>If you are a Canadian resident and would like to submit a request to access your personal information or to withdraw consent to the processing of your personal data, you must submit your request by using the Email Us or Chat links on our Consumer Care site, or calling us at 1-800-HALLMARK. You may also use this contact to submit any requests for information on our privacy practices or to submit a complaint regarding our practices.</p>
    <p>Your request or complaint must include your full name, street address, city, province, postal code, and an email address so that we are able to contact you if needed regarding this request.</p>
    <p>We cannot properly process requests or complaints that do not come through the designated request mechanism or do not contain sufficient information to allow us to process your request. You may also be required to take reasonable steps as we determine from time to time in order to verify your identity and/or the authenticity of the request. Once your request is processed, absent exemptions, we will provide you with details regarding what personal information we have, how it is used, and with which third parties it is shared.</p>
    <p>Canadian residents may have additional rights under Canadian law, including the Personal Information Protection and Electronic Documents Act (PIPEDA) and successor legislation. Please see the website of the <a href="https://www.priv.gc.ca/" target="_blank">Office of the Privacy Commissioner of Canada</a> for further information.</p>
    <p>Canadian residents also may learn more about interest-based advertisements from participating third parties in Canada at the Canadian DAA choice page at <a href="https://youradchoices.ca/" target="_blank">https://youradchoices.ca/</a>.</p>
    <p>You and we confirm that it is our wish that this Privacy Policy, the Terms of Use, and all other related policies be drawn up in English. <em>Vous reconnaissez avoir exigé la rédaction en anglais du présent document ainsi que tous les documents qui s'y rattachent.</em></p>
  </section>
  <section class="section" id="section-nevada-opt-out">
    <h3 class="section-title">Notice to Nevada Residents</h3>
    <p>Consumer Opt Out Requests: Nevada S.B. 220 provides consumers with the ability to opt out of the sale of their personal information. We do not sell your personal information as defined in S.B. 220. However, if you are a Nevada resident, you may still submit an opt-out request, and if our practices were to change in the future, we would honor your request. Your request to opt out of the sale of your covered information must be submitted to <strong>OptOut@hallmark.com</strong>. Your request must include your full name, street address, city, state, zip code, and a valid email address so that we can contact you if needed regarding your request. If you previously provided a phone number, including it will assist us in making sure we identify you as someone who wants to opt out. You may be required to take reasonable steps as we determine from time to time to verify your identity and/or the authenticity of your request.</p>
    <section class="section" id="section-notice-to-virginia-residents">
      <h3 class="section-title">Notice to Virginia Residents</h3>
      <p>We set forth above in our Privacy Policy the categories of personal data we process, the purpose for processing personal data, the categories of personal data shared, and the categories of third parties with whom personal data is shared.</p>
      <p>If you are a Virginia Consumer and would like to exercise your rights pursuant to the Virginia Consumer Data Protection Act (VCDPA), and any implementing regulations adopted thereunder, please complete the appropriate form below&nbsp;or call 1-877-203-2262 to submit a request.</p>
      <p>Virginia Consumers have the following rights, all subject to the meanings and exceptions set forth in the VCDPA:</p>
      <ul class="unordered-list gutter-bottom-large">
        <li>To confirm whether we are processing your Personal Data and request to access such data (<strong>"</strong><a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Know-Right-to-Access" target="_blank">Right to Access</a><strong>")</strong>.</li>
        <li>That we correct inaccurate Personal Data we hold about you (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Correct" target="_blank">Right to Correct”</a>).</li>
        <li>That we delete the Personal Data provided by you or obtained about you ("<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Delete" target="_blank">Right to Delete</a>").</li>
        <li>To obtain a copy of the Personal Data previously provided by you to us and, to the extent feasible, in a readily usable format to allow data portability (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Know-Right-to-Access" target="_blank">Right to Obtain</a>”)</li>
        <li>To opt-out out of:
          <ul class="unordered-list gutter-bottom-small">
            <li>&nbsp;the processing of your Personal Data for the purposes of targeted advertising (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Opt-Out" target="_blank">Right to Opt-Out of Targeted Advertising</a>”).
              <ul class="unordered-list gutter-bottom-small">
                <li>Hallmark engages in online advertising practices (and certain analytics or similar activities) that may be considered “targeted advertising” under the VCDPA. <strong>To disable sharing through cookies set by 3rd parties that may be considered targeted advertising under the VCDPA, Hallmark uses the OneTrust cookie manager to permit you to adjust your cookie settings.</strong></li>
              </ul>
            </li>
          </ul>
        </li>
        <li>To opt-in to the processing of Sensitive Data (“Right to Opt-In to Processing of Sensitive Data”). If applicable, we will obtain your consent to processing of Sensitive Data we collect.</li>
      </ul>
      <p><strong>VCDPA Appeals</strong></p>
      <p>Pursuant to the VCDPA, if, for any reason, you would like to appeal our decision relating to your request, you have the right to submit an appeal and can do so by <a href="https://care.hallmark.com/s/ccpa-form?actionType=Request-to-Submit-Privacy-Rights-Appeal-Virginia" target="_blank">submitting this form</a>&nbsp;or calling 1-877-203-2262. Please include your full name, the basis for your appeal, and any additional information to consider.</p>
      <section class="section" id="section-notice-to-callifornia-residents">
        <h3 class="section-title">Notice to California Residents</h3>
        <p>Please see our <a href="https://www.hallmark.com/privacy-notice/">Notice to California Residents</a>.</p>
        <p><strong>QUESTIONS AND CONTACT INFORMATION</strong></p>
        <p>If you have questions about any of these rights, please call us at 1-877-203-2262.</p>
        <p><strong>Other California Privacy Rights</strong></p>
        <p>California’s "Shine the Light" law (Civil Code Section 1798.83, also known as S.B. 27) allows California residents to request from companies conducting business in California certain information relating to third parties to which the company has disclosed certain categories of personal information during the preceding year for the third parties’ direct marketing purposes. Alternatively, the law provides that a company may comply, as Hallmark does, by disclosing in its privacy policy that it provides consumers choice (opt in or opt out) regarding sharing personal information with third parties for those third parties’ direct marking purposes, and information on how to exercise that choice. As stated in this Privacy Policy, Hallmark provides you choice prior to sharing your personal information with third parties for their direct marketing purposes. If you do not opt-in or if you opt-out at the time we offer that choice, we do not share your information with that identified third party for its direct marketing purposes.</p>
        <p>If you are a California resident and you have questions about our practices with respect to sharing information with third parties for their direct marketing purposes and your ability to exercise choice, please write to us at Hallmark Cards Consumer Care; P.O. Box 419034; Kansas City, MO 64141, or call 1-800-HALLMARK. You must put the statement “Your California Privacy Rights” in your writing if you choose to write to us at the designated mailing address. You must include your name, street address, city, state, and zip code. We are not responsible for notices that are not labeled or sent properly, or do not have complete information.</p>
        <section class="section" id="section-notice-to-colorado-residents">
          <h3 class="section-title">Notice to Colorado Residents</h3>
          <p>We set forth above in our Privacy Policy the categories of personal data we process, the purpose for processing personal data, the categories of personal data shared, and the categories of third parties with whom personal data is shared.</p>
          <p>If you are a Colorado Consumer and would like to exercise your rights pursuant to the Colorado Privacy Act (CoPA), and any implementing regulations adopted thereunder, please complete the appropriate form below&nbsp;or call 1-877-203-2262 to submit a request.</p>
          <p>Colorado Consumers have the following rights, all subject to the meanings and exceptions set forth in the CoPA:</p>
          <ul class="unordered-list gutter-bottom-large">
            <li>To confirm whether we are processing your Personal Data and request to access such data ("<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Know-Right-to-Access" target="_blank">Right to Access</a>"). If you desire to receive your Personal Data in a portable format, please submit a Right to Obtain request below.</li>
            <li>That we correct inaccurate Personal Data we hold about you (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Correct" target="_blank">Right to Correct”</a>).</li>
            <li>That we delete the Personal Data provided by you or obtained about you ("<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Delete" target="_blank">Right to Delete</a>").</li>
            <li>To receive a copy of the Personal Data we hold about you in a readily usable format to allow data portability, to the extent feasible (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Know-Right-to-Access" target="_blank">Right to Obtain</a>”).</li>
            <li>That we will not discriminate from your data subject requests ("<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Nondiscrimination-Colorado" target="_blank">Right to Non-Discrimination</a>").</li>
            <li>To opt-out out of:
              <ul class="unordered-list gutter-bottom-small">
                <li>&nbsp;the processing of your Personal Data for the purposes of targeted advertising (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Opt-Out" target="_blank">Right to Opt-Out of Targeted Advertising</a>”).
                  <ul class="unordered-list gutter-bottom-small">
                    <li>Hallmark engages in online advertising practices (and certain analytics or similar activities) that may be considered “targeted advertising” under the CoPA. <strong>To disable sharing through cookies set by 3rd parties that may be considered targeted advertising under the CoPA, Hallmark uses the OneTrust cookie manager to permit you to adjust your cookie settings</strong></li>
                  </ul>
                </li>
                <li>the sale of your Personal Data ("<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Opt-Out" target="_blank">Right to Opt-Out of Sale</a>"). Hallmark does not sell Personal Data for monetary consideration, but certain disclosures of Personal Data may be considered a "sale" under the CoPA.</li>
              </ul>
            </li>
            <li>To opt-in to the processing of Sensitive Data (“Right to Opt-In to Processing of Sensitive Data”). If applicable, we will obtain your consent to processing of Sensitive Data we collect. You may revoke this consent at any time, subject to application exceptions under law (<strong>"</strong><a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Opt-Out" target="_blank">Right to Revoke Consent for Sensitive Data Processing</a><strong>."</strong>)</li>
          </ul>
          <p><strong>CoPA Appeals</strong></p>
          <p>Pursuant to the CoPA, if, for any reason, you would like to appeal our decision relating to your request, you have the right to submit an appeal and can do so by <a href="https://care.hallmark.com/s/ccpa-form?actionType=Request-to-Submit-Privacy-Rights-Appeal-Virginia" target="_blank">submitting this form</a> or calling 1-877-203-2262. Please include your full name, the basis for your appeal, and any additional information to consider.</p>
          <section class="section" id="section-notice-to-connecticut-residents">
            <h3 class="section-title">Notice to Connecticut Residents</h3>
            <p>We set forth above in our Privacy Policy the categories of personal data we process, the purpose for processing personal data, the categories of personal data shared, and the categories of third parties with whom personal data is shared.</p>
            <p>If you are a Connecticut Consumer and would like to exercise your rights pursuant to the Connecticut Act Concerning Personal Data Privacy and Online Monitoring (CtPA), and any implementing regulations adopted thereunder, please complete the appropriate form below&nbsp;or call 1-877-203-2262 to submit a request.</p>
            <p>Connecticut Consumers have the following rights, all subject to the meanings and exceptions set forth in the CtPA:</p>
            <ul class="unordered-list gutter-bottom-large">
              <li>To confirm whether we are processing your Personal Data and request to access such data ("<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Know-Right-to-Access" target="_blank">Right to Access</a>"). If you desire to receive your Personal Data in a portable format, please submit a Right to Obtain request below.</li>
              <li>That we correct inaccurate Personal Data we hold about you (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Correct" target="_blank">Right to Correct”</a>).</li>
              <li>That we delete the Personal Data provided by you or obtained about you ("<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Delete" target="_blank">Right to Delete</a>").</li>
              <li>To receive a copy of the Personal Data we hold about you in a readily usable format to allow data portability, to the extent feasible (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Know-Right-to-Access" target="_blank">Right to Obtain</a>”).</li>
              <li>To opt-out out of:
                <ul class="unordered-list gutter-bottom-small">
                  <li>&nbsp;the processing of your Personal Data for the purposes of targeted advertising (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Opt-Out" target="_blank">Right to Opt-Out of Targeted Advertising</a>”).
                    <ul class="unordered-list gutter-bottom-small">
                      <li>Hallmark engages in online advertising practices (and certain analytics or similar activities) that may be considered “targeted advertising” under the CtPA. <strong>To disable sharing through cookies set by 3rd parties that may be considered targeted advertising under the CtPA, Hallmark uses the OneTrust cookie manager to permit you to adjust your cookie settings</strong></li>
                    </ul>
                  </li>
                  <li>the sale of your Personal Data ("<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Opt-Out" target="_blank">Right to Opt-Out of Sale</a>"). Hallmark does not sell Personal Data for monetary consideration, but certain disclosures of Personal Data may be considered a "sale" under the CtPA.</li>
                </ul>
              </li>
              <li>To opt-in to the processing of Sensitive Data (“Right to Opt-In to Processing of Sensitive Data”). If applicable, we will obtain your consent to processing of Sensitive Data we collect. You may revoke this consent at any time, subject to application exceptions under law (<strong>"</strong><a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Opt-Out" target="_blank">Right to Revoke Consent for Sensitive Data Processing</a><strong>."</strong>)</li>
            </ul>
            <p><strong>CtPA Appeals</strong></p>
            <p>Pursuant to the CtPA, if, for any reason, you would like to appeal our decision relating to your request, you have the right to submit an appeal and can do so by <a href="https://care.hallmark.com/s/ccpa-form?actionType=Request-to-Submit-Privacy-Rights-Appeal-Virginia" target="_blank">submitting this form</a> or calling 1-877-203-2262. Please include your full name, the basis for your appeal, and any additional information to consider.</p>
            <section class="section" id="section-notice-to-utah-residents">
              <h3 class="section-title">Notice to Utah Residents</h3>
              <p>We set forth above in our Privacy Policy the categories of personal data we process, the purpose for processing personal data, the categories of personal data shared, and the categories of third parties with whom personal data is shared.</p>
              <p>If you are a Utah Consumer and would like to exercise your rights pursuant to the Utah Consumer Privacy Act (UCPA), and any implementing regulations adopted thereunder, please complete the appropriate form below or call 1-877-203-2262 to submit a request.</p>
              <p>Utah Consumers have the following rights, all subject to the meanings and exceptions set forth in the UCPA:</p>
              <ul class="unordered-list gutter-bottom-large">
                <li>To confirm whether we are processing your Personal Data and request to access such data ("<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Know-Right-to-Access" target="_blank">Right to Access</a>"). If you desire to receive your Personal Data in a portable format, please submit a Right to Obtain request below.</li>
                <li>That we correct inaccurate Personal Data we hold about you (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Correct" target="_blank">Right to Correct”</a>).</li>
                <li>That we delete the Personal Data provided by you or obtained about you ("<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Delete" target="_blank">Right to Delete</a>").</li>
                <li>To receive a copy of the Personal Data we hold about you in a readily usable format to allow data portability, to the extent feasible (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Know-Right-to-Access" target="_blank">Right to Obtain</a>”).</li>
                <li>That we will not discriminate from your data subject requests ("<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Avoid-Discrimination-Utah" target="_blank">Right to Avoid Discrimination</a>").&nbsp;</li>
                <li>To opt-out out of:
                  <ul class="unordered-list gutter-bottom-small">
                    <li>&nbsp;the processing of your Personal Data for the purposes of targeted advertising (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Opt-Out" target="_blank">Right to Opt-Out of Targeted Advertising</a>”).
                      <ul class="unordered-list gutter-bottom-small">
                        <li>Hallmark engages in online advertising practices (and certain analytics or similar activities) that may be considered “targeted advertising” under the UCPA. <strong>To disable sharing through cookies set by 3rd parties that may be considered targeted advertising under the UCPA, Hallmark uses the OneTrust cookie manager to permit you to adjust your cookie settings.</strong></li>
                      </ul>
                    </li>
                    <li>the processing of Sensitive Data (“<a href="https://care.hallmark.com/s/ccpa-form?actionType=Right-to-Opt-Out" target="_blank">Right to Opt-Out of Sensitive Data Processing</a>”).</li>
                  </ul>
                </li>
              </ul>
            </section>
          </section>
        </section>
      </section>
    </section>
  </section>
</div>

    );


  return(
    
    <div className="screen-wrap" >
      { getQRKey() ? 
        <NavLogo />
        :
        <NavBar />
      }
    
      <MainContent className="container">
        <BackLink title="Back"/>
        
        <div className="ugly_markup">  
          {hmkraw}
        </div>
        <div style={{height:"200px"}}>&nbsp;</div>
      </MainContent>
      <Footer hideMobile={true} sticky={true}/>
    </div>
  );
}