


export default {

  HALLMARK_LANDING_PAGE: "https://www.hallmark.com/video-greeting-cards/?icid=vg_bp_110121_create-greeting-QR",

  HALLMARK_LANDING_FROM_DIGITAL_THANK_YOU: "https://www.hallmark.com/video-greeting-cards/?icid=vg_bp_110121_create-greeting-digital",

  HALLMARK_LANDING_FROM_INVITE: "https://www.hallmark.com/video-greeting-cards/?icid=vg_bp_110121_create-greeting-upload",

  HALLMARK_LANDING_FROM_PDP_AD: "https://www.hallmark.com/video-greeting-cards/?icid=vg_bp_013022_pdp_ad",

};