import React, { Component } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { apiFetch } from "../helpers/APIHelper";
import IdObjectMemCache from "../helpers/IdObjectMemCache";
import Plyr from "react-plyr";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import menuclose from "../assets/images/HMK-Icons/Icons_Menu_Close_Bold.svg";
import BackLink from "../components/BackLink";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import StandardModal from "../modal/StandardModal";
import { isLoggedIn, logToServer } from "../helpers/APIHelper";
import { isBadQRReaderApp } from "../helpers/URLHelper";

export class PreviewFizz extends Component {
  constructor(props) {
    super(props);

    this.isFizzCheckingAllowed = true;

    this.state = {
      isLoading: true,
      fizzId: props.match.params.fizzId,
      isDone: false,
      isStatus: false, 
      video: null,
      isVideoFinalised: false,
      showFinalClip: false,
      img: null,
      errorMessage:null,
      showModal: null,
      isThankyou: props.isThankyou
    };
    this.plyr = React.createRef();
    this.playScroll = React.createRef();
  }

  componentDidMount() {

    logToServer( 
      'PreviewFizz.Load' +
      ' webView=' + ( isBadQRReaderApp() ? 'bad ' : 'good ' ) +
      ' innerHeight=' + window.innerHeight + 
      ' innerWidth=' + window.innerWidth + 
      ' screenHeight=' + window.screen.height + 
      ' screenWidth=' + window.screen.width + 
      ' localStorage=' + (!!localStorage)+ 
      ' sessionStorage=' + (!!sessionStorage)
    );
    this.combineClips();
    
    document.title = 'Preview | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    TrackingHelper.trackPage();
  }

  componentWillUnmount() {
    clearTimeout(this.checkFizzTimeout);
    this.isFizzCheckingAllowed = false;
  }

  closeCurrentVideo = () => {
    // alert("close working");
    this.setState({
      showFinalClip: false,
    });
  };


  combineClips() {
    apiFetch(
      "/rest/fizz/" + this.state.fizzId + "/new-finalize",
      {
        method: "PUT",
      },
      (json) => {

        if( json.isLocked && !json.isThankyou ){
          // Fizz is locked - prevent any edits 
          window.location = "/list";
          return;
        }

        this.setState({
          img: json.img,
          video: json.video,
          isDone: !!json.video,
          isLoading: false,
          showModal: json.modal,
          isThankyou: json.isThankyou,
          title: json.title,
          body: json.body,
          isQRBoxSet: json.isQRBoxSet,
          isPhysical: json.isPhysical
        })

        IdObjectMemCache('fizz').setItem({
          id:this.state.fizzId,
          img:json.img,
        });
        if( !json.video ) {
          this.checkProcessingFizzes();
        }
      },
      (error) => {
        console.log(error)
        this.setState({ errorMessage: error.message });
      }
    );
  }

  checkProcessingFizzes() {
    var needsCheck = false;

    apiFetch(
      "/rest/fizz/process/" +
        this.state.fizzId +
        "?cache=" +
        Math.round(Math.random() * 100000000),
      {
        method: "GET",
      },
      (json) => {
        console.log(" JSON STRINGIFY RESPONSE" + json);
        this.setState({
          img: json.img || this.state.img,
          isDone: json.isDone,
          isStatus: json.status,
          isLoading: false,
          video: json.video,
        });

        if (!json.isDone && this.isFizzCheckingAllowed) {
          this.checkFizzTimeout = setTimeout(() => {
            this.checkProcessingFizzes();
          }, 3000);
        } else {
          TrackingHelper.trackPreview();
        }
      },
      (error) => {
        if (this.isFizzCheckingAllowed) {
          this.checkFizzTimeout = setTimeout(() => {
            this.checkProcessingFizzes();
          }, 3000);
        }
      }
    );
  }



  confirmModal = () => {

    // Just close the modal to get it out of there way
    this.setState({
      showModal:null
    })

    apiFetch(
      "/rest/fizz/" + this.state.fizzId + "/confirm-finalize-check",
      {
        method: "POST",
      },
      (json)=> {
        // Don't do anything we already closed the modal, lets just hope the server saved it
      },
      (error) => {
        // Don't do anything we already closed the modal, lets just hope the server saved it
      }
    );
  }



  showFinalClipModal = () => {
    this.setState({ showFinalClip: true });
  };

  continueFinalClipmodal = () => {


    logToServer( 
      'PreviewFizz.Lockdown' +
      ' webView=' + ( isBadQRReaderApp() ? 'bad ' : 'good ' ) +
      ' innerHeight=' + window.innerHeight + 
      ' innerWidth=' + window.innerWidth + 
      ' screenHeight=' + window.screen.height + 
      ' screenWidth=' + window.screen.width + 
      ' localStorage=' + (!!localStorage)+ 
      ' sessionStorage=' + (!!sessionStorage)
    );

    this.setState({ showFinalClip: false });

    apiFetch(
      "/rest/fizz/" + this.state.fizzId + "/lockdown",
      {
        method: "POST",
      },
      (json) => {
        console.log(json);
        IdObjectMemCache('fizz').setItem({
          id:this.state.fizzId,
          name:json.name,
          price:json.price,
          img:json.img
        });
        

        // redirect user to correct route.
        if( isLoggedIn() ){
          if( !json.needsPurchase ){
            // Already paid
            if( json.isQRBoxSet ){
              window.location = "/thank-you-qr-box/"+this.state.fizzId
              return;
            } else if ( json.isQRCard ) {
              window.location = "/thank-you-qr/"+this.state.fizzId
              return;
            } else if ( this.state.isThankyou ) {
              window.location = "/thank-you-reply/" + this.state.fizzId;
              return;
            } else { 
              window.location = "/thank-you-free/"+this.state.fizzId
              return;
            }
          } else {
            // needs to pay
            window.location = "/checkout/" + this.state.fizzId;
            return;

          }
        } else {

          if( !json.needsPurchase ){
            // Already paid
            window.location = "/signup-thank-you/" + this.state.fizzId;
            return;
          } else {
            window.location = "/signup-checkout/" + this.state.fizzId;
            return;
          }
        }

      },
      (error) => {
        console.log(error);
        this.setState({
          errorMessage: error.message,
        });
      }
    );
  };

  goBack = () => {
    this.props.history.goBack();
  };
  playVideo = () => {
    if( this.plyr && this.plyr.current ) {

      this.plyr.current.play();
      // Not sure why scrollIntoView is not a function. 
      if( this.playScroll && this.playScroll.current ) {
        this.playScroll.current.scrollIntoView();
      }
    }
  }


  render() {

    return (
      <div className="screen-wrap">
        <NavBar title="Your Project" 
          allowSignUpAlert = { !isLoggedIn() }
          fizzId = { this.state.fizzId }
        />
        <MainContent className="container bg-margin">

          {this.renderContent()}

          <Modal
            show={this.state.showFinalClip}
            onHide={this.closeCurrentVideo}
          >
            <Modal.Header className="text-left">
              <div className="icon-box" onClick={this.closeCurrentVideo}>
                <span className="close">
                  <img src={menuclose} alt="menuclose" />
                </span>
              </div>

              <h3 className="headline-text">Are you sure it's finished?</h3>
            </Modal.Header>

            { this.state.isQRBoxSet && 
              <Modal.Body>
                 <h5 className="headline-text mt-2 mb-2">
                  Last chance to make changes.
                </h5>
              </Modal.Body>
            }
            
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-primary btn-block"
                onClick={this.continueFinalClipmodal}
              >
                Yes, it's done
                <p className="mt-2 mb-0" style={{textTransform:'none'}}>Once finished, no more photos or videos can be added.</p>
              </Button>

              <button
                className="btn btn-outline-primary btn-block"
                onClick={this.closeCurrentVideo}
              >
                No, keep editing
              </button>
            </Modal.Footer>
          </Modal>
          

          <StandardModal
            show={!!this.state.showModal}
            onHide={this.confirmModal}
            header={(this.state.showModal||{}).header}
            confirmBtnText={(this.state.showModal||{}).confirmBtnText}
            closeBtnText={false}
            onConfirm={this.confirmModal} 
          >
          {(this.state.showModal||{}).body}
          </StandardModal>

        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }

  renderContent = ()=> {

    if( this.state.isLoading ) {
      return <Spinner float={true} />
    }


    var buttons = (
      <>
        { this.state.isThankyou ? (
          <>
            {this.state.isDone && (

              <button
                  type="button"
                  className="btn btn-primary btn-block btn-lg-inline-block m-lg-2"
                  onClick={this.continueFinalClipmodal}
                  >
                Send
              </button>
            )}
          </>
        ) : (

            <>
              {this.state.isDone && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-primary d-none d-lg-inline-block btn-lg-inline-block mr-lg-1"
                    onClick={this.playVideo}
                  >
                    Preview
                  </button>
                </>
              )}
              
              {this.state.isDone && (
                 <>
                  <button
                    type="button"
                    className="btn btn-primary btn-block btn-lg-inline-block"
                    onClick={this.showFinalClipModal}
                  >
                    It's finished
                  </button>
                  </>
              )}

              <Link to={"/edit/" + this.state.fizzId } className="btn btn-outline-primary btn-block btn-lg-inline-block ml-lg-1">
                Back To Edit
              </Link>

            </>
          )
        } 
      </>
    )


    return (
      <>
          { !this.state.isThankyou ?
            <BackLink
              to={"/edit/"+this.state.fizzId }
              title="Back to Edit"
            />
            :
            <BackLink
              to={ "/record-thank-you/"+this.state.fizzId }
              title="Back"
            />
          }

          { this.state.isPhysical && isBadQRReaderApp() &&
            <div className="container">
                <div className="col-md-6 card-orange pb-3">
                  <h2 className="headline-text">Are you using a QR Reader app?</h2>
                  <p className="card-body mb-1 pb-1">
                    We have detected that the browser you are in may have issues with the screen freezing.  If you are experiencing any issues please use the same camera app you use for taking pictures to scan the QR Code.
                  </p>
                </div>
              </div>
          }
          
          <h1 className="headline">{this.state.title || 'Preview your Video Greeting'}</h1>

          <div className="row" >
            <div className="col-12 col-lg-6 col-xl-7" >

              <h6 className="subheadline mb-3">
              {this.state.body || 'Your Video Greeting isn’t final yet, and you can keep editing. Once you finish it, you won’t be able to make any more\u00A0changes.'}
              </h6>

            </div>
            <div ref={this.playScroll} className="col-12 col-lg-6 col-xl-5 text-right d-none d-lg-block mt-3" >
              {buttons}
            </div>
          </div>


          {this.state.errorMessage && (
              <p className="alert alert-danger mt-2">
              {this.state.errorMessage}
              </p>
          )}

          <div className="text-center">
            <div className="position-relative">
              { !this.state.isDone &&
                (<>
                  <div className="preview-processing-wrap" >
                    <div className="preview-processing">
                      <div className="preview-processing-text">Your Video Greeting is being prepared and will display here shortly.</div>
                      <div className="text-center">
                        <Spinner/>
                      </div>
                    </div>
                  </div>
                </>)
              }
              { this.state.isDone && this.state.video ?
                <Plyr
                  ref={this.plyr}
                  type="video" // or "vimeo"
                  poster={this.state.img}
                  url={this.state.video}
                  autoplay
                />
                :
                <img
                  className="video-fit"
                  src={this.state.img}
                  alt="Your Video Will Play Here"
                ></img>
              }
            </div>
          </div>
          <div className="mt-3 text-center w-100 d-block d-lg-none">
          {buttons}
          </div>
        </>
      );
  }
}

export default PreviewFizz;
