import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import moment from 'moment';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import SharePopup from "../modal/SharePopup";
import Spinner from "../components/Spinner";
import Plyr from "react-plyr";
import { Link } from "react-router-dom";
import { apiFetch } from "../helpers/APIHelper";
import FizzStatus from "../helpers/FizzStatus";
import MemCache from "../helpers/MemCache";
import StandardModal from "../modal/StandardModal";
import { canShare, share } from "../helpers/URLHelper";

import TrashCan from "../assets/images/HMK-Icons/Icons_Trash_Regular.svg";
import Pencil from "../assets/images/HMK-Icons/Icons_Edit_Regular.svg";
import Share from "../assets/images/HMK-Icons/Icons_Share_Share_iOS_Bold.svg"
import ShoppingCart from "../assets/images/HMK-Icons/Icons_Cart_Cart_Empty_Bold.svg";
import PhysicalCard from "../assets/images/HMK-Icons/physical-card.svg";
import DigitalCard from "../assets/images/HMK-Icons/digital-card.svg";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";

export default class FizzList extends Component {
  constructor(props, context) {
    super(props, context);
    console.log("props:", props);

    this.popupVideoClose = this.popupVideoClose.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this._getData = this._getData.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.deleteFizzFunc = this.deleteFizzFunc.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    
    this.state = {
      popupVideoUrl: null,
      sharePopup: null,
      fizzlist: MemCache.getItem("fizzList"),
      sharetype: "share",
      loading: true,
      processingInterval: null,
      deleteFizz: null,
      inProcess: null,
      fizzID: null,
      viewTutorial: null,
      showChangeTemplate: false,
      templateFizz: null,
      templateMsg: "",
      showLoader: false,
      modalType: ""
    };
  }

  componentDidMount() {
    // console.log('componentWillMount');
    this._getData();

    document.title = 'My Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    // After first render
    var lastScroll = MemCache.getItem("lastScroll");
    if (lastScroll) {
      window.scroll(0, lastScroll);
    }
  }
  componentWillUnmount() {
    MemCache.setItem("lastScroll", window.scrollY);
  }


  deleteFizzFunc(fizz) {
    apiFetch(
      "/rest/fizz/" + fizz.id,
      {
        method: "DELETE",
      },
      (json) => {
        this.setState({
          deleteFizz: null,
        });

        var array = this.state.fizzlist;
        var index = array.indexOf(fizz);
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ fizzlist: array });
        }
      },
      (error) => {
        console.log(error);
        alert("Unable to delete item", error);
      }
    );
  }

  openDeleteModal(fizz) {
    this.setState({
      deleteFizz: fizz,
    });
  }

  closeDeleteModal() {
    this.setState({
      deleteFizz: null,
    });
  }

  popupVideoOpen = (fizz) => {
    this.setState({
      popupVideoUrl: fizz.video,
    });
  };
  popupVideoClose = () => {
    this.setState({
      popupVideoUrl: null,
    });
  };

  closePopup() {
    this.setState({ sharePopup: null });
  }

  openSharePopup(fizz, type) {

    if( canShare() ) {
      share({
        url: fizz.shareUrl
      })


    } else {
      this.setState({
        sharePopup: fizz,
        sharetype: type,
      });
    }
  }

  _getData = (backgroundRefresh) => {
    if (!backgroundRefresh) {
      this.setState({
        loading: true,
      });
    }

    apiFetch(
      "/rest/fizz/list?cache="+Math.round(Math.random() * 100000000),
      {
        method: "GET",
      },
      (json) => {
        MemCache.setItem("fizzList", json.items);
        this.setState({
          fizzlist: json.items,
          loading: false,
          noFizzCards: json.noFizzCards
        });

        TrackingHelper.trackPage();
      },
      (error) => {
        this.setState({
          loading: false,
          error: error+""
        });

        TrackingHelper.trackPage();
      }
    );

  };

  render() {

    var styles = {
      fullWidth: {
        width: "100%",
      },
      imagePadding: {
        padding: "2% 0",
      },
      fizzstyle: {
        textAlign: "left",
        paddingLeft: "10px",
      },
      upgradeButton: {
        backgroundColor: "#000000",
        color: "#ffffff",
      },
      upgradeButtonOutline: {
        border: "2px solid #000000",
        color: "#000000",
      },
      jumbotronBg: {
        backgroundColor: "#eeeeee",
      },
      viewMessage: {
        fontSize: "12px",
        margin: "10px",
      },
      viewCounterBorder: {
        border: "2px solid #55d209",
        padding: "4px",
      },
      deleteButton: {
        marginLeft: "1.4rem",
        fontSize: "16px",
        backgroundColor: "white",
        color: "#614095",
        fontWeight: "600",
      },
      deleteIconMargin: {
        margin: "0 5px !important",
      },
      editIconMargin: {
        margin: "0 5px !important",
      },
      buttonBg: {
        backgroundColor: "#614095",
        border: "0",
      },
      upgradeTextStyle: {
        padding: "0px 50px",
        textAlign: "center",
        fontSize: "0.8rem",
      },
      welcomeBg: {
        backgroundColor: "white",
      },
      fitContent: {
        width: "fit-content",
      },
      cardDescription: {
        fontWeight: "200",
        fontSize:"12px",
        marginBottom:"0"
      }
    };




    return (
      <div className="screen-wrap">
        <NavBar />
        <MainContent className="container">
          <h1 className="headline-text">My Video Greetings</h1>
          <p>The last day to complete a Video Greeting before service ends is February 20, 2025.
            <br/>Once you finish a project, it remains available to download for 6 months.</p>
          {this.state.loading && (
            <Spinner float={true} />
          )}
          {this.state.error && (
            <div className="alert alert-danger mt-3">
             {this.state.error}
            </div>
          )}
          <div className="">
          {this.state.noFizzCards &&
              this.state.noFizzCards.map((card, i) => {
                let qrCardImage = (
                  <div className="box-480">
                    <div className="box-480-item">
                      <img
                        src={card.image}
                        style={{maxWidth: '100%', maxHeight: '100%'}}
                        alt="card video"
                      />
                    </div>
                  </div>
                 );

                return (
                  <div className="row mt-3" key={card.qrKey}>
                    <div className="col-lg-4 d-none d-lg-block text-center">
                      {qrCardImage}
                    </div>

                    <div className="col-lg-8">
                      <div className="row" >
                        <div className="mb-2 col-12 text-right qr-no-fizz-create d-lg-none">
                            <Link className="btn btn-primary mb-2 btn-block" to={`/qr-setup/${card.qrKey}/create/${card.createThemeAnimationId}`}>
                              Setup This Card
                            </Link>
                        </div>

                        <div className="col-12 d-lg-none text-center">
                          {qrCardImage}
                        </div>
 
                        <div className="col-12">
                          <div className="d-flex mt-1">
                            <div>
                              <img style={{marginLeft:"-6px"}} src={ PhysicalCard  } alt="Card Type" />
                            </div>
                            <div className="flex-grow-1">
                                <div className="mt-2">
                                  <p style={styles.cardDescription}>Physical Card + Video</p> 
                                </div>
                            </div>

                            <div className="mb-2 text-right qr-no-fizz-create d-none d-lg-block">
                              <Link className="btn btn-primary mb-2" to={`/qr-setup/${card.qrKey}/create/${card.createThemeAnimationId}`}>
                                Setup This Card
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-12">
                      <hr/>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="">
            {this.state.fizzlist &&
              this.state.fizzlist.map((fizz) => {

                let fizzImage = (
                  <div className="box-480">
                    <div className="box-480-item">
                      {fizz.video && fizz.isLocked ? (
                        <button
                          className="link video-thumb"
                          tabIndex="0"
                          onClick={this.popupVideoOpen.bind(this, fizz)}
                        >
                          <img
                            src={fizz.image}
                            className="card-img"
                            alt="fizz video"
                          />
                        </button>
                      ) : (
                        <Link
                        to={`/preview/${fizz.id}`}
                        className='video-thumb'
                        >

                          <img
                            src={fizz.image}
                            className="card-img"
                            alt="fizz video"
                            />
                        </Link>
                      )}
                    </div>
                  </div>
                  );

                return (
                  <div className="row mt-3" key={"fizz" + fizz.id}>

                    
                    <div className="col-lg-4 d-none d-lg-block">
                      {fizzImage}
                    </div>


                    <div className="col-lg-8">
                      <div className="row" >
                        <div className="mb-2 col-8 ">
                          {fizz.name}
                        </div>
                        <div className="mb-2 col-4 text-right">
                          {!fizz.isLocked && (
                            <Link to={"/edit/" + fizz.id}>
                              <img src={Pencil} alt="Edit Fizz" />
                            </Link>
                          )}

                          {!fizz.hideDelete && (
                            <div className="delete_btn d-inline">
                              <button className="link" style={styles.deleteButton} onClick={this.openDeleteModal.bind(this, fizz)} >
                                <img src={TrashCan} alt="Delete Fizz" />
                              </button>
                            </div>
                          )}
                        </div>



                        <div className="col-12 d-lg-none">
                          {fizzImage}
                        </div>
 
                        <div className="col-12">
                          <div className="d-flex mt-1" >
                            <div>
                              <img style={{marginLeft:"-6px"}} src={fizz.isPhysical ? PhysicalCard : DigitalCard  } alt="Card Type" />
                            </div>
                            <div className="flex-grow-1">
                                <div className="mt-2">

                                    { fizz.isPhysical ?
                                      (fizz.isQRBoxSet? 
                                        <p style={styles.cardDescription}>Box Set + Video</p> 
                                        :
                                        <p style={styles.cardDescription}>Physical Card + Video</p> 
                                      )
                                    :
                                      <p style={styles.cardDescription}>Digital-Only Video Greeting</p>
                                    }
                                </div>
                                <p style={styles.cardDescription}>Started: {moment(fizz.fizzCreated+"+0000").format('ll')}</p>
                            </div>
                          </div>
                        </div>


                      { fizz.isLocked && 
                        ( fizz.shareUrl ? (
                            <div className="mt-2 col-12" >
                              <button className="btn btn-link pl-0 blue-link"
                                onClick={this.openSharePopup.bind( this, fizz, "share" )}
                              >
                                <i className="icon-mask" style={{ WebkitMaskImage:"url("+Share+")"}} alt="Email Icon"></i>
                                Share Video Greeting
                              </button>
                            </div>
                          )
                          : 
                          (
                            <div className="mt-2 col-12" >
                              <Link className="blue-link" 
                                to={"/checkout/" + fizz.id}
                              >
                                <i className="icon-mask" style={{ WebkitMaskImage:"url("+ShoppingCart+")"}} alt="Purchase Icon"></i>
                                Purchase Video Greeting
                              </Link>
                            </div>
                          )
                        )
                      }

                      </div>

                    </div>


                    <div className="col-12">
                      <hr/>
                    </div>
                  </div>
                );
              })}

            {this.state.fizzlist && this.state.fizzlist.length <= 0 && (
              <div>
                <br />
                  <br />
                  <h6 class="subheadline mb-3">Once you begin creating a Video Greeting, it will display here.</h6>

              </div>
            )}
          </div>



          <StandardModal
            size='lg'
            show={this.state.popupVideoUrl}
            onHide={this.popupVideoClose}
            closeBtnText="CLOSE"
            >
              <Plyr type="video" url={this.state.popupVideoUrl} autoplay />
          </StandardModal>
          
          <SharePopup
            sendToast={this.props.sendToast}
            show={this.state.sharePopup}
            onHide={this.closePopup}
            info={this.state.sharePopup}
            sharetype={this.state.sharetype}
          />


          <StandardModal
            show={!!this.state.deleteFizz}
            onHide={this.closeDeleteModal}
            header="Are you sure?"
            confirmBtnText="DELETE THIS VIDEO GREETING CARD"
            onConfirm={this.deleteFizzFunc.bind(this, this.state.deleteFizz)} >
            Are you sure, you want to delete this Video Greeting?
          </StandardModal>

          
        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }
}
