import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import { apiFetch } from '../helpers/APIHelper';
import Spinner from '../components/Spinner';
import Footer from "../components/Footer";
import Notify from '../components/Notify';
import { toastManager } from '../components/Toaster';
import TrackingHelper from '../helpers/TrackingHelper';
import MainContent from '../components/MainContent';
import { getQRKey } from "../helpers/URLHelper";
import NavLogo from "../components/NavLogo";
export default class ChangePassword extends Component{
  constructor(props, context) {
    super(props, context);

    this.state = {
      isSending: false,
      password: "",
      error: null,
      confirmation: null
    };
  }


  componentDidMount() {
    document.title = 'Change Password | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    TrackingHelper.trackPage();
  }


  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      isSending: true,
      error: null
    });

    var otp = null;
    if( this.props.match && this.props.match.params) {
      otp = this.props.match.params.otp;
    }

    let url = otp? '/register-password':'/rest/user/settings';
    console.log(url)

    apiFetch( url, {
      method: "PUT",
      data: {
        user:{
          phone: null,
          password: this.state.password,
        },
        password:this.state.password,
        otp: otp
      }
    },
    (json) => {
      this.setState({
        isSending: false,
        // confirmation: "Your password has been updated."
      });

      toastManager.showToast({
        message: 'Your password has been updated.' 
      })
      this.goBack();
    },
    (error) => {
      this.setState({
        isSending: false,
        error: error.message
      });
    });
  }

  handleChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render(){
    var allowSubmit = this.state.password.length > 5 && this.state.password.length < 71 && !this.state.isSending;

    return(
      <div className="screen-wrap">
        { getQRKey() ? 
          <NavLogo />
          :
          <NavBar />
        }
        <MainContent className="container">

          <div className="row" >
            <div className="col-lg-6" >
              <h1 className="headline-text mb-4">Change Password</h1>
              
              { this.state.confirmation && 
                <Notify message={this.state.confirmation} onHide={this.hideConfirm} />
              }
              {!this.state.confirmation &&
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <span className="has-float-label">
                      <label htmlFor="email">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        value={this.state.password}
                        onChange={this.handleChange}
                        id="email"
                        required={true}
                        placeholder="Enter New Password"
                        maxLength={70}
                      />
                      <p className="subheadline pl-4 mt-2">6 character minimum</p>
                    </span>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-block btn-lg-inline-block btn-primary mt-2"
                    disabled={!allowSubmit}
                  >
                    Update Password
                  </button>

                  { this.state.isSending &&
                    <Spinner float={true} />
                  }
                  
                  {this.state.error && (
                    <p className="alert alert-danger mt-2">
                      {this.state.error}
                    </p>
                  )}
                </form>
              }
            </div>
          </div>

        </MainContent>
        <Footer hideMobile={true} />
      </div>
    )
  }

}