import React, { useEffect, useMemo, useRef, useState } from "react";
import { apiFetch, isLoggedIn } from "../helpers/APIHelper";
import Spinner from "../components/Spinner";
import BackLink from "../components/BackLink";
import NavLogo from "../components/NavLogo";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import { Button } from "react-bootstrap";
import Plyr from "react-plyr";
import {toastManager} from "../components/Toaster";
import { getQRKey } from "../helpers/URLHelper";
import Footer from "../components/Footer";
import StandardModal from "../modal/StandardModal";

const QRBoxLink = (props) => {

  const [fizzId, setFizzId ] = useState( props.match.params.fizzId );
  const [fizz, setFizz] = useState({img: "/img/hmk/placeholder.jpg"});
  const [canCheckFizz, setCanCheckFizz] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState(false);
  const [showFinishModal, setShowFinishModal] = useState(false);

  let checkFizzTimeout;

  useEffect(() => {
    _getData();


    // Clean up before unmounting
    return () => {
      clearTimeout(checkFizzTimeout);
      setCanCheckFizz(false);
    }
  }, []);

  useEffect(()=>{

    if( !isDone && !loading ) {
      checkProcessingFizzes()
    }

  }, [isDone, fizzId, loading]);

  const _getData = () => {
    
    apiFetch(`/rest/qr/${getQRKey()}/box-set-preview/${props.match.params.fizzId}`, {
      method: 'GET',
    },
    json => {
      setFizzId( json.newFizzId ? json.newFizzId : fizzId );
      setFizz(json);
      setIsDone( json.isDone );
      setLoading(false);
      setError(false);

    },
    error => {

      console.log("Preview Error", error);

      setLoading( false );
      setIsDone( false );

      var errorMessage = (error && error.message)? error.message : (error+'');
      setError( errorMessage || "Could not load. Please refresh the page or scan the card again.");
    })
  }

  const checkProcessingFizzes = () => {
    apiFetch(
      `/rest/fizz/process/${fizzId}?cache=${Math.round(Math.random() * 100000000)}`,
      {
        method: "GET",
      },
      (json) => {
        if(json.video && json.isDone ) {
          setFizz(prevFizz => ({
            ...prevFizz,
            video: json.video
          }));

        }
        setIsDone( json.isDone );
        
        if (!json.isDone && canCheckFizz) {
          checkFizzTimeout = setTimeout(() => {
            checkProcessingFizzes();
          }, 3000);
        } else {
          TrackingHelper.trackPreview();
          clearTimeout(checkFizzTimeout);
        }
      },
      (error) => {
        if (canCheckFizz) {
          checkFizzTimeout = setTimeout(() => {
            checkProcessingFizzes();
          }, 3000);
        }
      }
    );
  }

  const activateCard = () => {
    apiFetch(
      `/rest/qr/box-set-link/${fizzId}?qrKey=${getQRKey()}`,
      {method: 'POST', data: {qrKey: getQRKey()}},
      
      (json) => {
        console.log(json);
        if (json.isLocked) {
          if( isLoggedIn() ){
            window.location = `/thank-you-qr-box/${fizzId}`;
          } else {
            window.location = `/signup-thank-you/${fizzId}`;
          }
        } else {
          window.location = `/edit/${fizzId}`;
        }
      }
    )
  }

  const renderContent = () => {
    if( loading ){
      return <Spinner float={true} />
    }

    if( error ) {
      return <div className="alert alert-danger" >{error}</div>
    }

    return (
      <>

        <h3 className="headline-text text-md-center">Let's give it one last check</h3>
        <div className="row" >
          <div className="col-12 col-lg-8 col-xl-8" >
            <p className="my-3">Watch your Video Greeting one last time to make sure it's perfect. When you're ready, click "It's finished." We'll link it to your boxed set of cards.</p>
          </div>

          <div className="col-12 col-lg-4 col-xl-4 text-right d-none d-lg-block mt-3" >
            <Button variant="btn btn-primary btn-block btn-lg-inline-block m-lg-2" disabled={!isDone} onClick={() => setShowFinishModal( true ) }>It's finished</Button>
          </div>
        </div>



        <div className="justify-content-center">
          <div className="position-relative">
            { !isDone &&
                <div className="preview-processing-wrap" >
                  <div className="preview-processing">
                    <div className="preview-processing-text">Your Video Greeting is being prepared and will display here shortly.</div>
                    <div className="text-center">
                      <Spinner/>
                    </div>
                  </div>
                </div>
            }

            { isDone && fizz.video ?
              <Plyr
                type="video" // or "vimeo"
                poster={fizz.img}
                url={fizz.video}
                autoplay
              />
              :
              <img
                className="video-fit w-100"
                src={fizz.img}
                alt="Your Video Will Play Here"
              ></img>
            }
          </div>
        </div>

        <div className="mt-3 text-center w-100 d-block d-lg-none">
          <Button variant="btn btn-primary btn-block" disabled={!isDone} onClick={() => setShowFinishModal( true ) }>It's finished</Button>
        </div>

        <StandardModal
          header="Are you sure?"
          closeBtnText="Cancel"
          onHide={()=>setShowFinishModal(false)}
          show={showFinishModal}
          >
          <h5 className="headline-text mt-2 mb-5">We'll link this video to your boxed set of cards.</h5>
          <button className="btn btn-primary btn-block" onClick={activateCard} >
            YES, LINK THEM  
            <p className="mt- mb-0" style={{textTransform:'none'}}><small>Once linked, this boxed set cannot be unlinked.</small></p>
          </button>
        </StandardModal>

      </>
    );
  }

  return (
    <div className="screen-wrap" >
      <NavLogo />
      <MainContent className="container bg-margin d-flex flex-column">
        <BackLink title="Back" />
        {renderContent()}
      </MainContent>
      <Footer hideMobile={true} />
    </div>
  )
}

export default QRBoxLink;