
import MemCache from "../helpers/MemCache";



export default function IdObjectMemCache( key ) {

	return {
		// MUST INCLUDE AN ID
		setItem: ( obj )=>{

			var currentObj = MemCache.getItem( key);

			var newObj = {};
			if( currentObj && currentObj.id == obj.id ) {
				newObj = {
					...currentObj,
					
					...obj
				}
			} else {
				newObj = obj;
			}

			MemCache.setItem( key, newObj );
			return newObj;
		},

		getItem: ( id )=>{

			var currentObj = MemCache.getItem( key);
			if( currentObj && currentObj.id == id ) {
				return currentObj;
			} 
			return {id:id};
		}
	}
}

