import React, { useState, useEffect, useRef } from 'react';
import Plyr from 'react-plyr';
import VideoStatsBar from './VideoStatsBar';

const MultiVideoPlayer = (props) => {

  const [videos, setVideos] = useState([]);
  const [activeVideo, setActiveVideo] = useState({});

  const plyrRef = useRef();

  useEffect(() => {
    setVideos(props.videos);
  }, [props.videos]);

  useEffect(() => {
    setActiveVideo(videos.shift());
  }, [!!videos.length]);

  const playNext = (e) => {
    let currentList = videos;
    currentList.push(activeVideo);
    setActiveVideo(currentList.shift());
    setVideos(currentList);
    plyrRef.current.play();
  }

  const playSelected = async (e, selectedIndex) => {
    await plyrRef.current.stop();
    let currentList = videos;
    const spliced = currentList.splice(selectedIndex);
    currentList.unshift(activeVideo);
    setActiveVideo(spliced.shift());
    setVideos([...spliced, ...currentList]);
    // await plyrRef.current.restart();
    await plyrRef.current.play();
  }

  return (
    <div className="row playlist-wrapper my-3">
            <div className="active-video col-md-9">
              <Plyr
                ref={plyrRef}
                type="video"
                url={activeVideo && activeVideo.video}
                onEnd={playNext}
                // poster={activeVideo.img}
                autoplay
              />

            { props.showStats && <VideoStatsBar shareUrl={ props.shareUrl} /> }
            </div>
            <div className="queue col-md-3 py-1 py-md-0 d-flex flex-md-column align-items-between justify-content-md-between" >
              { videos.map((video, index) => {
                return index < 3 && 
                  <div 
                    className="queue-item-wrapper" 
                    onClick={(e) => playSelected(e, index)}
                    key={`playlist-item-${index}`}
                  >
                    <Plyr
                      type="video"
                      url={video.video}
                      enabled={false}
                      poster={video.img}
                      autoplay
                    />
                  </div>
              })}
            </div>
          </div>
  )
}

export default MultiVideoPlayer;