import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./assets/css/style.css";
// import "./assets/css/bootstrap-v4.3.1.min.css";
import "./assets/fonts/beam-new-hmk.css";
import "./assets/css/loader.css";
import "./assets/css/uploader.css";
import "./assets/css/header-footer.css";
import "./assets/css/custom.css";
import "./assets/css/responsive.css";
import "./assets/css/notify.css";

import App from "./App";

if( window && window.location && window.location.host=='app.hallmarkvideogreetingcards.com' ) {
  window.location.href = "https://www.hallmarkvideogreetingcards.com"+window.location.pathname + window.location.search + window.location.hash;
}
 
var hostDomainProd = 'www.hallmarkvideogreetingcards.com';
var hostDomainDev = 'api-dev.hallmarkvideogreetingcards.com';
if( window && window.location ) {
  if( window.location.host == hostDomainProd ){
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
  }
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
