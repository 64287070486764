import React from 'react';
import CaretLeftIcon from "../assets/images/HMK-Icons/Icons_Arrows_Caret_Left_Bold.svg";
import { Link } from 'react-router-dom';

export default (props) => {

  const goBack = () => {
    window.history.back();
  };

	if( props.to ){
    return (
      <p className="back_btn mt-3">
        <Link to={props.to}>
          <span className="back_icon">
            <i className="icon-mask" style={{ WebkitMaskImage:"url("+CaretLeftIcon+")", height:"14px", width:"14px", marginBottom:"-2px", marginLeft:"2px"  }} alt="BackIcon"></i>                      
          </span>
          {props.title}
        </Link>
      </p>
    );
  } else {
    return(
      <p className="back_btn mt-3">
        <button className="link" onClick={goBack}>
          <span className="back_icon">
            <i className="icon-mask" style={{ WebkitMaskImage:"url("+CaretLeftIcon+")", height:"14px", width:"14px", marginBottom:"-2px", marginLeft:"2px"  }} alt="BackIcon"></i>                      
          </span>
            Back
        </button>
      </p>
    );
  }
}