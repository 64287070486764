import React from 'react';

export default (props) => {

    return (

        <>
          <div className="text-left">
            <button
              onClick={props.moveToNext}
              className="btn btn-block btn-lg-inline-block btn-outline-primary next-btn"
              >
              { props.textOverride ? props.textOverride : "NEXT"}
            </button>
          </div>
      </>
    );
    
}