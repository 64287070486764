import React, { Component } from "react";
import { apiFetch } from "../helpers/APIHelper";
import MemCache from "../helpers/MemCache";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MailIcon from "../assets/images/HMK-Icons/Icons_Mail_Regular.svg";
import SMSIcon from "../assets/images/HMK-Icons/Icons_Share_Share_iOS_Bold.svg";
import ShareIcon from "../assets/images/HMK-Icons/Icons_External_Link_Bold.svg";
import DownloadIcon from "../assets/images/HMK-Icons/Icons_Download_File_Regular.svg";
import { getQRKey } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import { getMailToLink, getSMSLink, isMobile, canShare } from "../helpers/URLHelper";
import { toastManager } from "../components/Toaster";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";

export class ThankYou extends Component {
    constructor(props) {
        super(props);

        this.state = {
          price: 0,
          hasPaid: false,
          name: "",
          theme_name: "",
          shareUrl: "",
          fizzId: props.match.params.fizzId,
          isLoading: true,
          errorMessage: '',
          img:null,
          redirectLabel: 'Browse Video Greetings',
          error:null,
        };
      }

      componentDidMount() {
        this._getData();
      }

      _getData() {

        var fizz = MemCache.getItem('fizz');
            console.log( "cached fizz",fizz );
        if( fizz && fizz.id == this.state.fizzId ){    
          console.log( { fizzID: fizz.id, img: fizz.img })
          this.setState({
            img:fizz.img 
          });
        }

        apiFetch(
          "/rest/fizz/" + this.state.fizzId  + "/detail",
          {
            method: "GET",
          },
          (json) => {
            console.log(" JSON STRINGIFY RESPONSE" + json);

            this.setState({
              name: json.item.name,
              hasPaid: json.item.hasPaid,
              shareUrl: json.item.shareUrl,
              downloadUrl: json.item.downloadUrl,
              isLoading: false,
              theme_name: json.item.name,
              price: json.item.price,
              img: json.item.img,
              isPhysical: json.item.isPhysical,
              sku: json.item.sku
            });

            //Tracking Info
            window.adobeDataLayer = { 
              occasion: json.item.themeName,
              type: json.item.isPhysical ? 'Physical' : 'Digital',
              sku: json.item.sku,
              price: ( json.item.isFree || json.item.isPhysical ) ? 0 : json.item.price,
              fizzUUID: json.item.uuid
            };
            TrackingHelper.trackPage();
            document.title = 'Thank you | Video Greeting Cards | Hallmark';
            document.getElementsByTagName( 'meta' )[3].content = "";
            
          },
          (error) => {
            console.log(error)
            this.setState({
              isLoading: false,
              error: error.message + ""
            })
          }
        );
      };

    gotoBrowsing = () => {
      window.location = "/browse";
    }

    copyToClipboard=()=>{
      navigator.clipboard.writeText(this.state.shareUrl);
      toastManager.showToast({
        message: 'Copied to Clipboard!'
      });

    }
    shareAny=()=>{
      navigator.share({
        url: this.state.shareUrl
      }).then(() => {
        // alert("Sent");
      }).catch((e)=>{
        // Canceled or something else
        // alert("Error"+e);
      })
    }

  
    render(){
      var hasClipboard = navigator && navigator.clipboard && navigator.clipboard.writeText;
      let smsLink = getSMSLink({body:this.state.shareUrl});
      let emailLink = getMailToLink({body:this.state.shareUrl});
      
      if( this.state.isLoading ){
        return(
          <div className="screen-wrap">
            <NavBar/>
            <MainContent className="container text-center pt-5">
              <Spinner/>
            </MainContent>
            <Footer hideMobile={true} />
          </div>
        )
      }

      return(
        <div className="screen-wrap" >
          <NavBar />
          <MainContent className="container bg-margin" >

          {this.state.error ? (
                <p className="alert alert-danger">{this.state.error}</p>
             ) : (
            <>

     
          { this.state.isPhysical ? (

            <div className="row mt-2">
              <div className="col-md-12 text-left">  
                <h1 className="headline-text">Thank you! Now for the best part…</h1>
                <p className="subheadline mt-3">
                  Your Video Greeting is complete. Now all you need to do is mail or give the card to your special someone. Once they scan the code inside the card, they’ll see your Video Greeting, and feel all the love you put into it.
                </p>
              </div>
            </div>

          ):( null )}


          <div className="row mt-2">
            <div className="col-md-6 text-left mb-4">  
              { this.state.isPhysical ? (
                <>
                  <h5 className="headline-text">Send a sneak preview</h5>
                  <p className="subheadline mt-3">We know you don't want to spoil the surprise before they receive their card. But in the meantime, you can share your Video Greeting with friends and family.</p>
                </>
              ):(
                <> 
                  <h5 className="headline-text">{ this.state.hasPaid ? "Thank you for your purchase" : "Woo hoo!"}</h5>
                  <h1 className="headline-text">Now let’s send your Video&nbsp;Greeting!</h1>
                  <p className="subheadline mt-3">Choose a delivery method below:</p>
                </>
              )}
              { this.state.shareUrl &&
                <>
                  { canShare() ?
                    
                    <button className="btn btn-link blue-link btn-padded" onClick={this.shareAny}>
                    <i className="icon-mask" style={{ WebkitMaskImage:"url("+ShareIcon+")"}} alt="Share Icon"></i>
                    Share this video
                    </button>
                  :
                    <>
                      <a href={emailLink} className="blue-link btn-padded">
                      <i className="icon-mask" style={{ WebkitMaskImage:"url("+MailIcon+")" }} alt="MailIcon"></i>
                      Email
                      </a>
                      
                      { smsLink && 
                        <div>
                        <a className="blue-link d-block d-md-none btn-padded pb-0" href={smsLink}>
                        <i className="icon-mask" style={{ WebkitMaskImage:"url("+SMSIcon+")"}} alt="Text Icon"></i>
                        Text message
                        </a>
                        </div>
                      }
                      
                      { hasClipboard &&
                        <div>
                        <button className="link blue-link btn-padded pt-2" onClick={this.copyToClipboard} >
                        <i className="icon-mask" style={{ WebkitMaskImage:"url("+ShareIcon+")"}} alt="Copy Icon"></i>
                        Copy link to paste anywhere
                        </button>
                        </div>
                      }
                    </>
                  }
                
                  <div>
                    <a className="blue-link btn-padded tag-download-user" href={this.state.downloadUrl}>
                      <i className="icon-mask" style={{ WebkitMaskImage:"url("+DownloadIcon+")"}} alt="Download Icon"></i>
                      Download and save
                    </a>
                  </div>

                </>
              }
              


            </div>
  
            <div className="col-md-1 d-none d-sm-block"></div>
  
            <div className="col-md-5  pt-4">
                  <img
                    style={{ width: "100%" }}
                    src={this.state.img}
                    alt="img"
                  />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 text-left mb-4">   

              { this.state.hasPaid && !this.state.isPhysical && 
                <>
                  <h5 className="headline-text">Order summary</h5>
                        
                  <div className="row mt-3">
                    <div className="col-9 text-left">
                      <p className="pt-2">{this.state.theme_name}</p>
                    </div>
                    <div className="col-3 text-right">
                      <p className="pt-2">{ this.state.price }</p>
                    </div>
                  </div>
                    
                  <hr className="mt-0"/>

                  <div className="row">
                    <div className="col-9 text-left">
                      <p>Total</p>
                    </div>
                    <div className="col-3 text-right">
                      <p>{ this.state.price }</p>
                    </div>
                  </div>
                </>
              }
            

            </div>
          </div>


          </>
          )}

          </MainContent>

          <Footer hideMobile={true} />
        </div>
      )
    }
}


export default ThankYou;
