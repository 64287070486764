import React from 'react';
import DonateLogo from "../assets/images/Susan_G_Komen-logo.svg";

const DonateBanner = (props) => {
  return (
    <div className="donate-banner mt-4">
      <div className="donate-logo-wrapper">
        <img src={DonateLogo} className="mt-n2 donate-logo" />
      </div>
      <div className="donate-caption">
        <strong>Join our Fight.<br/>Save Lives.</strong>
      </div>
      <div className="donate-button-wrapper">
        <a href="https://secure.info-komen.org/site/Donation2?df_id=23821&mfc_pref=T&23821.donation=form1&creative=fy22_evergreen_moments&s_src=komen.org&s_subsrc=main_nav_donate&_gl=1*1l3thv3*_ga*OTUwNDY3NDM0LjE2NjMxMDI0OTc.*_ga_HGS8BJYTKQ*MTY2MzEwMjQ5Ny4xLjAuMTY2MzEwMjQ5Ny42MC4wLjA.*_fplc*QWFpSjJMYng1TSUyRmlMQ0p3JTJCZUlhOElqMGVDNnVlUmlENlRYVm9McUlCbFBNbTVvUjJjbGtpQWJMMmNiaHhpJTJCZU1maUc1eCUyRkRDTVlJbTklMkZlWXlKZzR1bG8zZFowZEt3aVZtMlAlMkZZS0NRcmtKd2lzMFNReU96MzJZVjdIbmtnJTNEJTNE&_ga=2.243798533.1016320399.1663102499-950467434.1663102497" target="_blank" className="btn btn-donate">Donate Today</a>
      </div>
    </div>
  )
}

export default DonateBanner;