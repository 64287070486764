import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { apiFetch } from "../helpers/APIHelper";
import { getQRKey, getBaseURL } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";

import NavLogo from "../components/NavLogo";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import { Button } from "react-bootstrap";
import BackLink from "../components/BackLink";
import {toastManager} from "../components/Toaster";
import moment from "moment";
import StandardModal from "../modal/StandardModal";


const QRBoxList = (props) => {

  const [boxSetList, setBoxSetList] = useState([]);
  const [qrKey, setQrKey] = useState(null);
  const [fizzId, setFizzId] = useState(null);
  const [showNotFinishedModal, setShowNotFinishedModal ] = useState(false);

  useEffect(() => {
    _getData();
  }, []);

  const _getData = () => {
    console.log(props);
    setQrKey(props.match.params.qrKey);
    apiFetch(`/rest/qr/box-set/list?qrKey=${qrKey}`, {
      method: 'GET'
    }, json => {
      console.log(json);
      setBoxSetList(json.items);
    })
  }

  const goToLink = (id) => {

    window.location = `/qr-setup/${qrKey}/${id}/link`;
  }

  return (
    <div className="screen-wrap" >
      <NavLogo />
      <MainContent className="container bg-margin">

        <BackLink title="Back" />
        <h3 className="headline-text">Choose a previous project to continue</h3>
        <div className="d-flex flex-column my-4">
        {boxSetList.map((set) => {
          return (
            <div 
              key={set.id} 
              className={"d-flex justify-content-between align-items-center py-1 my-2 border-top border-bottom" + (set.isLocked ? "" : " disabled-link")}
              onClick = { set.isLocked ? () => goToLink(set.id) : () => setShowNotFinishedModal( true ) }
            >
              <div className="flex-grow-1">
                <span className="list-name">{set.name}</span>
                <p>
                  <span className="list-date" >Started: {moment(set.fizzCreated.date+"+0000").format('ll')}</span>
                  { !set.isLocked &&
                    <span className="disabled-link-text pull-right">Not Finished</span>
                  }
                </p>
              </div>

              <img src={set.image} className="w-25" />

              <Link to={`/qr-setup/${qrKey}/${set.id}/link`} className="list-arrow">
                <i className="fa fa-chevron-right px-3 px-lg-5" aria-hidden="true"></i>
              </Link>
            </div>
          )
        })}
        </div>


        <StandardModal
            show={showNotFinishedModal}
            onHide={()=>setShowNotFinishedModal(false)}
            header="This Boxed Set is not finished."
            confirmBtnText="GOT IT"
            closeBtnText={false}
            onConfirm={()=>setShowNotFinishedModal(false)} >
             In order to link to another box, the video must be complete. Scan a card in the boxed set to finish it.
          </StandardModal>
      </MainContent>
    </div>
  )
}

export default QRBoxList;