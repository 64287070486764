import React, { Component } from "react";
import Plyr from "react-plyr";
import "../assets/css/LandingPage.css";
import "react-multi-carousel/lib/styles.css";
import StandardModal from "../modal/StandardModal";
import Spinner from "../components/Spinner";
import { apiFetch } from "../helpers/APIHelper";

export class LandingOfferBanner extends Component {
  constructor(props) {
    super(props);
    console.log( props );
    this.state = {
      videoPlay: null,
      isOver: props.data.isOver,
      bgColorVideo: props.data.bgColorVideo,
      howToVideo: props.data.howToVideo,
      sideVideo: props.data.sideVideo,
      body: props.data.body,
      headline: props.data.headline,
      btnText: props.data.btnText,
      limitText: props.data.limitText,
      expiredHeadline: props.data.expiredHeadline,
      expiredBody: props.data.expiredBody,
    };
  }

	onGetStarted = () => {
    // window.location = '/offer-register'; // no longer force register/login
    window.location = '/browse' ; // offer isOver
  }


  onWatchHowTo = () => {
    this.setState({
      videoPlay: true,
    });
  }

  closeVideoPlay = ()=>{
    this.setState({
      videoPlay: null,
    });
  }

	render(){

    if( this.state.isOver ) {
      return (
        <section className="hero p-0">
          <div className="container-fluid p-0 container-lg">
            <div className="row no-gutters">
              <div className="col-md-12 pb-5 pt-5">
                <h2 className="headline  pt-4 pb-0 text-center">{this.state.expiredHeadline}</h2>
                <div className="hero__text hero__text__digital p-3">
                  <p className="hero__description mt-2">{this.state.expiredBody}</p>
                  <button onClick={this.onGetStarted} className="btn btn-primary">{this.state.btnText}</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }

       return(
         <section className="hero p-0">
           <h2 className="headline custom-headline pt-4 pb-0 text-center" >{this.state.headline}</h2>
           <video className="hero-bg-video disable-gtm" 
                   loop={true}
                   muted={true}
                   autoPlay
                   playsInline ><source src={this.state.bgColorVideo} type="video/mp4" /></video>
           <div className="container-fluid p-0 container-lg" >
             <div className="row no-gutters">
               <div className="col-md-8">
                 <div className={"hero__text hero__text__digital"} >
                   <p className="hero__description mt-2">{this.state.body}</p>

                    <button className="btn btn-primary" onClick={this.onGetStarted} >{this.state.btnText}</button>
                   <p className="mt-4 pb-4 custom-limit-text">{this.state.limitText}</p>
                 </div>
               </div>

               <div className="col-md-4 d-md-block hero__image p-0">
                 <video className="disable-gtm p-0 custom-landing-video"
                   loop
                   muted
                   autoPlay
                   playsInline
                   width="100%"
                   // onLoad={(e)=>{e.currentTarget.play()}}
                 ><source src={this.state.sideVideo} type="video/mp4" /></video>
               </div>
             </div>
           </div>


           <StandardModal
             size='lg'
             show={this.state.videoPlay}
             onHide={this.closeVideoPlay}
             closeBtnText={false} >
               <Plyr
                 type="video" // or "vimeo"
                 url={this.state.howToVideo}
                 autoplay
                 options={{playsinline:true}}
               />
           </StandardModal>
         </section>
       );
  }

}
  
export default LandingOfferBanner;
