export default class FizzStatus {
  constructor(status) {
    this.status = status;
  }

  isDone() {
    return this.isFizzCompleted() || this.isFizzInError();
  }
  isProcessing() {
    return this.isFizzInQueue() || this.isFizzInProcess();
  }
  needsToBeCombined() {
    return !this.isDone() && !this.isProcessing();
  }

  isProducer() {
    return (this.status & 1) === 1;
  }
  isVideoSubmitted() {
    return (this.status & 2) !== 0;
  }
  isFizzCompleted() {
    return (this.status & 4) !== 0;
  }
  isFizzInQueue() {
    return (this.status & 8) !== 0;
  }
  isFizzInProcess() {
    return (this.status & 16) !== 0;
  }
  isFizzInError() {
    return (this.status & 32) !== 0;
  }
  isFizzPublished() {
    return (this.status & 64) !== 0;
  }
}
