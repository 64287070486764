import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import LoadingBar from "../components/LoadingBar";
import { Modal } from "react-bootstrap";
import QuickSignup from "../components/QuickSignup";
import menuclose from "../assets/images/HMK-Icons/Icons_Menu_Close_Bold.svg";
import StandardModal from "../modal/StandardModal";
import Webcam from "react-webcam";
import ProgressUploader from "../helpers/ProgressUploader";
import stoprecord from "../assets/images/stop-recording@2x.png";
import startrecord from "../assets/images/recordTapToStart@2x.png";
import Plyr from "react-plyr";
import "../assets/css/record-fizz.css";
import { toastManager } from "../components/Toaster";
import BackLink from "../components/BackLink";
import Notify from '../components/Notify';
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import { getInviteFizzUUID } from "../helpers/URLHelper";
import { generateDeviceId } from "../helpers/APIHelper";
import { needsShowQuickSignUp } from "../helpers/APIHelper";



export class PreviewFizz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //fizzId: set in loadProps
      isRecording: false,
      hasRecording: false,
      showFinalClip: false,
      uiError:null,
      isInvite: false,
      isThankyou: false,
      taId: null,
      isUploading: false,
      uuId: '',
      trackMicLevels: false,
      lowVolume: false,
      maxVolumeLevel: 0,
      uploadError: false,
      uploadFizzId: null
    };

    this.webcamRef = React.createRef();
    this.recordChunks = [];
    this.micLevels = [];
    this.loadProps(props);
  }

  hideConfirm = ()=>{
    this.setState({
      uiError:null
    })
  }

  componentDidMount() {

    document.title = 'Record | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    TrackingHelper.trackPage();
  }

  componentWillUnmount(){
    if( this.clockInterval ) {
      clearInterval(this.clockInterval);
    }
    if ( this.uploadTimer ){
      clearTimeout(this.uploadTimer);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.loadProps(nextProps)) {
    }
  }

  loadProps(props) {
    this.state.isInvite = props.isInvite;
    this.state.isThankyou = props.isThankyou;
    
    
    var oldFizzId = this.state.fizzId;
    var oldTA = this.state.taId;
    this.state.taId = this.props.match.params.themeAnimationId;
    this.state.fizzId = props.match.params.fizzId;
    
    return oldTA !== this.state.taId || oldFizzId !== this.state.fizzId;
  }

  handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      console.log("Add Data", data);
      this.recordChunks = this.recordChunks.concat(data);
    }
  };

  start = () => {
    this.recordChunks = [];

    this.setState(() => ({trackMicLevels: true}));
    this.visualize(this.webcamRef.current.stream);
    
    if (this.mediaRecorderRef) {
      this.mediaRecorderRef.removeEventListener(
        "dataavailable",
        this.handleDataAvailable
      );
    }

    var typePreference = [{
      mime:"video/webm",
      ext:"webm",
      uploadExt:'webm',
    },{ 
      mime:"video/mp4",
      ext:"mp4",
      uploadExt:'h264-mp4',
    },{ 
      mime:"video/mpeg",
      ext:"mpeg",
      uploadExt:'mpeg',
    }];

    var workingType = null;
    for (var i in typePreference) {
      if( MediaRecorder.isTypeSupported(typePreference[i].mime) ) {
        workingType = typePreference[i];
        break;
      }
    }
    this.workingType = workingType;


    this.mediaRecorderRef = new MediaRecorder(this.webcamRef.current.stream, {
      mimeType: workingType.mime,
    });
    this.mediaRecorderRef.addEventListener(
      "dataavailable",
      this.handleDataAvailable
    );
    this.mediaRecorderRef.start();

    this.setState({ isRecording: true });


    this.startTime = (new Date()).getTime();
    this.clockInterval = setInterval(()=>{

      let newTime = (new Date()).getTime();
      this.setState({
        currentTime: ( newTime-this.startTime )/1000
      })
    },500);
  };
  stop = () => {
    let maxVolumeLevel = Math.max(...this.micLevels);
    
    this.setState({
      trackMicLevels: false,
      maxVolumeLevel: maxVolumeLevel
    });
  
    console.log("final mic levels:", this.micLevels);
    console.log("Maximum volume detected:", maxVolumeLevel);

    if ( false && maxVolumeLevel < -110) {
      this.setState(() => ({lowVolume: true}))
    } else {
      this.setState(() => ({showFinalClip: true}));
    }
    clearInterval(this.clockInterval);
    let newTime = (new Date()).getTime();
    this.setState({
      currentTime: ( newTime-this.startTime )/1000
    })

    console.log(this.mediaRecorderRef);
    this.mediaRecorderRef.stop();
    this.setState({
      isRecording: false,
      hasRecording: true,
    });
  };
  restart = () => {
    this.recordChunks = [];
    this.setState({
      isRecording: false,
      hasRecording: false,
      showFinalClip: false,
      previewVideoUrl: null,
      uploader: null,
      currentTime: 0,
      streamError: '',
      lowVolume: false,
    });
  };
  download = () => {
    if (this.recordChunks.length) {
      const blob = new Blob(this.recordChunks, {
        type: this.workingType.mime,
      });

      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture."+this.workingType.ext;
      a.click();
      var video = document.getElementById("video");
      video.src = window.URL.createObjectURL(url);
      window.URL.revokeObjectURL(url);
      this.recordChunks = [];
    }
  };



  upload = () => {

    if (this.recordChunks.length) {
      const blob = new Blob(this.recordChunks, {
        type: this.workingType.mime,
      });

      this.uploadTimer = setTimeout( () => toastManager.showToast({
        jumboTitle: 'Hang tight! Your upload is still in progress.',
        message: 'Remain on this page, and it will finish soon. We’re sorry for the delay—sometimes large uploads take longer to process.' ,
        keepOpen: true
      }), 5000 );

      let file = new File([blob], "browser-recording."+this.workingType.uploadExt);

      let linkAddOn = '';
      if ( this.state.isInvite ){
        linkAddOn= `&uuid=${getInviteFizzUUID()}`;
      }

      let apiUrl = `/rest/fizz/${this.state.fizzId}/add-clip?type=desktop-record${linkAddOn}&micVol=${this.state.maxVolumeLevel}`;

      if( this.state.isThankyou && !this.state.fizzId ){
        
        generateDeviceId(); //make sure device Id cookies are set.
        
        apiUrl = `/rest/fizz/thank-you-add-clip?type=desktop-record${linkAddOn}&micVol=${this.state.maxVolumeLevel}`;
      }

      this.setState({
        showQuickSignupModal: needsShowQuickSignUp() 
      });

      let uploader = new ProgressUploader(file, (uploader) => {
        // Progress
        console.log(uploader.getPercent());
        // Call set state to refresh
        this.setState({
          showFinalClip: false,
          uploader: uploader,
          uploadingMessage: " ",
          isUploading: true
        });
      });
      uploader
        .start(
          "POST",
          apiUrl
        )
        .then((uploader) => {
          if ( uploader.hasError() ) {
            console.log("Error: ", uploader.response );

            toastManager.showToast({
              title: 'Upload Failed',
              message: uploader.response.message || "Please try again or select a different file.",
              error: true
            });

            this.setState({
              uploadError: true
            });
            
            this.restart();
            return;
          }

          if ( this.uploadTimer ){
            clearTimeout(this.uploadTimer);
          }
          
          toastManager.showToast({
            message: 'Upload successful!' 
          });

          if( !this.state.showQuickSignupModal ){

            if( this.state.isInvite ) {
              window.location = "/view-invite-thank-you";
            } else if( this.state.isThankyou ) {
              window.location = "/preview-thank-you/"+uploader.response.fizzId;
            } else {
              window.location = "/edit/"+this.state.fizzId;
            }
          }
          
          // refresh the list
          this.setState({
            uploader: uploader,
            uploadingMessage: "Uploading Done",
            isUploading: false,
            uploadError: false,
            uploadFizzId: uploader.response.fizzId 
          });
          
        });
    }
  };

  closeQuickSignupModal = () => {

    if( localStorage && localStorage.setItem ) {
      localStorage.setItem("hasSeenQuickSignUp", true );
    }

    this.setState(prevState => ({
      ...prevState,
      signupRejected: true,
      showQuickSignupModal: false
    }));

    if( !this.state.isUploading && !this.state.uploadError ){

      if( this.state.isInvite ) {
        window.location = "/view-invite-thank-you";
      } else if( this.state.isThankyou ) {
        window.location = "/preview-thank-you/"+this.state.uploadFizzId;
      } else {
        window.location = "/edit/"+this.state.fizzId;
      }

    }
  }

  onSignupSuccess = () => {

    if( localStorage && localStorage.setItem ) {
      localStorage.setItem("hasSeenQuickSignUp", true );
    }

    this.setState(prevState => ({
      ...prevState,
      signupRejected: false,
      showQuickSignupModal: false
    }));

    if( !this.state.isUploading && !this.state.uploadError ){

      if( this.state.isInvite ) {
        window.location = "/view-invite-thank-you";
      } else if( this.state.isThankyou ) {
        window.location = "/preview-thank-you/"+this.state.uploadFizzId;
      } else {
        window.location = "/edit/"+this.state.fizzId;
      }
      
    }

  }



  preview = () => {
    //close low volume modal if open
    if( this.state.lowVolume ){
      this.setState({ lowVolume: false });
    }

    if (this.recordChunks.length) {

      const blob = new Blob(this.recordChunks, {
        type: this.workingType.mime,
      });

      const url = URL.createObjectURL(blob);
      this.setState({
        showFinalClip: false,
        previewVideoUrl:url
      });

    }
  };

  // closeCurrentVideo = () => {
  //   // alert("close working");
  //   this.setState({
  //     showFinalClip: false,
  //     isRecording: false,
  //     hasRecording: false,
  //   });
  // };

  onUploadError = () => {
    this.setState({ uploadError: null });
    this.restart();
  };

  showFinalClipModal = () => {
    this.setState({ showFinalClip: true });
  };

  // continueFinalClipmodal = () => {
  //   this.setState({ showFinalClip: false });
  //   window.location = "/checkout/" + this.state.fizzId;
  // };

  goBack = () => {
    this.props.history.goBack();
  };

   handleUserMedia  = async(stream) => {

    this.setState({ 
      uiError : null
    });
    // setTimeout("window.location.reload()",1000);
    console.log("Video stream received.");
  };

  visualize = (stream) => {
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 2048;
    source.connect(analyser);
    this.micLevels.length = 0; // Flush the array for a fresh start
    const micTracker = setInterval(() => {
      if (!this.state.trackMicLevels) {
        clearInterval(micTracker);
        return;
      }
      const dataArray = new Float32Array(analyser.frequencyBinCount)
      analyser.getFloatFrequencyData(dataArray)
      this.micLevels.push(dataArray.reduce((a, b) => a + b) / dataArray.length);
      console.log(this.micLevels);
      // => Values outside the default [-100, -30] range.
    }, 100);
  }

  handleUserMediaError = async (err) => {
    console.log( err);

        if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {
            //required track is missing 
            this.setState({ 
              uiError : "Camera/microphone not found. Please connect a webcam/microphone and refresh the page to record."
            });
        } else if (err.name == "NotReadableError" || err.name == "TrackStartError") {
            //webcam or mic are already in use 
            this.setState({ 
              uiError : "Camera/microphone already in use. Please close any other applications useing the webcam/microphone and refresh the page to record."
            });
        } else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError") {
            //permission denied in browser 
            this.setState({ 
              uiError : "Please grant access to your camera and microphone and refresh the page to record."  
            });
        } else {
            //other errors 
            this.setState({ 
              uiError : "No camera detected. Please check that your camera is not blocked or turned off. If you don't have a camera on this computer try with another device."
            });
        }
  }

  render() {

    var displayTime = "0:00";
    if( this.state.currentTime ) {
      let sec = Math.round(this.state.currentTime) % 60;
      let min = Math.floor(this.state.currentTime/60);
      displayTime = min+":"+(sec<10? "0":'' )+sec;
    }

    return (
      <div className="screen-wrap">
        <NavBar />
        <MainContent className="container bg-margin">
          { ( this.state.isInvite || this.state.isThankyou ) ?
            <BackLink
              to={ this.props.history.location }
              title="Back"
              disabled= {this.state.isUploading}
            />
            :
            <BackLink
              to={"/edit/"+this.state.fizzId }
              title="Back to Edit"
            />
          }
          <h1 className="headline-text">Record</h1>
          { this.state.uiError && 
          <div className="pt-2 pb-2">
                <Notify error={true} message={this.state.uiError} />
          </div>
          }
  
          { this.state.uploadError && 
          <div className="pt-2 pb-2">
                <Notify error={true} message={this.state.uploadError} onHide={this.onUploadError.bind(this )} />
          </div>
          }
        


            {this.state.uploader ?
              <div className="mb-2 mt-3">
                { this.state.uploader.status === "pending" && 
                  <div className="row">
                    <div className="col-4">
                      <p className="mb-0">Uploading...</p>
                    </div>
                    <div className="col-7" >
                      <LoadingBar percent={this.state.uploader.getPercent()} />
                    </div>
                  </div>
                }
              </div>
              :
              ( this.state.previewVideoUrl &&
                <div className="text-center mb-2">
                  {this.state.hasRecording && this.state.hasRecording && (
                    <button
                      className="btn mob_button_block btn-primary mr-3"
                      onClick={this.upload}
                    >
                      Upload Video
                    </button>
                  )}
                  {this.state.hasRecording && this.state.hasRecording && (
                    <button
                      className="btn mob_button_block btn-outline-primary"
                      onClick={this.restart}
                    >
                      Record Again
                    </button>
                  )}
                </div>
              )
            }


            <div className="text-center">
              <div className="video-view ">
                <div className="box-480" >
                  <div className="box-480-item" >

                    { this.state.previewVideoUrl && 
                      <div>{/*Extra wrapper need to avoid issues breaking when remmoved*/}
                        <Plyr
                          type="video" // or "vimeo"
                          url={this.state.previewVideoUrl}
                          autoplay
                        />
                      </div>
                    }
                    {!this.state.previewVideoUrl  &&
                      <div>
                        <Webcam
                          audio={true}
                          muted={true}
                          mirrored={true}
                          ref={this.webcamRef}
                          className="w-100"
                          videoConstraints={{
                            facingMode: 'user', 
                            aspectRatio: 854/480, 
                            width: { ideal: 854 },
                            height: { ideal: 480 },
                            frameRate: 29.97
                          }}
                          onUserMedia={(stream) => {this.handleUserMedia(stream)}}
                          // onUserMedia={e => console.log(e + "check1")}
                          onUserMediaError={(err) => {this.handleUserMediaError(err)}}
                        />
                        <div className="record-clock">{displayTime}</div>
                      </div>

                    }
                  </div>
                </div>

                <div className="video-content">
                  {!this.state.hasRecording && !this.state.isRecording && (
                    <button
                      type="button"
                      className="video_view_btn"
                      onClick={this.start}
                    >
                      <img src={startrecord} className="video_view_btnimg" />
                    </button>
                  )}
                  {!this.state.hasRecording && this.state.isRecording && (
                    <button
                      type="button"
                      className="video_view_btn"
                      // onClick={this.stop}
                      onClick={this.stop}
                    >
                      <img src={stoprecord} className="video_view_btnimg" />
                    </button>
                  )}
                </div>

              </div>

            <br />

          </div>


          <StandardModal header="Ready to upload?" 
            closeBtnText="Record Again" 
            onHide={this.restart}
            show={ this.state.showFinalClip }
            >
            <button className="btn btn-primary btn-block" onClick={this.preview} >Preview</button>
            <button className="btn btn-primary btn-block" onClick={this.upload} >Upload</button>
          </StandardModal>

          <StandardModal
            header="Low Volume!"
            closeBtnText="Record Again"
            onHide={this.restart}
            show={this.state.lowVolume}
            >
            <div className="mb-3">
                The sound in this recording was very quiet.
                If you were talking in the video your microphone may be off, on mute, or too far away.
            </div>
            <button className="btn btn-primary btn-block" onClick={this.preview} >Preview</button>
          </StandardModal>

          {/* QuickSign Modal */}
          <StandardModal
            show={this.state.showQuickSignupModal}
            onHide={this.closeQuickSignupModal}
            closeBtnText={false}
            size="lg"
          >
            <QuickSignup successCallback={this.onSignupSuccess} from="recordFizz"/>
          </StandardModal>


        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }
}

export default PreviewFizz;
