import React from "react";
import { Link } from "react-router-dom";
import MainContent from "../components/MainContent";
import NavLogo from "../components/NavLogo";
import { isLoggedIn } from "../helpers/APIHelper";

const QRScanMore = (props) => {
  console.log(props);
  return (
    <div className="screen-wrap">
      <NavLogo />
      <MainContent className="container">
        <h1 className="headline-text my-3 my-md-5">
          Alright! Your video and set of cards have been linked.
        </h1>

        <p className="subheadline mt-3 mb-4">
          Everyone who receives a card from this set will be able to watch your personalized Video Greeting.
        </p>


        <h5 className="headline-text mt-5">
          Want to link this video to another boxed set of cards?
        </h5>

        
        <p className="subheadline mt-3">You can link another boxed set now, or wait till later. To link another set now, <strong>open your camera and scan the code</strong> inside one of the cards.</p>
        <div className="qr-box-cta-wrapper mx-auto">

          <Link 
            className="btn btn-primary w-100"
            to={ isLoggedIn() ? `/thank-you-qr-box/${props.match.params.fizzId}` : `/signup-thank-you/${props.match.params.fizzId}` }
          >
            No I'm all Done
          </Link>
        </div>
      </MainContent>
    </div>
  )
}

export default QRScanMore;