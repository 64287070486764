import React, { Component } from "react";
import Constants from "../helpers/Constants";

export default class ShopTheCardsBanner extends Component {

	render(){

		var img = this.props.img || '/img/shop-the-cards-banner/bday-congrats.jpg';
		return(

			<div style={{backgroundColor:'#CBB4EE'}} >
				<section className="container text-center pb-4" >
					<div className="row no-gutters p-3">
						<div className="col-md-8">
							<div className="text-center" >
	            				<h5 className="landing_title mt-4">Want to make it extra special? Send it by mail.</h5>
	            				<p>There’s another way to send your Video Greeting: with a real Hallmark card!
	Shop the cards online or in store, and find out more.</p>
								<a className="btn btn-primary shop-cards-btn" href={Constants.HALLMARK_LANDING_FROM_PDP_AD} >SHOP THE CARDS</a>
							</div>
						</div>
						<div className="col-md-4 d-md-block hero__image">
							<img className="w-100" src={img} alt="Video Greeting Card" />
						</div>
					</div>
				</section>
			</div>
		)
	}
}