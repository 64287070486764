import React, { Component } from 'react';
import { Button,Modal } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import Footer from "../components/Footer";
import Plyr from 'react-plyr';
import { Link } from 'react-router-dom';
import { apiFetch } from '../helpers/APIHelper';
import Spinner from '../components/Spinner';
import MainContent from "../components/MainContent";
import TrackingHelper from '../helpers/TrackingHelper';
import { getQRKey } from "../helpers/URLHelper";
import NavLogo from "../components/NavLogo";


export default class Tutorials extends Component {
  
  constructor(props, context) {
    super(props, context);

    this.closeVideo = this.closeVideo.bind(this);
    this._getData = this._getData.bind(this);

    this.state = {
      tutorialUrl: null,
      videos: null,
      showPlayer: false
    };
  }

  componentDidMount() {
    this._getData();

    document.title = 'Video Greeting Cards Tutorial | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "Watch our video tutorials to see the easy steps to putting together a Hallmark Video Greeting Card. Send one today!";
  }

  _getData() {

    this.setState({
      loading: true
    });

    apiFetch('/rest/tutorial', {
      method: 'GET',
    }, (json) => {
      console.log(json);

      this.setState({
        videos: json.list,
        loading: false
      });
      TrackingHelper.trackPage();
    }, (error, res) => {
      this.setState({
        loading: false,
        error: "Error loading  videos. Please refresh the page to try again."
      })

      TrackingHelper.trackPage();
    });

    

  }

  closeVideo() {
    this.setState({ 
      tutorialUrl: null,
      showPlayer:false
     });
  }


  showVideo(type) {
    this.setState({
      tutorialUrl:type,
      showPlayer: true
    });
  } 


  render () {
    return (

    <div className="screen-wrap" >
      { getQRKey() ? 
        <NavLogo />
        :
        <NavBar />
      }
      <MainContent className="container">
        <div className="row mt-4">
          <div className="col-md-12 text-left "><h1 className="headline-text">Tutorials</h1></div>
          <div className="col-md-12 text-left"><p className="subheadline">Check out the tutorial video below to learn more about creating the perfect video greeting.</p></div>
          <div className="col-md-12 text-left"><p className="subheadline">Need more help? Reach customer care at <a href="tel:+18775808847" style={{ borderRight: "none" }}> 1-877-580-8847</a> </p></div>

          { this.state.loading && 
            <Spinner className="black" />
          }

          { this.state.error &&
            <div className="col-md-12 text-center alert alert-danger">
              {this.state.error}
            </div>
          }

          {this.state.videos && this.state.videos.map((video, index) =>
            <div className="col-md-4" key={video.video} >
              <button onClick={this.showVideo.bind(this, video.video)} className="btn btn-img video-thumb">
                  <img src={video.img} alt="Video" />
              </button>
            </div>
          )}
      
        </div> 
      </MainContent>
      <Footer hideMobile={true}/>

      <Modal size="lg" show={this.state.showPlayer} onHide={this.closeVideo}>
        <Modal.Body>
          
          <Plyr type="video" // or "vimeo"
            url={this.state.tutorialUrl} autoplay
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeVideo}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>

    );

  }
}