/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import SpecialFieldEditor from '../components/SpecialFieldEditor';


export default class FizzLogoOptions extends Component {

	constructor(props) {
		super(props);

		this.state = {
			hasLogoContacts: !!props.hasLogoContacts
		};
		this.parseLogoProp(props.availableLogos);
		this._fireSelectedLogoChange();
	}
	componentWillReceiveProps(nextProps){

		this.state.hasLogoContacts = nextProps.hasLogoContacts;

		if( this.state.availableLogos !== nextProps.availableLogos ) {
			this.parseLogoProp(nextProps.availableLogos);
			this._fireSelectedLogoChange();
		}
	}
	parseLogoProp( logo ) {
		var defaultLogo = null;
		if (logo && logo.options && logo.options.length > 0) {
			defaultLogo = this.findLogoById( logo, logo.defaultId);
			if( !defaultLogo ) {
				defaultLogo = logo.options[0];
			}
		}
		this.state.availableLogos = logo;
		this.state.selectedLogo = defaultLogo;
	}

	findLogoById( logoInfo, logoId ) {
		var match = null;
		if( logoInfo && logoInfo.options && logoInfo.options.length>0 && logoId ) {
			let matches = logoInfo.options.filter(option => option.id == logoId) 
			if( matches.length>0 ) {
				match = matches[0];
			}
		}
		return match;
	}

	render() {

		let logoSwitches = null;
		if (this.state.availableLogos && this.state.availableLogos.options) {
			if(this.state.availableLogos.options.length === 2) {
				let noLogoOption = this.state.availableLogos.options[0];
				logoSwitches = (
					<div className="row">
						<div className="col-8">
							<b>{this.state.availableLogos.title}</b><br/>
							<small>{this.state.availableLogos.description}</small>
						</div>
						<div className="col-4 col-lg-2 text-right">
							<label className="switch">
								<input type="checkbox" checked={this.state.selectedLogo!==noLogoOption} onChange={this._addLogoValueChange.bind(this)} />
								<span className="slider round"></span>
							</label>
						</div>
					</div>
				);
			} else if(this.state.availableLogos.options.length>2) {
				logoSwitches = (
					<div className="row mb-2">
						<div className="col-8" >
							<b>{this.state.availableLogos.description}</b><br/>
							<small>{this.state.availableLogos.description}</small>
							<br/>
							<select 
								className="form-control" 
								onChange={this.dropDownSelectLogo} 
								style={{background:"white"}}
								value={this.state.availableLogos.defaultId||'0'} 
								>
									{	this.state.availableLogos.options.map( (logo)=>
											<option key={'l-'+logo.id} value={logo.id} >{logo.name}</option>
										)
									}
							</select>
						</div>
					</div>
				);
			}
		} 

		let fizzSwitch = ( this.state.selectedLogo && this.state.selectedLogo.contactFields &&
			<div className="row mt-3">
				<div className="col-8">
					<b>Add my contact information</b><br/>
				</div>
				<div className="col-4 col-lg-2 text-right">
					<label className="switch">
						<input type="checkbox" checked={this.state.hasLogoContacts} onChange={this._hasLogoContactsValueChange.bind(this)} />
						<span className="slider round"></span>
					</label>
				</div>
			</div>
		);

		let contactFields = null;
		if (this.state.hasLogoContacts && this.state.selectedLogo && this.state.selectedLogo.contactFields) {
			contactFields = (
				<div className="row mb-2">
					<div className="col-sm-12 col-md-12 col-lg-8" >
						<SpecialFieldEditor specialFields={this.state.selectedLogo.contactFields} onSpecialFieldsChange={this._updateLogoContactFields.bind(this)} />
					</div>
				</div>
			);
		}


		if( logoSwitches || fizzSwitch || contactFields ) {
			return <div>
						{logoSwitches}
						{fizzSwitch}
						{contactFields}
					</div>
		} else {
			return null
		}

	}


	dropDownSelectLogo = (e) => {
		var logoId = e.target.value;
		let selectedLogo = this.findLogoById( this.state.availableLogos, logoId);
		console.log("Select Logo "+logoId,selectedLogo);
		this.selectLogo(selectedLogo);

	}
	selectLogo(logo) {
		if( logo ) {
			this.state.availableLogos.defaultId = logo.id;
		}
		this.setState({selectedLogo: logo },this._fireSelectedLogoChange);
	}
	_addLogoValueChange(e) {
		var value = e.target.checked;
		console.log("Check Val:"+value);
		var logo = this.state.availableLogos.options[ value ? 1 : 0 ];
		this.selectLogo(logo);
	}

	
	_updateLogoContactFields(contactFields) {
		this.state.selectedLogo.contactFields = contactFields;
		this.setState({
			selectedLogo:this.state.selectedLogo
		});
		this._fireSelectedLogoChange();
	}

	_hasLogoContactsValueChange() {
		this.setState({ hasLogoContacts: !this.state.hasLogoContacts },this._fireSelectedLogoChange);
	}

	_fireSelectedLogoChange() {
		if( this.props.onSelectedLogoChange ) {
			this.props.onSelectedLogoChange({
				selectedLogo:this.state.selectedLogo, 
				contactFields: (this.state.hasLogoContacts && this.state.selectedLogo) ? this.state.selectedLogo.contactFields  : null,
				hasLogoContacts:this.state.hasLogoContacts
			});
		}
	}

}
