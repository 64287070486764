import React, { useState, useEffect } from "react";
import Notify from '../components/Notify';

export const toastManager = {
  _showToast:null,
  showToast: (info)=>{
    if( toastManager._showToast ) {
      toastManager._showToast(info)
    }
  }
};


const Toaster = ( props ) => {

  const [list, setList ] = useState([]);
  const [keepOpen, setKeepOpen ] = useState([])

  useEffect(() => {
      // setList(props.toastList);
      if( keepOpen ){
        return;
      }

      const interval = setInterval(() => {
        if ( list.length>0 ) {
            deleteToast(list[0].id);
        }
      }, 5000);
    
      return () => {
        clearInterval(interval);
    }

  }, [ list ]);


  const deleteToast = id => {
    const index = list.findIndex( e => e.id === id );
    list.splice(index, 1);
    setList([...list]);
  }

  const showToast = info => {
    setKeepOpen( !!info.keepOpen );
    info.id = Math.floor( ( Math.random() * 100 ) + 1 );
    // ONLY show one... need new UI to display more than one
    setList( [info] );
  }

  // This will provide access to the newest Toaster put on the screen, We expect there will only ever be one
  toastManager._showToast = showToast;

  return(
    <div className="container">
      { list && list.map( ( toast, i ) => 
        <div key={i}> 
          <Notify 
            float={true}
            jumboTitle={toast.jumboTitle}
            header={toast.title}
            message={toast.message}
            error={toast.error ? toast.error : null }
            onHide={() => deleteToast(toast.id)}
          />
        </div>
      ) }
    </div>
  );
}

export default Toaster;