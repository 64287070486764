import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MainContent from "../components/MainContent";
import { ThemeAnimationBanner } from "../components/ThemeAnimationBanner";
import LandingAssortment from "../components/LandingAssortment";
import LandingVideo from "../components/LandingVideo";
import LandingSteps from "../components/LandingSteps";
import ShopTheCardsBanner from "../components/ShopTheCardsBanner";
import Spinner from "../components/Spinner";


import { apiFetch } from "../helpers/APIHelper";

import step1 from "../assets/images/step-1-digital-update.jpg";
import step2 from "../assets/images/step-2-invite.jpg";
import step3 from "../assets/images/step-3-digital.jpg";

const stepArray = [
  {
    image: step1,
    imageAlt: 'Step 1',
    imageHeader: 'Add photos, video and music',
    imageBody: 'Record your video message, and choose an intro and music from a selection.'
  },
  {
    image: step2,
    imageAlt: 'Step 2',
    imageHeader: 'Invite others to join in',
    imageBody: 'Friends and family can upload photos and videos, too. You choose which ones to use, and set the order.'
  },
  {
    image: step3,
    imageAlt: 'Step 3',
    imageHeader: 'Send instantly for them to enjoy',
    imageBody: 'We stitch it together for you to send via text or email. It’s a moment they’ll never expect—or forget.'
  }
  ];

export class ThemeAnimationLandingPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			slug: this.props.match.params.themeAnimationId,
			loading:true,
		}
	}

	componentDidMount(){
		this.getData();
	}

	getData = () => {
		this.setState({
			loading: true
		});

		apiFetch(
			"/rest/theme-animation/" + this.state.slug + "/landing",
			{
				method: "GET",
			},
			(json) => {
				this.setState({
					themeAnimationId: json.id,
					img: json.img,
					shopImg: json.shopImg,
					video: json.video,
					body: json.body,
					btnText: json.btnText,
					headline: json.headline,
					samples: json.samples,
					linkToTheme: json.linkToTheme,
					loading: false,
				});
			}, 
			(error) => {
				this.setState({
					loading: false,
					error: ((error && error.message )? error.message : error)+''
				});
			}
		)
	}

	render() {
		return(
			<div className="screen-wrap">
				<NavBar/>
					<MainContent>

						{ !this.state.loading && !this.state.error &&
							<>
								<ThemeAnimationBanner themeAnimationId={this.state.themeAnimationId} img={this.state.img} video={this.state.video} body={this.state.body} btnText={this.state.btnText} headline={this.state.headline} linkToTheme={this.state.linkToTheme} />
								<LandingVideo samples={this.state.samples} />
								<LandingSteps stepArray={stepArray}/>
								<ShopTheCardsBanner img={this.state.shopImg}/>
								<LandingAssortment themeAnimationId={this.state.themeAnimationId} />
							</>
						}


						{ !this.state.loading && this.state.error &&
							<div className="container mt-5" >
								<div className="alert alert-danger">{this.state.error}</div>
							</div>
						}

						{ this.state.loading &&
							<Spinner float={true} />
						}

					</MainContent>
				<Footer hideMobile={true} />
			</div>
		)
	}
}

export default ThemeAnimationLandingPage