import React, { Component } from "react";
// import { Button, Modal, Form } from "react-bootstrap";
import { apiFetch } from "../helpers/APIHelper";
import { BrowserRouter as Router, Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import arrowright from "../assets/images/HMK-Icons/Icons_Arrows_Caret_Right_Bold.svg";
import Spinner from "../components/Spinner";
import MainContent from "../components/MainContent";
import TrackingHelper from "../helpers/TrackingHelper";

class ThemeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      themes:[],
      error:null,
      offer: null,
    };
  }

  componentWillMount() {
    this._getData();
    document.title = 'Choose a Video Greeting to Send | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "Browse video greetings by occasion. Find video greeting cards for birthday, congratulations, anniversary and more."
   
  }

  _getData = () => {
    this.setState({
      loading: true,
    });

    apiFetch(
      "/rest/theme-v3?cache=" + (Math.random()*99999999),
      {
        method: "GET",
      },
      (json) => {

        const themes = [];

        for (var i=0; i<json.rows.length; i++) {
          if (json.rows[i].type == "list-2" || json.rows[i].type == "grid" ) {
            for (var j = 0; j < json.rows[i].list.length; j++) {
              themes.push( json.rows[i].list[j] );
            }
          } else if (json.rows[i].type === "theme-img") {
            themes.push( json.rows[i] );
          }
        }

        this.setState({
          loading: false,
          themes: themes,
          offer: json.offer,
        });

        TrackingHelper.trackPage();
      },
      // function (error) {
        (error) => {
        console.log(error);
        this.setState({
          loading: false,
          error: error.message + ""
        })

        TrackingHelper.trackPage();
      }
    );
  };

  render() {
    // var { windowStatus } = this.state;

    // if (windowStatus) {
    //   inputStyle = {
    //     backgroundColor: "blue",
    //   };
    // }

    var styles = {

      icon: {
        height: "60px",
        margin: "20px 0"
      },
      themeName: {
        height: "60px",
        margin: "20px 0"
      },

    };

    // console.log(this.state.grid);
    // console.log(this.state.iconlist);
    // console.log(this.state.windowStatus);
    return (
      <div className="screen-wrap">
        <NavBar/>

        { this.state.offer && 
          <div className="text-center p-4" style={{ backgroundColor: '#61429A', width: '100%'}}>
            <div className="landing_title" style={{ color: 'white', fontSize: '30px' }} >You have <span style={{ fontWeight: 'bold', fontSize:'40px'}}>{this.state.offer.offerExpiresInDays}</span> to start your free Video Greeting</div>
          </div>
        
        }

        <MainContent className="container-fluid p-mobile-0 container-lg">
            
            <h1 className="headline-text headline-gap">Choose an occasion</h1>

            {this.state.error ? (
                <p className="alert alert-danger">{this.state.error}</p>
             ) : (
            <>
            { this.state.loading &&
              <div className="text-center">
                <Spinner float={true} />
              </div>
              
            }

            <div className="row">
              {this.state.themes.map((theme, index) =>

                  <div
                    key={"t-" + theme.themeId}
                    className="col-12 col-lg-3 text-lg-center theme-list-item align-self-center"
                    style={{ backgroundColor: theme.bgColor || theme.color }}
                  >
                    <Link to={"/theme/" + theme.themeId} 
                      style={{ backgroundColor: theme.bgColor || theme.color }} >
                      <div className="text-lg-center">{/* DIV to increase click area*/ }
                        <span className="theme-list-image" >
                          <img src={theme.img} style={styles.icon} alt="Theme Icon" />
                        </span>
                        <div className="theme-list-name headline-text" ><span>{theme.name}</span></div>
                        <span className="theme-list-arrow pull-right d-lg-none" >
                          <span className="arrow">
                            <img
                              src={arrowright}
                              alt="Go to"
                            />
                          </span>
                        </span>
                      </div>
                    </Link>
                  </div>
              )}
            </div>
            </>
             )}
        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }
}

export default ThemeContainer;
