import React, { Component } from 'react';
import DownIcon from "../assets/images/HMK-Icons/Icons_Arrows_Norgie_Down_Bold.svg"
import { getSelectedMusic } from "./MusicPicker";

export default class MusicListField extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        selectedMusic: getSelectedMusic( this.props.music )
    }
  }

  componentDidUpdate() {
    if( this.state.selectedMusic.id != this.props.music.defaultMusicId ){
      this.setState({
        selectedMusic: getSelectedMusic( this.props.music )
      })
    }
  }

    render() {

      if( this.props.displayErr ){
        return(
          <p className="alert alert-danger mt-4">
            {this.props.displayErr}
          </p>
        )
      }

      if( this.props.music && this.props.music.options && this.props.music.options.length>1 ) {
  
        return (
          <div className="change_music mt-3">
            <div className="form-group" >
              <span className="has-float-label">
                <label>Background music</label>
                <button
                  className="form-control text-left music-dropdown"
                  style={{color:'#48464f'}}
                  onClick={()=>{ this.props.showMusicList()}}
                >
                    {this.state.selectedMusic
                      ? this.state.selectedMusic.name
                      : "No Music"}
                    <img src={DownIcon} className="float-right icon-down"/>
                </button>
              </span>
            </div>
        </div>
        );
      }

      return null;
  
    }
  }