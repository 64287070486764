import React, { Component } from "react";
import { Button, Modal, Dropdown, DropdownButton } from "react-bootstrap";
import Plyr from "react-plyr";
import arrayMove from "array-move";
import Dropzone from "react-dropzone";
import { HashRouter, Link, Route, Switch } from "react-router-dom";
import { Prompt } from 'react-router'
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import {toastManager} from "../components/Toaster";
import LoadingBar from "../components/LoadingBar";
import { apiFetch } from "../helpers/APIHelper";
import FizzStatus from "../helpers/FizzStatus";
import ProgressUploader from "../helpers/ProgressUploader";
import UploadManager from "../helpers/UploadManager";
import EditFizzTextModal from "../modal/EditFizzTextModal";
import MusicModal from "../modal/MusicModal";
import NewAlertBubble from "../components/NewAlertBubble";
import EditWatermark from "../components/EditWatermark";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import StandardModal from "../modal/StandardModal";
import userplus from "../assets/images/user-plus.svg";
import IconMove from "../assets/images/HMK-Icons/Icons_Move_Regular.svg";
import IconEdit from "../assets/images/HMK-Icons/Icons_Edit_Regular.svg";
import IconTrash from "../assets/images/HMK-Icons/Icons_Trash_Regular.svg";
import Iconclose from "../assets/images/HMK-Icons/Icons_Menu_Close_Bold.svg";
import Mute from "../assets/images/HMK-Icons/Mute.svg";
import Volume from "../assets/images/HMK-Icons/VolumeHigh.svg";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import Notify from "../components/Notify";
import { isLoggedIn, needsShowQuickSignUp } from "../helpers/APIHelper";
import AddButtonGroup from "../components/AddButtonGroup";
import QuickSignup from "../components/QuickSignup";
import menuclose from "../assets/images/HMK-Icons/Icons_Menu_Close_Bold.svg";
import { getBaseURL } from "../helpers/URLHelper";


const ARE_YOU_SURE_UPLOADING = "Your upload hasn’t finished. Are you sure you want to leave?";

var styles = {
  fullWidth: {
    width: "100%",
  },
  textboxcss: {
    border: "1px solid #202020",
    width: "100%",
  },
  addbox: {
    textAlign: "center",
    padding: "25%",
  },
  textcss: {
    fontSize: "22px",
    fontFamily: "sans-serif",
  },
  buttonStyle: {
    backgroundColor: "#e0e0e0",
    borderRadius: "10px 10px",
    border: "1px solid grey",
    height: "50px",
    width: "50px",
    paddingTop: "10%",
  },
  textbuttonStyle: {
    backgroundColor: "#e0e0e0",
    borderRadius: "10px 10px",
    border: "1px solid grey",
    height: "50px",
    width: "50px",
  },
  buttonTextStyle: {
    fontSize: "9px",
    // paddingTop: "4px",
    fontFamily: "sans-serif",
  },
  textbuttonTextStyle: {
    fontSize: "9px",
    paddingTop: "0px",
    fontFamily: "sans-serif",
  },
  iconPadding: {
    padding: "0",
  },
  buttonBg: {
    backgroundColor: "#603695",
    border: "0",
  },
  rowElement: {
    width: "100%",
  },
  selectBorder: {
    border: "4px solid #603695",
  },
  buttonPadding: {
    padding: "1rem 0.25rem",
  },
  buttonFontSize: {
    fontSize: "x-large",
  },
  floatLeft: {
    float: "left",
  },
  musicModalScroll: {
    height: "400px",
    overflowY: "scroll",
  },
  deleteButton: {
    marginLeft: "8px",
    backgroundColor: "black",
    color: "white",
  },
  dropItembox: {
    paddingLeft: "0",
    paddingTop: "100px",
    textAlign: "center",
    width: "100%",
    height: "270px",
    backgroundColor: "white",
  },
  dropBack: {
    background: "#E5E5E5",
    padding: "10px 20px",
  },
};

class EditFizz extends Component {
  constructor(props) {
    super(props);
    
    this.closeEditVideo = this.closeEditVideo.bind(this);
    this.openEditVideoModal = this.openEditVideoModal.bind(this);
    this.deleteVideo = this.deleteVideo.bind(this);
    this.checkIcon = this.checkIcon.bind(this);
    this.closeMusicModal = this.closeMusicModal.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.handleOnDragEnd = this.handleOnDragEnd.bind(this);

    this.state = {
      clips: [],
      editVideo: null,
      editType: null,
      inputBox: "",
      clipPopup: null,
      deleteToUrl: null,
      processMessage: "",
      fizz: null, // NULL UNTILL LOADED,
      uploadingList: [],
      checkFlag: null,
      showMusicPicker: false,
      music: null,
      deleteFizz: null,
      videoId: null,
      isUploading: false,
      showQuickSignupModal: false,
      signupRejected: false
    };
    this.loadProps(props);
  }
  componentWillReceiveProps(nextProps) {
    if (this.loadProps(nextProps)) {
      this._getData();
    }
  }
  loadProps(props) {
    var old = this.state.fizzId;

    this.state.fizzId = props.match.params.fizzId;

    this.uploadManager = new UploadManager( this.state.fizzId, 'editFizz', {
      onProgress:this.uploadProgress, 
      onAllDone:this.allDone,
      onOneDone:this.oneDone,
    })

    //this.setState({
    //	fizzId: props.match.params.fizzId
    //});

    return old != this.state.fizzId;
  }
  componentDidMount() {
    this._getData();
    document.title = 'Your Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    window.addEventListener("beforeunload", this.windowBeforeUnload);
  }
  componentWillUnmount() {
    // console.log( 'Unmount');
    this.stopCheckingForUpdates();

    if ( this.uploadTimer ){
      clearTimeout(this.uploadTimer);
    }
    window.removeEventListener("beforeunload", this.windowBeforeUnload);
  }

  windowBeforeUnload = (e)=>{

    if( !this.state.isUploading ){
      return;
    }

    var confirmationMessage = ARE_YOU_SURE_UPLOADING;
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  }

  checkForUpdates() {

    if( this.stopChecking ){
      return;
    }
    if( this.state.disableNewestActor ){
      return;
    }

    if( !this.checkingSince ) {
      this.checkingSince = (new Date()).getTime();
    }

    var beenCheckingForSec = ( (new Date()).getTime() - this.checkingSince )/1000;

    var waitTimeInSec = 60;
    if( beenCheckingForSec < 60  ) {
      waitTimeInSec = 20;
    } else if( beenCheckingForSec < 5*60  ) {
      waitTimeInSec = 60;
    } else if( beenCheckingForSec < 30*60  ) {
      waitTimeInSec = 60*3;
    } else {
      // Been on the page for 30min. Leave
      window.location = "/list";
      return;
    }
    // console.log("Check again in "+waitTimeInSec+"sec");
    this.refreshTimeout = setTimeout(() => {

      apiFetch( "/rest/fizz/" + this.state.fizzId + "/newest-actor-video", {
        method: "POST",
      },
      (json) => {

        if ( json.videoId && json.videoId !== this.state.newestActorVideoId ) {
          // get data will update newestActorVideoId
          this.setState({
            newestActorVideoId:json.videoId // THIS will get set in getData... but just incase
          })
          // console.log(`FOUND SOMETHING ${json.videoId} !== ${this.state.newestActorVideoId}`);

          toastManager.showToast({ 
            title:'Good News!',
            message: 'Someone added a video or photo to your Video Greeting.' 
          });

          this.checkingSince = (new Date()).getTime();
          this._getData(true);


          
        } else {
          // console.log(`Still Just ${json.videoId}`);
          this.checkForUpdates();
        }
      },
      (error) => {
        // We wont display an error to the user for this. -EG
        console.log(error);
        this.checkForUpdates();
      });

    }, waitTimeInSec*1000);


  }
  stopCheckingForUpdates = () => {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }
    this.stopChecking = true;
  }

  
  checkIcon(option) {
    this.setState({ checkFlag: option });
  }

  closeEditVideo() {
    this.setState({
      editVideo: null,
      editType: null,
      processMessage: "",
    });
  }

  closeClipPopup = ()=>{
    this.setState({
      clipPopup: null,
    });
  }

  closeMusicModal() {
    this.setState({
      showMusicPicker: false,
      selectedMusicId: this.state.defaultMusicId,
    });
  }

  openDeleteModal(fizz) {
    this.setState({
      deleteFizz: fizz,
    });
  }


  closeDeleteModal() {
    this.setState({
      deleteFizz: null,
    });
  }

  openEditVideoModal(clip, type) {
    this.setState(
      {
        editVideo: clip,
        editType: type,
        videoId: clip.id, 
        checkFlag: clip.audio_option,  
        inputBox: clip.caption ? clip.caption : "",
      }
    );
  }

  openClipPopup = (clip)=>{
    if (!clip.video) {
      clip.video = clip.introVid;
    }
    this.setState({
      clipPopup: clip,
    });
  }

  onHideUpload( uploader ) {
    this.uploadManager.remove(uploader);
  }

  deleteVideo(fizz) {
    var deleteUrl = "/rest/video/" + this.state.editVideo.id;
    this.setState({ editVideo: null, editType: null });

    apiFetch( deleteUrl,
      {
        method: "DELETE",
      },
      (json) => {
        this.setState({
          deleteToUrl: null,
        });
        var array = this.state.clips;
        var index = array.indexOf(fizz);

        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ clips: array });
        }
      },
      (error)=>{
        alert("Failed to delete item", error);
      }
    );
  }

  saveButton(clip, type) {
    var self = this;

    self.setState({
      processMessage: "Saving...",
    });

    if (type == "text") {
      var text = self.state.inputBox;

      apiFetch(
        "/rest/video/" +
          self.state.editVideo.id +
          "/caption/" +
          encodeURIComponent(text),
        {
          method: "PUT",
        },
        function (json) {
          clip.caption = text;
          self.setState({
            processMessage: "Saved",
          });
          // console.log("CAPTION CHANGED");
        },
        function (error) {
          console.log(error);

          self.setState({
            processMessage: error,
          });
        }
      );
    } else if (type == "music") {
      apiFetch(
        "/rest/video/audio-option",
        {
          
          method: "POST",
          data: {
            video: self.state.videoId,
            audioOption: self.state.checkFlag,
          },
        },
         (json) => {
          console.log("music option set");
          clip.audio_option = this.state.checkFlag;
          this.closeEditVideo()
          this.setState({
            clips: this.state.clips //update after manal set 
          });
        },
        function (error) {
          console.log(error);
          self.setState({
            processMessage: error,
          });
        }
      );
    } else if (type == "trim") {
      var trimIframeWindow =
        document.getElementById("trimIframe").contentWindow;
      let trimStart = trimIframeWindow.startTime;
      let trimEnd = trimIframeWindow.endTime;

      apiFetch(
        "/rest/video/" + self.state.editVideo.id + "/trim",
        {
          method: "PUT",
          data: {
            trimStart: trimStart,
            trimEnd: trimEnd,
          },
        },
        function (json) {
          console.log("Trim Saved");
          self.setState({
            processMessage: "Saved",
          });
        },
        function (error) {
          console.log(error);
          self.setState({
            processMessage: error,
          });
        }
      );
    }
  }

  closeQuickSignupModal = () => {

    if( localStorage && localStorage.setItem ) {
      localStorage.setItem("hasSeenQuickSignUp", true );
    }

    this.setState(prevState => ({
      ...prevState,
      signupRejected: true,
      showQuickSignupModal: false
    }));
  }

  onSignupSuccess = () => {

    if( localStorage && localStorage.setItem ) {
      localStorage.setItem("hasSeenQuickSignUp", true );
    }

    this.setState(prevState => ({
      ...prevState,
      signupRejected: false,
      showQuickSignupModal: false
    }));
  }

  showMusicPicker = () => {
    this.setState({
      showMusicPicker: true,
    });
  };
  onSaveMusic = (picked) => {
    if (picked && this.state.music.defaultMusicId == picked.id) {
      // This gets called when the props of the music picker are first set
      this.setState({
        selectedMusic: picked,
      });
      return;
    }

    if (picked) {
      this.state.music.defaultMusicId = picked.id;
    }

    this.setState({
      musicSaveStatus: "Saving...",
    });
    apiFetch(
      "/rest/fizz/" + this.state.fizzId + "/music",
      {
        method: "PUT",
        data: {
          musicId: picked.id,
        },
      },
      (json) => {
        if (picked) {
          this.state.music.defaultMusicId = picked.id;
        }
        this.setState({
          musicSaveStatus: null,
          showMusicPicker: false,
          selectedMusic: picked,
        });
      },
      (error) => {
        this.setState({
          musicSaveStatus: error + "",
        });
        console.log(error);
      }
    );
  };
  onCloseMusic = () => {
    this.setState({
      showMusicPicker: false,
    });
  };

  _getData = () => {
    apiFetch(
      "/rest/fizz/" +
        this.state.fizzId +
        "/detail_v2?cache=" +
        Math.round(Math.random() * 100000000),
      {
        method: "GET",
      },
      (json) => {
        if( json.fizz.isLocked ){
          // Fizz is locked - prevent any edits 
          window.location = "/list";
          return;
        }

        this.setState({
          fizz: json.fizz,
          clips: json.fizz.videos,
          music: json.fizz.music,
          logo: json.fizz.logo,
          specialFields: json.fizz.special_field,
          newestActorVideoId: json.fizz.newestActorVideoId,
          disableNewestActor: json.disableNewestActor,
        },()=>{
          this.checkForUpdates();
          TrackingHelper.trackPage();
        });

      },
      (error) => {
        console.log(error);
        this.setState({
          error: error.message,
        });

        TrackingHelper.trackPage();
      }
    );
  };

  startUpload = (files) => {
    this.uploadManager.startUpload(files);
    this.setState({
      isUploading: true
    })
    this.uploadTimer = setTimeout( () => toastManager.showToast({
      jumboTitle: 'Hang tight! Your upload is still in progress.',
      message: 'Remain on this page, and it will finish soon. We’re sorry for the delay—sometimes large uploads take longer to process.' ,
      keepOpen: true
    }), 5000 );

    this.setState({
      showQuickSignupModal: needsShowQuickSignUp() 
    });   
    
  }

  uploadProgress=(clips)=>{
    this.setState({
      uploadingList: clips
    });
  }
  
  allDone = ( hasAnyErrors )=>{
    console.log("ALL DONE", hasAnyErrors)
    this.setState({
      isUploading: false
    });

    if ( this.uploadTimer ){
      clearTimeout(this.uploadTimer);
    }

    if( !hasAnyErrors ) {
  
      toastManager.showToast({ 
        message: 'Upload successful!' 
      });
      
    }
  }
  oneDone = (uploader)=>{
    if( uploader.hasError() ){
      toastManager.showToast({
        message: uploader.response.message,
        error: 'error'
      })
    }

    // This could be an else... We likely will not get clips if there was an error... but just in case
    if (uploader.response.clips && uploader.response.clips.length>0) {
      this.setState({
        clips: this.state.clips.concat(uploader.response.clips),
      });

      // RETURN TRUE WILL TAKE IT OUT OF THE ARRAY
      return true;
    }
  }

  _OLD_startUpload = (files) => {
    var uploads = [];
    var allSuccess = true;
    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      let uploader = new ProgressUploader(file, (uploader) => {
        // Progress
        // Call set state to refresh
        this.setState({
          uploadingList: this.state.uploadingList,
        });
      });

      uploader.key = Math.random();
      uploader
        .start("POST", `/rest/fizz/${this.state.fizzId}/add-clip?type=desktop`)
        .then((uploader) => {
          // If Error uploading, inform user of error
          if ( uploader.response.status != 'success' ){
            allSuccess = false;
            console.log("Error: ", uploader.response );
            toastManager.showToast({
              message: uploader.response.message,
              error: 'error'
            })

          } 

          if (uploader.response.clips) {
            this.setState({
              clips: this.state.clips.concat(uploader.response.clips),
            });

            for (var j = 0; j < this.state.uploadingList.length; j++) {
              let upload = this.state.uploadingList[j]
              if ( uploader === upload ){
                this.state.uploadingList.splice( j, 1 )
                this.setState({
                  uploadingList: this.state.uploadingList
                });
              }
            }

          }

          var allDone = true;
          for (var i = 0; i < this.state.uploadingList.length; i++) {
            let upload = this.state.uploadingList[i];
            if ( !upload.isDone() ) {
              allDone = false;
              break;
            } 
          }

          if (allDone) {
            this.setState({
              isUploading: false
            });
            
            if( allSuccess ){
              toastManager.showToast({ 
                message: 'Upload successful!' 
              });
            }
          } else {
            // refresh the list
            this.setState({
              uploadingList: this.state.uploadingList,
            });
          }
        })
        .catch( (e) =>{
          // If Error uploading, inform user of error
            allSuccess = false;
            console.error( e );
            toastManager.showToast({
              message: 'Upload Failed',
              error: 'error'
            })
        });

      uploads.push(uploader);
    }

    this.setState({
      isUploading: true,
      uploadingList: this.state.uploadingList.concat(uploads),
    });



  };

  onFileUploadChange = (event) => {
    var acceptedFiles = Array.from(event.target.files);
    console.log("Select", acceptedFiles);
    this.startUpload(acceptedFiles);
    event.target.value = null;
  };
  onDrop = (acceptedFiles) => {
    console.log("Drop", acceptedFiles);
    this.startUpload(acceptedFiles);
  };

  showEditFizzText = () => {
    this.setState({
      showEditFizzText: true,
    });
  };
  onSaveFizzText = (fizzInfo) => {
    if (fizzInfo && fizzInfo.name) {
      this.state.fizz.name = fizzInfo.name;
      this.setState({
        fizz: this.state.fizz,
        showEditFizzText: false,
      });
    }
  };
  onCloseFizzText = () => {
    this.setState({
      showEditFizzText: false,
    });
  };

  handleOnDragEnd(result) {
    console.log("result" + JSON.stringify(result));
    if (!result.destination) return;

    const items1 = Array.from(this.state.clips);
    console.log("items1" + items1);
    const [reorderedItem] = items1.splice(result.source.index, 1);
    items1.splice(result.destination.index, 0, reorderedItem);

    console.log("items1" + JSON.stringify(items1));
    this.setState(
      {
        clips: items1,
      },
      () => this.onSortEnd()
    );

    console.log("handleOnDragEnd");

  }

  onSortEnd = async () => {
    await apiFetch(
      "/rest/video/rearrange",
      {
        method: "PUT",
        data: { videos: this.state.clips },
      },
      (json) => {
        // Nothing to change the ui was already updated
      },
      (error)=>{
        console.log(error);
      }
    );
  };

  render() {
    // console.log(this.state.fizz);
    // console.log(this.state.clips);

    // console.log(this.state.fizzId);

    let fizzStatus = new FizzStatus(
      this.state.fizz ? this.state.fizz.status : 0
    );

    return (
      <div className="screen-wrap">
        <NavBar title="Your Project" />
        
        <Prompt when={this.state.isUploading} message={ARE_YOU_SURE_UPLOADING} />

        <MainContent className="container">
          <h1 className="headline-text">Your Video Greeting</h1>

          {/* Empty screen option  */}
          {this.state.fizz ? (
            <div className="row mt-3 align-items-center justify-content-center bg-margin">
              <div className="col-lg-12">
                <div className="row align-items-center heading_video  pl-1">
                  <div className="col-lg-10 col-8 p-2 p-xs-0 heading_direction">
                    <div>
                    <p>
                      {this.state.fizz.name}
                    </p>
                    </div>
                    <Link
                        className="btn btn-link"
                        to={`/change/${this.state.fizzId}`}
                      >
                        <span>
                          <img src={IconEdit} alt="IconEdit" />
                        </span>
                      </Link>
                  </div>
                  {this.state.fizz.img && (
                    <div className="col-lg-2 col-4 text-right">
                      <Link
                        to={"/preview/" + this.state.fizzId}
                        className="card-img-top video-thumb notSelectable"
                        data-height="80"
                      >
                        <img
                          style={{ width: "100%" }}
                          src={this.state.fizz.img}
                          alt="img"
                        />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 text-center">

                {this.state.clips.length <= 0 && (
                  <>
                    <div className="mt-2 mb-4 text-center">
                      <h5 className="headline-text">
                        Start by adding a photo or&nbsp;video
                      </h5>
                    </div>
                
                    <div className="text-center mb-3">
                      <AddButtonGroup
                        fizzId={this.state.fizzId}
                        onFileUploadChange={this.onFileUploadChange.bind(this)}
                        isInvite={false}
                        errorsCleared={true}
                      />
                    </div>
                  </>
                )}

                <div className={this.state.clips.length <= 0 ? "mt-5" : ""}>
                  <Link to={"/preview/" + this.state.fizzId} 
                      className="btn btn-block btn-lg-inline-block btn-primary"
                      >
                    PREVIEW YOUR VIDEO &gt;
                  </Link>
                  <div>
                    <small className="my-2 d-block">Watch your video and prepare it for sending.</small>
                  </div>
                </div>

                {!isLoggedIn() &&  
                  <button onClick={() => {this.setState(prevState => ({...prevState, showQuickSignupModal: true}))}}
                      className="btn btn-block btn-lg-inline-block btn-secondary quick-signup-cta-btn"
                      >
                    SAVE YOUR PROGRESS
                  </button>
                }
              </div>

              <div className="col-lg-12 mt-3 d-flex flex-column">
                <div className="">
                  {this.state.clips.length > 0 && (
                    <h5 className="headline-text mb-2 d-flex">
                      <div className="flex-grow-0" style={{lineHeight:'61px'}}>Edit Video Greeting</div> 
                      <div className="flex-grow-1 d-none d-lg-block text-right">
                        <AddButtonGroup
                          fizzId={this.state.fizzId}
                          onFileUploadChange={this.onFileUploadChange.bind(this)}
                          isInvite={false}
                          errorsCleared={true}
                        />
                      </div>
                    </h5>
                  )}
                  <DragDropContext onDragEnd={this.handleOnDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          className="droppable"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {/* ) : ( */}

                          {this.state.clips.map((clip, index) => {
                            return (
                              <Draggable
                                key={clip.id}
                                draggableId={"draggable-" + clip.id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <div className="row align-items-center heading_video ">
                                      <div className="col-lg-10 col-8">
                                        <div className="row align-items-center">
                                          <div className="col-6">
                                            <span {...provided.dragHandleProps} className="iconMoveVideo">
                                              <img
                                                src={IconMove}
                                                alt="Iconmove"
                                              />
                                            </span>
                                          </div>
                                          <div className="col-6 text-right d-flex justify-content-end">
                                            { clip.isImage == false ? (
                                              <button
                                                className="link mr-3"
                                                onClick={() =>
                                                  this.openEditVideoModal(
                                                    clip,
                                                    "music"
                                                  )
                                                }
                                              >
                                                <span>
                                                  { clip.audio_option === 1 ? <img src={Mute} alt="Mute" /> : <img src={Volume} alt="Volume" />  }
                                                </span>
                                              </button>
                                            ) : null}


                                            <button
                                              className="link"
                                              onClick={() =>
                                                this.openEditVideoModal(
                                                  clip,
                                                  "delete"
                                                )
                                              }
                                            >
                                              <span>
                                                <img
                                                  src={IconTrash}
                                                  alt="IconTrash"
                                                />
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="col-lg-2 col-4 text-right notSelectable"
                                        key={`clip-${clip.index}`}
                                      >
                                        <div
                                          className="card notSelectable"
                                          // style={
                                          //   editVideos.id === clip.id
                                          //     ? styles.selectBorder
                                          //     : null
                                          // }
                                          unselectable="on"
                                        >
                                          {clip.isImage === false &&
                                          clip.video ? (
                                            <button
                                              className="btn btn-img video-thumb notSelectable"
                                              onClick={this.openClipPopup.bind(this,clip)}
                                              data-height="480"
                                            >
                                              <img
                                                className="card-img-top nonDraggableImage notSelectable"
                                                src={clip.image}
                                                draggable={false}
                                                unselectable="on"
                                                // onMouseDown={disableMouse}
                                              />
                                            </button>
                                          ) : (

                                            <button
                                              className="btn btn-img notSelectable"
                                              onClick={this.openClipPopup.bind(this,clip)}
                                              data-height="480"
                                            >
                                              <img
                                                className="card-img-top nonDraggableImage notSelectable"
                                                src={clip.image}
                                                draggable={false}
                                                unselectable="on"
                                                // onMouseDown={disableMouse}
                                              />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>


                  {this.state.uploadingList.map((upload, index) => {
                    {

                      return (
                          <div key={`upload-${upload.key}`} className="row align-items-center heading_video">
                            <div className="col-lg-10 col-8">
                              { upload.getStatusDisplay() != 'Error Uploading' ?
                                <>
                                  <p>{upload.getStatusDisplay()}</p>
                                  <LoadingBar percent={upload.getPercent()} />
                                </>
                              :
                                <Notify 
                                  message={ upload.response.message != "" ? upload.response.message : "Upload Failed" } 
                                  error="true" 
                                  onHide={this.onHideUpload.bind(this, upload ) } 
                                />
                              }
                            </div>
                            <div
                              className="col-lg-2 col-4 text-right notSelectable"
                              key={`clip-${upload.key}`}
                            >
                              <div className="card card-img-top" >
                                <div
                                  className="notSelectable box-480"
                                  // style={
                                  //   editVideos.id === clip.id
                                  //     ? styles.selectBorder
                                  //     : null
                                  // }
                                  unselectable="on"
                                >
                                  {upload.img && (
                                      <img 
                                        className="box-480-item nonDraggableImage notSelectable uploading-video"
                                        src={upload.img}/>
                                  )}
                                  {upload.video && (
                                      <video 
                                        className="box-480-item nonDraggableImage notSelectable uploading-video"
                                        draggable={false}
                                        unselectable="on"
                                      >
                                        <source src={upload.video} />
                                      </video>
                                  )}
                                  { !upload.img && !upload.video && (
                                      <div className="box-480-item nonDraggableImage notSelectable uploading-video" >
                                      </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                      );
                    }})
                  }
                </div>

                {this.state.clips.length > 0 && <div className="text-center mb-3">
                  <AddButtonGroup
                    fizzId={this.state.fizzId}
                    onFileUploadChange={this.onFileUploadChange.bind(this)}
                    isInvite={false}
                    errorsCleared={true}
                  />
                  {/* {addButtons('bottom')} */}
                </div>}
                {!isLoggedIn() &&
                  <small className="mx-auto text-center pt-5">
                  By continuing, I agree to Hallmark’s &nbsp;
                  <Link className="blue-link" to={getBaseURL("/terms")}>Terms&nbsp;of&nbsp;Use</Link>&nbsp;and&nbsp;<Link className="blue-link" to={getBaseURL("/privacy-policy")}>Privacy&nbsp;Policy</Link>.
                </small>}
              </div>
            </div>
          ) : (
            <div>
              {this.state.error ? (
                <div className=" text-center alert alert-danger mt-4">
                  {this.state.error}
                </div>
              ) : (
                <Spinner float={true} />
              )}
            </div>
          )}

          <MusicModal
            show={this.state.showMusicPicker}
            music={this.state.music}
            onSave={this.onSaveMusic}
            onClose={this.onCloseMusic}
            status={this.state.musicSaveStatus}
          />



          <StandardModal
            size="lg"
            show={this.state.clipPopup}
            onHide={this.closeClipPopup}
            closeBtnText="Close"
          >
            { this.state.clipPopup &&
              (this.state.clipPopup.isImage ? (
                <img src={this.state.clipPopup.image} width="100%"/>
                ):(
                <Plyr
                  type="video" // or "vimeo"
                  url={this.state.clipPopup ? this.state.clipPopup.video : null}
                  autoplay
                />
              )
            )}
          </StandardModal>


        {/* Delete Clip Modal */}
          <StandardModal
            show={this.state.editVideo && this.state.editType == "delete" }
            onHide={this.closeEditVideo}
            header="Are you sure you want to delete this item?"
            confirmBtnText="YES, DELETE"
            onConfirm={this.deleteVideo.bind(this, this.state.editVideo)} 
          >
          Deleted items cannot be recovered.
          </StandardModal>

        {/* Edit Sound Modal */}
          <StandardModal
            show={this.state.editVideo && this.state.editType == "music" }
            onHide={this.closeEditVideo}
            header="Sound options"
            confirmBtnText="DONE"
            onConfirm={this.saveButton.bind(this, this.state.editVideo, this.state.editType )} 
          >
            <ul className="list-group list-group-flush sound-picker-list">
              <a tabIndex="0" onClick={this.checkIcon.bind(this, 0)}>
                <li className="list-group-item text-center">
                  {this.state.checkFlag === 0 ? (
                    <span className="icon-checkmark"></span>
                  ) : null}{" "}
                  Play background music + video&nbsp;sound
                </li>
              </a>
              <a tabIndex="0" onClick={this.checkIcon.bind(this, 1)}>
                <li className="list-group-item text-center">
                  {this.state.checkFlag === 1 ? (
                    <span className="icon-checkmark"></span>
                  ) : null}
                  Mute video sound
                </li>
              </a>
              <a tabIndex="0" onClick={this.checkIcon.bind(this, 2)}>
                <li className="list-group-item text-center">
                  {this.state.checkFlag === 2 ? (
                    <span className="icon-checkmark"></span>
                  ) : null}
                  Mute background music
                </li>
              </a>
            </ul>
            <p>{this.state.processMessage}</p>
          </StandardModal>

          <Modal
            show={this.state.showQuickSignupModal}
            onHide={this.closeQuickSignupModal}
            size='lg'
          >
            <Modal.Header className="text-left pb-0">
              <div onClick={this.closeQuickSignupModal}>
                <span className="close">
                  <img src={menuclose} alt="menuclose" />
                </span>
              </div>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <QuickSignup successCallback={this.onSignupSuccess} preventRedirect = {true} from={'EditFizz'}/>
            </Modal.Body>
          </Modal>
        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }
}

export default EditFizz;
