import React, { Component } from "react";
import { apiFetch } from "../helpers/APIHelper";
import { getMailToLink, getSMSLink, isAndroid, isIOS, canShare, isMobile } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import { toastManager } from "../components/Toaster";
import TrackingHelper from "../helpers/TrackingHelper";
import NextButtonGroup from "./NextButtonGroup";


export default class InviteOptions extends Component {


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      fizzId: this.props.fizzId,
      inviteMessage:"",
      inviteLink: "",
      shareLink: "",
    }
  }

  componentDidMount() {
    this._getData();

    document.title = 'Invite others | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
  }

  _getData = () => {
    this.setState({
      loading: true
    });

    apiFetch('/rest/fizz/' + this.state.fizzId + '?screen=inviteCode', {
      method: 'GET',
    }, (json) => {
      console.log(json);
      this.setState({
          loading: false,
          fizz: json.item,
          inviteMessage: json.item.inviteSampleNoLink,
          inviteLink: json.item.inviteLink,
          linkToSend : json.item.inviteSampleNoLink + '\n\n' + json.item.inviteLink
      });

      TrackingHelper.trackPage();

    }, (error) => {
      console.log(error);
      this.setState({
        loading: false,
        error: ((error&&error.message)||error)+""
      })

      TrackingHelper.trackPage();
    });
  }

  onInviteChange=(e)=>{
    this.setState({
      inviteMessage:e.target.value,
      linkToSend: e.target.value + '\n\n' + this.state.inviteLink
    })
  }

  copyToClipboard=()=>{
    navigator.clipboard.writeText(this.state.linkToSend);
    toastManager.showToast({
			message: 'Copied to Clipboard!'
    });
    this.trackInviteClick();
  }
  
  shareAny=()=>{
    navigator.share({
      text: this.state.linkToSend
    }).then(() => {
      // alert("Sent");
    }).catch((e)=>{
      // Canceled or something else
      // alert("Error"+e);
    })
    this.trackInviteClick();
  }

  trackInviteClick = () => {
    
    if( this.props.onInvite ){
      this.props.onInvite();
    } 
    
    apiFetch('/rest/tracking/best/invite-intent?fizzId=' + this.state.fizzId, {
      method: 'POST',
      data: { value: 1, },
    }, (json) => {

      //fire and forget
    
    }, (error) => {
    
      console.log(error);
    
    });
  }

  render() {

    var hasClipboard = navigator && navigator.clipboard && navigator.clipboard.writeText;

    let smsLink = getSMSLink({body:this.state.linkToSend});
    let emailLink = getMailToLink({body:this.state.linkToSend, subject:"Help me create a surprise group video"});

    return (
      <div>

          <h6 className="subheadline mb-3">Make it a group surprise by collecting photos and videos from others. Edit the message below, and remember to let them know when it’s due.</h6>


          <div className="row pt-3 align-items-center justify-content-center ">
        

            <div className="col-lg-8 text-center">
              
              {this.state.error ? (
                <div className=" text-center alert alert-danger">
                  {this.state.error}
                </div>
              ):(
                <>

                  {this.state.loading && 
                    <Spinner float={true} />
                  }
                  <textarea className="invite-info" value={this.state.inviteMessage} onChange={this.onInviteChange} />

                  {!this.state.loading && 
                    <>
                        { hasClipboard &&
                          <div className="mt-4 mb-3">
                            <button className="link blue-link" onClick={this.copyToClipboard} >
                              Copy Invite and paste anywhere
                            </button>
                          </div>
                        }
        
                      <div className="pinned-bottom-bar">
                        <div className="row">
                          <div className="col-md-6 text-right">
                            { canShare() && isMobile() ?
                              <>
                                { isAndroid() ?
                                  <>
                                  {/* this needs more refactoring -- maybe make a dropdown/pullup option to select either if both. */}
                                    <div>
                                      <a className="btn btn-primary btn-block btn-lg-inline-block" onClick={this.trackInviteClick.bind(this)} href={smsLink}>
                                        Text This Invite
                                      </a>
                                    </div>
                                    <button className="btn btn-primary btn-block btn-lg-inline-block" onClick={this.shareAny}>
                                      More Sending Options
                                    </button>
                                  </>
                                  :
                                  // isIOS
                                  <>
                                    <button className="btn btn-primary btn-block btn-lg-inline-block" onClick={this.shareAny}>
                                      Send This Invite
                                    </button>
                                  </>
                                }
                              </>
                            :
                              <>
                                {/* this needs more refactoring -- maybe make a dropdown/pullup option to select either if both. */}
                                { smsLink && 
                                  <div className="mt-md-4">
                                    <a className="btn btn-primary btn-block btn-lg-inline-block" onClick={this.trackInviteClick.bind(this)} href={smsLink}>
                                      Text This Invite
                                    </a>
                                  </div>
                                }
                                <div className="mb-md-2">
                                  <a className="btn btn-primary btn-block btn-lg-inline-block" onClick={this.trackInviteClick.bind(this)} href={emailLink}>
                                    Email This Invite
                                  </a>
                                </div>

                              </>
                            }
                          </div>

                          <div className="col-md-6">

                              <NextButtonGroup
                                moveToNext = { this.props.moveToNextFlow}
                              />

                          </div>
                        </div>
                      </div>
                    </>
                  }
                </>
              )}
              
            </div>
          </div>

      </div>
    );
  }
}
