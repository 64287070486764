import React from "react";
import { Link } from "react-router-dom";
import { isLoggedIn, logOut } from "../helpers/APIHelper";
import iosBadge from "../assets/images/app-store-ios.png";
import androidBadge from "../assets/images/app-store-android.png";

function PreferAnApp( props ) {

  // until App is fully tested
  return null;

  return (
    <div>
      
      <p className="subheadline">
        <strong className="mb-2">Prefer to use an app?</strong>
        <br/>
        You can create Hallmark Video Greetings with our iOS and Android apps.
      </p>
      <div className="text-center" >
        <a className="p-3" href="https://apple.com/bad" >
          <img src={iosBadge} height="40" />
        </a>
        <a className="p-3" href="https://google.com/bad" >
          <img src={androidBadge} height="40" />
        </a>
      </div>
    </div>
  );
}

export default PreferAnApp;
