export default function SessionStorageHelper( fallback ) {

	return {

		setItem: ( key, val )=>{

      if( sessionStorage && sessionStorage.setItem ){
        return sessionStorage.setItem( key, val )
      } else if ( fallback ){
        return fallback.setItem( key, val );
      } 

      return null;

    },

		getItem: ( key )=>{

      if( sessionStorage && sessionStorage.getItem ){
        return sessionStorage.getItem( key  );
      } else if ( fallback ) {
        return fallback.getItem( key );
      }

      return null;

		}
	}
}