export function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");

  var val = null;
  if (parts.length >= 2) {
    val = parts.pop().split(";").shift();
  }
  return val;
}

export function generateDeviceId() {
  return null;
}

export function getDeviceId() {
  return null;
}

export function isLoggedIn() {
  // return true;
  var storage = ''; 
  var isAccount = false;
  if( localStorage ) {
    storage = localStorage.getItem( 'isAccount' )+'';
    isAccount = storage === 'true';
  }
  return getCookie("sessionId") && isAccount;
}

export function needsShowQuickSignUp() {
  if( isLoggedIn() ){
    return false;
  }

  if( localStorage && localStorage.getItem ) {
    if( localStorage.getItem("hasSeenQuickSignUp") ){
      return false;     
    } 
  } 

  return true;
  
}

export function logOut() {
  document.cookie = "sessionId=; Max-Age=0; path=/;";
  window.location = "/login";
  localStorage.removeItem( 'isAccount' );
  localStorage.removeItem( 'hasSeenQuickSignUp' );
}

export function saveSession( json ) {
  var sessionId = json.webSessionId ? json.webSessionId : json.sessionId;
  
  if( json.isAccount===true || json.isAccount===false ) {

    if( window.localStorage ) {
      window.localStorage.setItem( 'isAccount', json.isAccount );
    }
  }

  if( sessionId ) {
    let days = 30;
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    var expires = "; expires="+date.toUTCString();
    
    document.cookie = "sessionId=" + sessionId +expires+ "; path=/";
  }


}

export function apiFetch(path, options, successFun, errorFun) {
  // console.log(`${path} Request::`, options);
  // console.log(JSON.stringify(options.data));

  // console.log(path, options);
  fetch(path, {
    method: options.method || "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      sessionId: getCookie("sessionId") || "",
    },
    body: JSON.stringify(options.data),
    // body: {
    //   version: "web",
    //   user: {
    //     deviceType: 3,
    //     email: "united@test.com",
    //     password: "test",
    //     deviceId: "web",
    //   },
    // },
  })
    .then((response) => {
      if( response.status==403 ) {
        document.cookie = "sessionId=; Max-Age=0; path=/;";
      }
      var json = response.json();
      json.httpStatusCode = response.status
      return json;
    })
    .then((responseJson) => {
      if (responseJson) {
        if (responseJson.status === "success") {
          // console.log(responseJson);
          if (successFun) {
            saveSession(responseJson);
            return successFun(responseJson);
          }
        } else {
          errorFun( new Error(responseJson.message) );
        }
      } else {
        errorFun( new Error("Error communicating with the server.") );
      }
    })
    .catch((error) => {
      console.log(`${path} Error::`, error);
      if (errorFun) {
        errorFun( new Error('Could not load. Please try again.') );
      }
    });
}

export function logToServer( message ) {
    apiFetch( '/rest/message/log', {
      method: 'POST',
      data:{
        message:message,
        os: 'RJS '+navigator.oscpu+" "+navigator.platform+
          " P="+ navigator.product +"-"+navigator.productSub+
          " V="+ navigator.vendor +"-"+navigator.vendorSub
      }
    }, (json) => {

    }, (error) => {
      console.log( 'Log Error:' + error)
    });
}
