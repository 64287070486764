import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Plyr from "react-plyr";
import "../assets/css/LandingPage.css";
import "react-multi-carousel/lib/styles.css";
import StandardModal from "../modal/StandardModal";
import VideoBGSoTheColorsMatch from "../assets/videos/hero-color-230.mp4";

export class LandingCustomBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoPlay: null
    };
  }

  onGetStarted = () => {
    // window.location = '/offer-register';
    window.location = '/browse'; // offer isOver
  }

  onWatchHowTo = () => {
    this.setState({
      videoPlay: true,
    });
  }

  closeVideoPlay = ()=>{
    this.setState({
      videoPlay: null,
    });
  }

  render() {

    var message = 'Upload your photos and videos to create a one-of-a-kind video greeting. Then send it instantly via text or email for them to save and share.';
    
    var bgColor = 'https://static.hallmarkvideogreetingcards.com/pub/landing-page-digital-hero-lynn-bg.mp4';
    var sideVideo = 'https://static.hallmarkvideogreetingcards.com/pub/landing-page-digital-hero-bday-lynn.mp4';
    if( this.props.isQRCard ) {
      bgColor = VideoBGSoTheColorsMatch;
      message = 'Introducing cards that let you easily add personalized photos and videos—and invite others to join in. Then send it so they can enjoy, save and share.';
      sideVideo = 'https://static.hallmarkvideogreetingcards.com/pub/landing-page-physical-hero-28cq-v2.mp4';
    }

    var isOver = true;
    if( isOver ) {
      return (
        <section className="hero p-0">
          <div className="container-fluid p-0 container-lg">
            <div className="row no-gutters">
              <div className="col-md-12 pb-5 pt-5">
                <h2 className="headline  pt-4 pb-0 text-center">This Free Card Monthly offer has ended</h2>
                <div className="hero__text hero__text__digital p-3">
                  <p className="hero__description mt-2">Our July Digital Video Greetings promotion has ended, but you can still send one today. Add photos and videos, invite others, then send instantly via text or email. Perfect for birthdays, holidays, congrats, and more.</p>
                  <button onClick={this.onGetStarted} className="btn btn-primary">Shop Digital Greetings</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }

      return(
        <section className="hero p-0">
          <h2 className="headline custom-headline pt-4 pb-0 text-center" >Redeem your free July Digital Video&nbsp;Greeting</h2>
          <video className="hero-bg-video disable-gtm" 
                  loop={true}
                  muted={true}
                  autoPlay
                  playsInline ><source src={bgColor} type="video/mp4" /></video>
          <div className="container-fluid p-0 container-lg" >
            <div className="row no-gutters">
              <div className="col-md-8">
                <div className={"hero__text "+(this.props.isQRCard?'hero__text__qr':'hero__text__digital')} >
                  <p className="hero__description mt-2">Hallmark Digital Video Greetings let you add photos and videos, invite others, then send instantly via text or email. Shop our entire selection of free greetings now, or learn more below.</p>
                  {
                    this.props.isQRCard?
                      <button 
                        className="btn btn-outline-primary btn-block btn-lg-inline-block" 
                        onClick={this.onWatchHowTo} 
                        style={{ border: 'none' }}
                      >
                      SEE HOW IT WORKS
                      </button>
                      :
                      <button className="btn btn-primary" onClick={this.onGetStarted} >Shop free Digital Greetings</button>
                  }
                  <p className="mt-4 pb-4 custom-limit-text">Limit 1 per Crown Rewards member.</p>
                </div>
              </div>

              <div className="col-md-4 d-md-block hero__image p-0">
                <video className="disable-gtm p-0 custom-landing-video"
                  loop
                  muted
                  autoPlay
                  playsInline
                  width="100%"
                  // onLoad={(e)=>{e.currentTarget.play()}}
                ><source src={sideVideo} type="video/mp4" /></video>
              </div>
            </div>
          </div>


          <StandardModal
            size='lg'
            show={this.state.videoPlay}
            onHide={this.closeVideoPlay}
            closeBtnText={false} >
              <Plyr
                type="video" // or "vimeo"
                url='https://static.hallmarkvideogreetingcards.com/pub/how-to-video-greeting-card-v1-30cq.mp4'
                autoplay
                options={{playsinline:true}}
              />
          </StandardModal>
        </section>
      );
    }
}

export default LandingCustomBanner;
