import React, { Component } from "react";
import LoadingBar from "./LoadingBar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import IconMove from "../assets/images/HMK-Icons/Icons_Move_Regular.svg";
import IconTrash from "../assets/images/HMK-Icons/Icons_Trash_Regular.svg";
import Mute from "../assets/images/HMK-Icons/Mute.svg";
import Volume from "../assets/images/HMK-Icons/VolumeHigh.svg";
import Notify from "./Notify";


class ClipsList extends Component {
  constructor(props) {
    super(props);

  }


  handleOnDragEnd = (result) => {
    console.log("result" + JSON.stringify(result));
    if (!result.destination) return;

    const items1 = Array.from(this.props.clips);
    console.log("items1" + items1);
    const [reorderedItem] = items1.splice(result.source.index, 1);
    items1.splice(result.destination.index, 0, reorderedItem);

    console.log("items1" + JSON.stringify(items1));

    this.props.updateParentClips( items1 );

    console.log("handleOnDragEnd");

  }

  onHideUpload( uploader ) {
    this.props.uploadManager.remove(uploader);
  }

  render() {


    return (
      <div>
          <DragDropContext onDragEnd={this.handleOnDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className="droppable"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >

                  {this.props.clips.map((clip, index) => {
                    return (
                      <Draggable
                        key={clip.id}
                        draggableId={"draggable-" + clip.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className="row align-items-center heading_video ">
                              <div className="col-lg-8 col-8">
                                <div className="row align-items-center">
                                  <div className="col-6">
                                    <span {...provided.dragHandleProps} className="iconMoveVideo">
                                      <img
                                        src={IconMove}
                                        alt="Iconmove"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-6 text-right d-flex justify-content-end">
                                    { clip.isImage == false ? (
                                      <button
                                        className="link mr-3"
                                        onClick={() =>
                                          this.props.openEditVideoModal(
                                            clip,
                                            "music"
                                          )
                                        }
                                      >
                                        <span>
                                          { clip.audio_option === 1 ? <img src={Mute} alt="Mute" /> : <img src={Volume} alt="Volume" />  }
                                        </span>
                                      </button>
                                    ) : null}


                                    <button
                                      className="link"
                                      onClick={() =>
                                        this.props.openEditVideoModal(
                                          clip,
                                          "delete"
                                        )
                                      }
                                    >
                                      <span>
                                        <img
                                          src={IconTrash}
                                          alt="IconTrash"
                                        />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="col-lg-4 col-4 text-right notSelectable"
                                key={`clip-${clip.index}`}
                              >
                                <div
                                  className="card notSelectable"
                                  unselectable="on"
                                >
                                  {clip.isImage === false &&
                                  clip.video ? (
                                    <button
                                      className="btn btn-img video-thumb notSelectable"
                                      onClick={() => { this.props.openClipPopup( clip) } }
                                      data-height="480"
                                    >
                                      <img
                                        className="card-img-top nonDraggableImage notSelectable"
                                        src={clip.image}
                                        draggable={false}
                                        unselectable="on"
                                        // onMouseDown={disableMouse}
                                      />
                                    </button>
                                  ) : (

                                    <button
                                      className="btn btn-img notSelectable"
                                      onClick={()=> { this.props.openClipPopup( clip ) } }
                                      data-height="480"
                                    >
                                      <img
                                        className="card-img-top nonDraggableImage notSelectable"
                                        src={clip.image}
                                        draggable={false}
                                        unselectable="on"
                                        // onMouseDown={disableMouse}
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>


          {this.props.uploadingList.map((upload, index) => {
            {

              return (
                  <div key={`upload-${upload.key}`} className="row align-items-center heading_video">
                    <div className="col-lg-9 col-8">
                      { upload.getStatusDisplay() != 'Error Uploading' ?
                        <>
                          <p>{upload.getStatusDisplay()}</p>
                          <LoadingBar percent={upload.getPercent()} />
                        </>
                      :
                        <Notify 
                          message={ upload.response.message != "" ? upload.response.message : "Upload Failed" } 
                          error="true" 
                          onHide={()=>{ this.onHideUpload(upload) }} 
                        />
                      }
                    </div>
                    <div
                      className="col-lg-3 col-4 text-right notSelectable"
                      key={`clip-${upload.key}`}
                    >
                      <div className="card card-img-top" >
                        <div
                          className="notSelectable box-480"
                          unselectable="on"
                        >
                          {upload.img && (
                              <img 
                                className="box-480-item nonDraggableImage notSelectable uploading-video"
                                src={upload.img}/>
                          )}
                          {upload.video && (
                              <video 
                                className="box-480-item nonDraggableImage notSelectable uploading-video"
                                draggable={false}
                                unselectable="on"
                              >
                                <source src={upload.video} />
                              </video>
                          )}
                          { !upload.img && !upload.video && (
                              <div className="box-480-item nonDraggableImage notSelectable uploading-video" >
                              </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
              );
            }})
          }
        </div>
    );
  }
}

export default ClipsList;
