import React, { Component } from "react";
import Plyr from "react-plyr";
import "../assets/css/LandingPage.css";
import "react-multi-carousel/lib/styles.css";
import StandardModal from "../modal/StandardModal";
import VideoBGSoTheColorsMatch from "../assets/videos/hero-color-230.mp4";
import AutoPlayVideo from "../components/AutoPlayVideo";

export class ThemeAnimationBanner extends Component {
	constructor(props) {
		super(props);

			this.state = {
			  videoPlay: null
			};
	}

	onGetStarted = () => {
    window.location = this.props.linkToTheme ? this.props.linkToTheme : "/create/" + this.props.themeAnimationId
  }

	render(){
    

		return(
			<section className="hero p-0">
          <div className="container-fluid p-0 container-lg" >
            <div className="row ">
              <div className="col-md-7">
                <div className="hero__text" >
                  <h2 className="headline">{this.props.headline}</h2>
                  <p className="hero__description mt-4">{this.props.body}</p>
				  				<button className="btn btn-primary btn-block btn-lg-inline-block" onClick={this.onGetStarted} >{this.props.btnText}</button>
                </div>
              </div>

              <div className="col-md-5 d-md-block">
                <div className="mt-4 mb-4" >
                  <AutoPlayVideo 
                    className=" disable-gtm "

                    style={{zIndex:100}}
                    loop
                    muted
                    autoPlay
                    playsInline
                    width="100%"
                    
                    // No Source Needed
                    video={this.props.video} 

                  />
                </div>
              </div>
            </div>
          </div>

        </section>
		)
	}
}