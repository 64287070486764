import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MainContent from "../components/MainContent";
import LandingAssortment from "../components/LandingAssortment";
import LandingVideo from "../components/LandingVideo";
import LandingSteps from "../components/LandingSteps";
import Spinner from "../components/Spinner";
import { apiFetch, saveSession } from "../helpers/APIHelper";
import step1 from "../assets/images/step-1-digital-update.jpg";
import step2 from "../assets/images/step-2-invite.jpg";
import step3 from "../assets/images/step-3-digital.jpg";
import LandingOfferBanner from "../components/LandingOfferBanner";
import ShopTheCardsBanner from "../components/ShopTheCardsBanner";

const stepArray = [
  {
    image: step1,
    imageAlt: 'Step 1',
    imageHeader: 'Add photos, video and music',
    imageBody: 'Record your video message, and choose an intro and music from a selection.'
  },
  {
    image: step2,
    imageAlt: 'Step 2',
    imageHeader: 'Invite others to join in',
    imageBody: 'Friends and family can upload photos and videos, too. You choose which ones to use, and set the order.'
  },
  {
    image: step3,
    imageAlt: 'Step 3',
    imageHeader: 'Send instantly for them to enjoy',
    imageBody: 'We stitch it together for you to send via text or email. It’s a moment they’ll never expect—or forget.'
  }
  ];

export class LandingOfferPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading:true,
		}
	}

	
  componentDidMount(){
		this.getData();
	}

	getData = () => {
		this.setState({
			isLoading: true
		});

		apiFetch(
			"/rest/offer/detail/" + this.props.offerKey,
			{
				method: "GET",
			},
			(json) => {
        console.log(json);
        
        if( json.sessionId ){
          saveSession(json);
        }

				this.setState({
					data: json,
          isLoading: false
				});
			}, 
			(error) => {
				this.setState({
					isLoading: false,
					error: ((error && error.message )? error.message : error)+''
				});
			}
		)
	}

	render() {
		return(
			<div className="screen-wrap">
				<NavBar/>
					<MainContent>

          { this.renderContent() }

					</MainContent>
				<Footer hideMobile={true} />
			</div>
		)
    
	}




  renderContent = () => {

    if( this.state.isLoading ) {
      return <Spinner float={true} />
    }
    
    if( this.state.error ){
      return(
        <div className="container mt-5" >
          <div className="alert alert-danger">{this.state.error}</div>
        </div>
      )
    }

    return (
      <>
        <LandingOfferBanner data={this.state.data} /> 
        <LandingVideo />
        <LandingSteps stepArray={stepArray}/>
        <ShopTheCardsBanner /> 
        <LandingAssortment customPage={true} />
      </>
    )

  }
}

export default LandingOfferPage