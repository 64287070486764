import React, { useEffect, useState } from 'react';
import { getQRKey } from '../helpers/URLHelper';
import QuickSignup from '../components/QuickSignup';
import NavBar from "../components/NavBar";
import NavLogo from "../components/NavLogo";
import MainContent from "../components/MainContent";
import { apiFetch  } from "../helpers/APIHelper";
import IdObjectMemCache from "../helpers/IdObjectMemCache";

const SaveProjectSignup = (props) => {

  const [fizzId, setFizzId] = useState( props.match.params.fizzId );
  const [redirect, setRedirect] = useState('');
  const [isPreCheckout, setIsPreCheckout] = useState( props.isPreCheckout );

  useEffect(() => {
    getData();
  }, []);


  const getData = () => {   
    
    apiFetch("/rest/fizz/" + fizzId, {
      method: 'GET',
      }, (json) => {
        console.log(json);

        if( !json.item.needsPurchase || props.destination == 'thankyou' ){
          setIsPreCheckout( false );

          if( json.item.isQRBoxSet ){
            setRedirect( "/thank-you-qr-box/"+ fizzId );
          } else if ( json.item.isQRCard ) {
            setRedirect( "/thank-you-qr/"+ fizzId );
          } else { 
            setRedirect( "/thank-you-free/"+ fizzId );
          }
        } else {
          setIsPreCheckout( true )
        }

    }, (error) => {
      console.log( 'Error:' + error)
    });
  };

  return (
    <div className="screen-wrap">
      { getQRKey()  ? 
        <NavLogo />
        :
        <NavBar />
      }
      <MainContent className="container bg-margin">
        <div className="row">
          <div className="col-md-6">
            <QuickSignup isPreCheckout={isPreCheckout} redirectDestination={redirect} isCheckout={isPreCheckout} {...props} />
          </div>

          <div className="col-md-6 text-center d-none d-md-block pt-4">
            <video className="disable-gtm"
              loop
              muted
              autoPlay
              playsInline
              width="100%"
            ><source src="https://static.hallmarkvideogreetingcards.com/pub/landing-page-digital-hero-28cq-v2.mp4" type="video/mp4" /></video>
          </div>
        </div>
      </MainContent>
    </div>
  );
}

export default SaveProjectSignup;