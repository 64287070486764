import React, {Component} from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

class CheckoutForm extends Component {

  state = {
    errorMessage: '',
  };

  handleChange = ( { error } ) => {
    if(error){
      this.setState({errorMessage: error.message});
    } else {
      this.setState({errorMessage: ''});
    }
  };

  handleSubmit = (evt) => {
    evt.preventDefault();

    if( this.props.shouldRequestToken ) {
      var should = this.props.shouldRequestToken;
      if( !should ) {
        return;
      }
    }

    if (this.props.stripe) {

      this.setState( { disabled: true });

      this.props.stripe.createToken().then((e)=>{
        this.setState( { disabled: false} );
        this.props.handleResult( e );
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };
  
  render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        {this.props.children}

        <div className="form-group">
          <span className="has-float-label">
            <label for="cardNumber">Card number</label>
            <span className="form-control">
              <CardNumberElement
                {...createOptions()}
                onChange={this.handleChange}
                id="cardNumber"
              />
            </span>
          </span>
        </div>
        <div className="row dflex">
          <div className="col-md-6">
            <div className="form-group">
              <span className="has-float-label">
                <label for="expiration">Expiration date</label>
                <span className="form-control">
                  <CardExpiryElement
                    {...createOptions()}
                    onChange={this.handleChange}
                    id="expiration"
                  />
                </span>
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <span className="has-float-label">
                <label for="cvcNumber">CVC</label>
                <span className="form-control">
                  <CardCVCElement
                    {...createOptions()}
                    onChange={this.handleChange}
                    id="cvcNumber"
                  />
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="error" role="alert">
          {this.state.errorMessage}
        </div>
        <button className="btn btn-lg btn-primary btn-block" disabled={ this.state.disabled || this.props.disabled }>Purchase</button>
        <br/>
        <p className="foot-note">Your payment information is secured and encrypted.</p>
      </form>
    );
  }
}

export default injectStripe(CheckoutForm);