import React, { Component } from "react";
import { apiFetch, saveSession } from "../helpers/APIHelper";
import NavBar from "../components/NavBar";
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import SpecialFieldEditor from "../components/SpecialFieldEditor";
import FizzLogoOptions from "../components/FizzLogoOptions";
import Spinner from "../components/Spinner";
import MusicModal from "../modal/MusicModal";
import StandardModal from "../modal/StandardModal";
import { getQRKey, getBaseURL } from "../helpers/URLHelper";
import DownIcon from "../assets/images/HMK-Icons/Icons_Arrows_Norgie_Down_Bold.svg"
import BackLink from "../components/BackLink";
import CaretLeftIcon from "../assets/images/HMK-Icons/Icons_Arrows_Caret_Left_Bold.svg";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import Speaker from "../assets/images/HMK-Icons/VolumeHigh.svg";
import HolidayBoxSetStack from "../assets/images/holiday-box-set-2022.jpg";


export default class CreateFizz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBoxSetAlert: false, 
      isLoading: true,
      apiimage: "",
      specialFields: [],
      imageString: "%0A",
      themeId:null, // Set from API
      isNameLengthGreaterThanOne: false,
      unlockedPhysicalCards:[],
      displayErr: null,
    };
    this.loadProps(props);
  }

  
  hideConfirm = ()=>{
    this.setState({
      displayErr:null,
      createError:null
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.loadProps(nextProps)) {
      this._getData();
    }
  }
  loadProps(props) {
    var oldTA = this.state.taId;
    var oldFizzId = this.state.fizzId;
    const params = new URLSearchParams(props.location.search);
    this.state.taId = this.props.match.params.themeAnimationId;
    this.state.fizzId = this.props.match.params.fizzId;
    this.state.qrKey = this.props.match.params.qrKey;
    this.state.pt = params.get("pt");
    this.state.accessKey = params.get('key');
    return oldTA !== this.state.taId || oldFizzId !== this.state.fizzId;
  }

  componentWillMount() {
    this._getData();
    console.log(this.props);
    document.title = 'Personalize | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
  }

  _getData = () => {
    this.setState({
      displayErr: null,
      isLoading: true,
    });
    apiFetch(
      "/rest/theme-animation/"+this.state.taId+"?key="+this.state.accessKey,      
      {
        method: "POST",
      },
      (json) => {
        saveSession(json);

        let jsonItem = json.item || json;
        this.setState(
          {
            isLoading: false,

            themeId: jsonItem.themeId,
            taId: this.state.taId || jsonItem.taId || jsonItem.themeAnimationId,
            previewImageCount: jsonItem.previewImageCount || 0,

            specialFields: jsonItem.special_field || jsonItem.specialFields || [],
            specialFieldsToSend: this.getSpecialFields(
              jsonItem.special_field || jsonItem.specialFields || []
            ),
            unlockedPhysicalCards: jsonItem.unlockedPhysicalCards || [],
            showBoxSetAlert: !!jsonItem.showBoxSetAlert,
            title: jsonItem.title || "Personalize your Video"
          },
          () => {
            var showExistingProjectModal = false
            if( !getQRKey() && this.state.unlockedPhysicalCards && this.state.unlockedPhysicalCards.length > 0) {
              showExistingProjectModal = true;
            }

            this.setState({
              imageString: this.buildSpecialFieldImageString(),
              showExistingProjectModal: showExistingProjectModal,
            });
          }
        );

        TrackingHelper.trackPage();
      },
      (error) => {
        this.setState({
          isLoading:false,
          displayErr:error.message
        });
        console.log("error message" + error.message);

        TrackingHelper.trackPage();
      }
    );
  };


  onCloseExistingProjectModal = () => {
    this.setState({
      showExistingProjectModal: false,
    })
  }
  onConfirmExistingProjectModal = () => {
    if (this.state.unlockedPhysicalCards.length == 1) {
      window.location.href = "/edit/" + this.state.unlockedPhysicalCards[0]
    }
    else {
      window.location.href = "/list"
    }
  }

  createFizz = () => {
    var fields = this.state.specialFieldsToSend;
    var areAllValid = true;
    for( var i=0; i<fields.length; i++ ){

      var field = fields[i];
      if( field.required==1 ){
        field.invalid = field.value ? (field.value.length===0) : true;
      }
      if( fields[i].invalid ){
        areAllValid = false;
      }

    }

    if( !areAllValid ) {
      this.setState({
        createError: "Please complete all required fields.",
      });
      return;
    }

    this.setState({
      createLoading: true,
      createError: null,
    });
    apiFetch(
      this.state.fizzId ? ("/rest/fizz/"+this.state.fizzId) : "/rest/fizz",
      {
        method: this.state.fizzId ?  "PUT" : "POST",
        data: {
          specialFields: fields,
          themeAnimation: this.state.taId,
          pt: this.state.pt,
          qrKey: getQRKey(),
          key: this.state.accessKey
        },
      },
      (json) => {
        this.setState({
          createLoading: false,
          createError: null,
        });
          window.location = '/edit/' + json.id;
      },
      (error) => {
        this.setState({
          createLoading: false,
          createError: error + "",
        });
        console.log(error.message);
      }
    );
  };

  getSpecialFields(groups) {
    var fields = [];
    for (var i = 0; i < groups.length; i++) {
      var group = groups[i];
      fields = fields.concat(group.items);
    }
    return fields;
  }

  onSpecialFieldsChange = (groups) => {
    this.setState({
      specialFieldsToSend: this.getSpecialFields(groups),
      createError: "", // Clear the error as they type
    });
    clearTimeout(this.textTimeout);
    this.textTimeout = setTimeout(() => {
      this.setState({
        imageString: this.buildSpecialFieldImageString(),
      });
    }, 500);
  };

  buildSpecialFieldImageString = () => {
    let imageString = "";
    if (this.state.specialFieldsToSend) {
      this.state.specialFieldsToSend.map((item) => {
        imageString += `${item.value}\n`;
      });
    }
    return encodeURIComponent(imageString);
  };

 

  render() {

    var previewImages = [];
    for (var i = 0; i < this.state.previewImageCount; i++) {
      previewImages.push(
        "/rest/theme-animation/" +
          this.state.taId +
          "/image-preview/" +
          i +
          "/" +
          this.state.imageString
      );
    }

    var preivewMarkup = ( 
      <div>
        {previewImages.map((url) => (
           <div className="box-480">
            <div className="box-480-item">
              <img
                key={url}
                style={{ width: "100%", marginBottom: "10px" }}
                src={url}
              />
            </div>
          </div>

        ))}
      </div>
    );


    if( this.state.isLoading ){
      return(
        <div className="screen-wrap">       
          { getQRKey() ? 
            <NavLogo />
            :
            <NavBar />
          }
          <MainContent className="container bg-margin">
            <Spinner float={true} />
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }

    if( this.state.displayErr ){

      return(
        <div className="screen-wrap">
          <NavBar/>
          <MainContent className="container bg-margin">
            <p className="alert alert-danger mt-4">
              {this.state.displayErr}
            </p>
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }

    return (
      <div className="screen-wrap">

        { getQRKey() ? 
          <NavLogo />
          :
          <NavBar />
        }
        <MainContent className="container bg-margin">
{/* 
          <p className="back_btn">
            { this.state.fizzId ?
              <BackLink
                to={"/edit/"+this.state.fizzId }
                title="Back to Edit"
              />
              :
              <BackLink
                title="Back"
              />

            }
          </p>
            
            */}

          <h1 className="headline-text">{ this.state.title }</h1>

          {this.props.location.state && this.props.location.state.isBoxSet &&
            <p className="my-2">
              The Video Greeting you create will be linked to every card in your boxed set.
            </p>
          }

          <div className="row mt-3">
            <div className="col-md-6" >
              <SpecialFieldEditor
                  specialFields={this.state.specialFields}
                  onSpecialFieldsChange={this.onSpecialFieldsChange.bind(
                    this
                  )}
                />
              <p className="subheadline mt-4"><i>*Required</i></p>
              
              {/* mobile web view show image between edits and music dropdown */}
              <div className="d-md-none"> 
                  {preivewMarkup}
              </div>


              <div className="pinned-bottom-bar" >
                <button
                  tabIndex="0"
                  onClick={this.createFizz}
                  disabled={this.state.createLoading}
                  className="btn btn-block btn-lg-inline-block btn-primary next-btn"
                >
                  Next
                </button>
              </div>


              {this.state.createLoading && <Spinner float={true} />}

              {this.state.createError  && (
                <p className="alert alert-danger mt-2">
                  {this.state.createError }
                </p>
              )}

            </div>

            <div className="col-md-6 d-none d-md-block" >
                {preivewMarkup}
            </div>
          </div>
          
          <StandardModal
            size="md"
            show={this.state.showExistingProjectModal}
            onHide={this.onCloseExistingProjectModal}
            header="You already started a Video Greeting"
            closeBtnText="Start a new video"
            confirmBtnText={this.state.unlockedPhysicalCards.length == 1 ? "EXISTING PROJECT" : "VIEW YOUR PROJECTS"}
            onConfirm={this.onConfirmExistingProjectModal}
          >
            Looks like you've already scanned your card and started a Video Greeting. Do you want to keep working on that existing project, or start a new, digital-only video?
          </StandardModal>

          <StandardModal
            size="md"
            show={this.state.showBoxSetAlert}
            onHide={ ()=>{this.setState({showBoxSetAlert:false}) } }
            header={<span style={{fontSize:'22px'}}>You purchased a boxed&nbsp;set!</span>}
            confirmBtnText="Get Started"
            closeBtnText={false}
            onConfirm={ ()=>{this.setState({showBoxSetAlert:false}) } }
          >
            <p style={{fontSize:'20px'}} >
             This means every card in the box will have the same Video Greeting.
            </p>
              {/*<img src={HolidayBoxSetStack} width="100%" />*/}
          </StandardModal>



        </MainContent>

        <Footer className="footer-w-pinned-bottom-bar" hideMobile={true} />
        
      </div>
    );
  }
}
