import React, { Component } from "react";
import "./App.css";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";

import { isLoggedIn } from "./helpers/APIHelper";
import { getQRKey } from "./helpers/URLHelper";
import Login from "./screens/Login";
import Register from "./screens/Register";
import LandingPage from "./screens/LandingPage";
import { ThemeAnimationLandingPage } from "./screens/ThemeAnimationLandingPage";
import ThemeContainer from "./screens/ThemeContainer";
import ThemeDetail from "./screens/ThemeDetail";
import FizzList from "./screens/FizzList";
import CreateFizz from "./screens/CreateFizz";
import InviteOthers from "./screens/InviteOthers";
import Checkout from "./screens/Checkout";
import ThankYou from "./screens/ThankYou";
import FAQs from "./screens/FAQ";
import Terms from "./screens/Terms";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import NotFound from "./screens/NotFound";

import EditFizz from "./screens/EditFizz";
import EditFizzFlow from "./screens/EditFizzFlow";
import PreviewFizz from "./screens/PreviewFizz";
import RecordPreviewFizz from "./screens/RecordPreviewFizz";
import RecordFizz from "./screens/RecordFizz";
import QRScan from "./screens/QRScan";
import ViewInvite from "./screens/ViewInvite";
import Tutorials from "./screens/Tutorials";
import ViewInviteThankYou from "./screens/ViewInviteThankYou";
import BaseMarkup from "./screens/_BaseMarkup";
import PasswordReset from "./screens/PasswordReset";
import ScrollToTop from "./helpers/ScrollToTop";
import ChangePassword from "./screens/ChangePassword";
import Toaster, {toastManager} from "./components/Toaster";
import AlertReset from "./screens/AlertReset";
import SharedFizz from "./screens/SharedFizz";
import ViewInviteContact from "./screens/ViewInviteContact";
import QRBoxAdd from "./screens/QRBoxAdd";
import QRBoxLink from "./screens/QRBoxLink";
import QRBoxList from "./screens/QRBoxList";
import QRBoxThankYou from "./screens/QRBoxThankYou";
import QRScanMore from "./screens/QRScanMore";
import SaveProjectSignup from "./screens/SaveProjectSignup";
import OfferLandingPage from "./screens/OfferLandingPage";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {

      if( isLoggedIn() ) {
        return (
          <Component {...props} />
        );
      } else {

        var encodedPath = "";

        if( props.location && props.location.pathname ) {
          encodedPath = encodeURIComponent( props.location.pathname+props.location.search );
          var pathname = props.location.pathname;

          if( pathname.startsWith("/create") || pathname.startsWith("/qr-setup" ) ){
            return (
              <Redirect to={`/register/${encodedPath}`} />
            );
          } 
          
        }
        
        return (
          <Redirect to={`/login/${encodedPath}`} />
        );

      }
    }}
  />
);

class App extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      toastList: '',
    }

  }

  sendToast = ( data ) => {
    toastManager.showToast( data[0] );
  }
  componentDidMount() {}

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Toaster toastList={this.state.toastList} />
          <Switch>
            <Route path="/template-base-markup" exact component={BaseMarkup} />

            <Route path="/qr-setup/:qrKey/create/:themeAnimationId" component={CreateFizz} />
            <Route path="/qr-setup/:qrKey/register" component={ (props)=>(<Register {...props} blockRedirectQR={true}/> )} />
            <Route path="/qr-setup/:qrKey/login" component={ (props)=>( <Login {...props} blockRedirectQR={true}/> )} />
            <Route path="/qr-setup/:qrKey/terms" component={Terms} />
            <Route path="/qr-setup/:qrKey/tutorials" component={Tutorials} />
            <Route path="/qr-setup/:qrKey/faq" component={FAQs} />
            <Route path="/qr-setup/:qrKey/privacy-policy" component={PrivacyPolicy} />
            <Route path="/qr-setup/:qrKey/change-password"component={ChangePassword} />
            <Route path="/qr-setup/:qrKey/password-reset"component={PasswordReset} />
            <Route path="/qr-setup/:qrKey/:themeAnimationId/add" component={QRBoxAdd} />
            <Route path="/qr-setup/:qrKey/:fizzId/link" component={QRBoxLink} />
            <Route path="/qr-setup/:qrKey/:sku/:skuId" component={QRScan} />
            <Route path="/qr-setup/:qrKey/list" component={QRBoxList} />
            <Route path="/qrbox-scan-more/:fizzId" exact component={QRScanMore} />
            
            <Route path="/in/:uuid/record/:fizzId" exact component={ (props) => (<RecordFizz {...props} isInvite={true} /> )} />
            <Route path="/in/:uuid" component={ViewInvite} />
 
            <Route path="/" exact component={LandingPage} />
            <Route path="/free-july" exact component={ (props) => (<OfferLandingPage {...props} offerKey= {'free-july'} /> )} />
            <Route path="/free-adobe" exact component={ (props) => (<OfferLandingPage {...props} offerKey= {'free-adobe'} /> )} />
            <Route path="/free-hmkhq-event" exact component={ (props) => (<OfferLandingPage {...props} offerKey= {'free-hmkhq-event'} /> )} />
            <Route path="/video-greeting/:themeAnimationId" exact component={ThemeAnimationLandingPage} />
            <Route path="/browse" component={ThemeContainer} />
            <Route path="/change-password/:otp" exact component={ChangePassword} />
            <Route path="/faq" exact component={FAQs} />
            <Route path="/login/:redirect" exact component={Login} />
            <Route path="/login" exact component={Login} />
            <Route path="/password-reset" component={PasswordReset} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/register/:redirect" exact component={Register} />
            <Route path="/register" exact component={Register} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/theme/:themeId" component={ThemeDetail} />
            <Route path="/tutorials" component={Tutorials} />
            <Route path="/alert-reset-viewed/:otp/:fizzId" component={AlertReset} />
            <Route path="/view-invite-thank-you" component={ViewInviteThankYou} />
            <Route path="/view-invite-contact" component={ViewInviteContact} />
            <Route path="/view-invite-trial-register" exact component={(props)=>(<Register {...props} isTrialAccount={true} /> )} />
            <Route path="/view-invite-trial-login" exact component={(props)=>(<Login {...props} isTrialAccount={true} /> )} />
            <Route path="/offer-register" exact component={(props)=>(<Register {...props} isShareOffer={true} /> )} />
            <Route path="/offer-login" exact component={(props)=>(<Login {...props} isShareOffer={true} /> )} />
            <Route path="/create/:themeAnimationId" component={CreateFizz} />
            <Route path="/edit/:fizzId" component={EditFizzFlow} />
            <Route path="/record/:fizzId" exact component={RecordFizz} />
            <Route path="/preview/:fizzId" exact component={PreviewFizz} />
            <Route path="/checkout/:fizzId" exact component={Checkout} />

            <Route path="/vid/:uuid" exact component={SharedFizz} />

            <Route path="/record-thank-you" exact component={(props) => (<RecordFizz {...props} isThankyou={true} /> )}  />
            <Route path="/record-thank-you/:fizzId" exact component={(props) => (<RecordFizz {...props} isThankyou={true} /> )}  />
            <Route path="/preview-thank-you/:fizzId" exact component={(props) => (<PreviewFizz {...props} isThankyou={true} /> )}  />
            <Route path="/thank-you-reply/:fizzId" exact component={ThankYou} />

            <Route path="/thankyou-thank-you/:fizzId" exact component={(props) => (<ThankYou {...props} isThankyou={true} /> )} />


            <PrivateRoute path="/change-password" exact component={ChangePassword} />
            <Route path="/checkout/:fizzId" exact component={Checkout} />

            <Route path="/signup-checkout/:fizzId" component={(props) => (<SaveProjectSignup {...props } destination='checkout' from="signUpCheckOut"/> )} />
            <Route path="/signup-thank-you/:fizzId" exact component={(props)=>(<SaveProjectSignup {...props } destination='thankyou' from="signUpThankYou"/> )} />

            <Route path="/change/:fizzId" component={CreateFizz}  />
            <Route path="/invite-others/:fizzId" component={InviteOthers} />
            <Route path="/list" exact component={FizzList} />
            <Route path="/record/:fizzId" exact component={RecordFizz} />
            <Route path="/preview/:fizzId" exact component={PreviewFizz} />
            <Route path="/thankyou/:fizzId" exact component={ThankYou} />

            <Route path="/thank-you-free/:fizzId" exact component={ThankYou} />
            <Route path="/thank-you-qr/:fizzId" exact component={ThankYou} />
            <Route path="/thank-you-digital/:fizzId" exact component={ThankYou} />
            <Route path="/thank-you-qr-box/:fizzId" exact component={QRBoxThankYou} />




            <Route path="*" exact component={NotFound} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
