import React from "react";

export default function CarouselItem ( props ) {
  return (
    <div className="step_container">
      <figure className="step_snap">
        <div className="image">
          <img
            src={ props.image }
            alt={ props.imageAlt }
          />
        </div>
      </figure>
      <div className="figure_desp text-left">
        <h6>{ props.imageHeader }</h6>
        <p>{ props.imageBody }</p>
      </div>
    </div>
  );
}