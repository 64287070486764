import React, { Component } from "react";
import ReactDOMServer from 'react-dom/server';
import { apiFetch, generateDeviceId, saveSession } from "../helpers/APIHelper";
import Plyr from "react-plyr";
import { Link } from "react-router-dom";
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import moment from 'moment';
import menuclose from "../assets/images/HMK-Icons/Icons_Menu_Close_Bold.svg";
import BackLink from "../components/BackLink";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import MultiVideoPlayer from '../components/MultiVideoPlayer';
import UserComments from "../components/UserComments";
import { getFizzUUID } from "../helpers/URLHelper";
import { getMailToLink, getSMSLink, isMobile, isIOS, canShare, share } from "../helpers/URLHelper";
import RecordIcon from "../assets/images/HMK-Icons/Icons_Record_Bold.svg";
import MailIcon from "../assets/images/HMK-Icons/Icons_Mail_Regular.svg";
import TextIcon from "../assets/images/HMK-Icons/Icons_Chat_Regular.svg";
import StandardModal from "../modal/StandardModal";
import ShareOG from "../assets/images/HMK-Icons/Icons_Share_Share_iOS_Bold.svg"
import DownloadIconOG from "../assets/images/HMK-Icons/Icons_Download_File_Regular.svg";
import ViewPageBanner from "../components/ViewPageBanner";
import VideoStatsBar from "../components/VideoStatsBar";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import MemCache from "../helpers/MemCache";
import SessionStorageHelper from "../helpers/SessionStorageHelper";


const viewStorageHelper = LocalStorageHelper( SessionStorageHelper( MemCache ) );

export default class SharedFizz extends Component {
  constructor(props) {
    super(props);
 
    this.fizzUUID = getFizzUUID();
    this.plyr = React.createRef();
    // this.playScroll = React.createRef();
        
    console.log(this.fizzUUID);
    this.state = {
      title: "",
      video: "",
      img: "",
      showRedeem: true,
      showVideoStats: false,
      firstPlay: false,
      commentBody: "",
      commentFirstName: "",
      commentLastName: "",
      playlistVideos: [],
      activeVideo: {},
      bannerType:null
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
  }
  
  componentWillUnmount() {
    // this.videoElement.removeEventListener("play" );
  }



  getData = () => {
    this.setState({
      isLoading: true
    });
    console.log("setting data...");
    apiFetch(`/rest/vid/${this.fizzUUID}/fizz?cache=${Math.round(Math.random() * 100000000)}`, {
      method: 'GET',
    }, (json) => {
      console.log(json);
      this.setState({
        isLoading: false,
        title: json.title,
        img: json.img,
        video: json.video,
        shareUrl: json.shareUrl,
        thankyouAnimation: json.thankyouAnimation,
        thankyouAnimationImg: json.thankyouAnimationImg,
        fizzId: json.fizzId,
        offer: json.offer,
        showVideoStats: json.showVideoStats,
        showComments: json.showComments,
        showMultiPlayer: json.showMultiPlayer,
        bannerType: json.bannerType,
      });

      if( json.sessionId ){
        saveSession(json);
      }
      
      
      const list = json.videos;
      this.setState(prevState => ({
        ...prevState,
        playlistVideos: list
      }));
    

      TrackingHelper.trackPage();
    }, (error) => {
      this.setState({
        isLoading: false,
        error: error+""
      })
      TrackingHelper.trackPage();
    });
  };


  handleChange = (event) => {

    var value = event.target.value;
    if( event.target.type=='checkbox' ){
      value = event.target.checked;
    }
    this.setState({
      [event.target.name]: event.target.value,
      errors:{}
    });

    console.log("Handle Change", event.target.name, value );
  };


  fizzPlayed = () => {
    console.log( 'fizz played' );
    TrackingHelper.trackPreview();
    
    this.updateViewsCount();
  }

  updateViewsCount = () => {
    var savedUpdateTime = viewStorageHelper.getItem( 'statsUpdateTime' );
    var viewedFizzId = viewStorageHelper.getItem( 'viewedFizzId' );
    var duration = moment.duration( moment().diff( moment( savedUpdateTime ) ) );
    var minutes = duration.asMinutes();
    
    // is this the same fizz?
    if( this.state.fizzId && this.state.fizzId == viewedFizzId ){
      
      if( minutes < 1 ){
        // refresh time occured during blackout time.
        return;
      }
      
    }
    
    viewStorageHelper.setItem( 'viewedFizzId', this.state.fizzId );
    viewStorageHelper.setItem( 'statsUpdateTime', moment().format() );

    apiFetch(`/rest/vid/${getFizzUUID()}/viewed`, {
      method: 'POST',
    }, (json) => {
      console.log(json);
      
      // right now we do not care about return.
        // viewStorageHelper.setItem( 'views', json.viewCount );

    }, (error) => {
      console.log( 'Error:' + error)
    });

  }

  
  shareAny=()=>{
    navigator.share({
      url: this.state.shareUrl
    }).then(() => {
      // alert("Sent");
    }).catch((e)=>{
      // Canceled or something else
      // alert("Error"+e);
    })
  }


  redeemOffer = () => {
    apiFetch( `/rest/user/actor-info?uuid=${this.fizzUUID}` , {
      method:'POST',
    }, (json) => {
      console.log( json );
     
      
    }, (error) => {
      console.log(`Error::`, error);
    }); 
  }

  showPreview(ta) {
    this.setState({
      previewTA: ta,
    });

  }


  renderOffer() {

    if( !this.state.offer ) {
      return null;
    }

    var viewMarkup = ((this.state.offer||{}).ui||{}).viewMarkup;

    // viewMarkup = ReactDOMServer.renderToStaticMarkup(
    //   <>
    //     <h5>Like what you see? Create an account and you’ll have 30 days to make your first Hallmark Video Greeting Card FREE!</h5>
    //     <p>*Digital delivery only (send by email or text)</p>
    //   </>
    // );


    console.log( JSON.stringify(viewMarkup) );

    return (
        <>
          <hr/>
            <div className="row mt-3 mb-3">
              <div className="col-12 col-md-8">
                <div dangerouslySetInnerHTML={{__html:viewMarkup}} />
              </div>

              <div className="col-12 col-md-4 d-block">
                <Link to="/offer-register"
                  className="btn btn-block btn-primary"
                  >
                  {(this.state.offer.ui||{}).viewBtn || 'REDEEM OFFER' }
                </Link>
              </div>

            </div>
          <hr/>
        </>
    );
  }

  render() {

    if( this.state.isLoading ){
      return(
        <div className="screen-wrap">
          <NavLogo/>
          <MainContent className="container bg-margin">
            <Spinner float={true}/>
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }


    var generalURL = this.state.shareUrl;

    var videoUrl = this.state.video;
    var downloadUrl = generalURL+"/download";

    var shareUrl = generalURL;

    let smsLink = getSMSLink({body:shareUrl});
    let emailLink = getMailToLink({body:shareUrl});


    let emailLinkThankYou = getMailToLink({
      subject:'Thank You!',
      body: "Thank you! I’m so grateful you thought of me, and the video you sent made me feel extra special." 
    });
    let smsLinkThankYou = getSMSLink({
      subject:'Thank You!',
      body: "Thank you! I’m so grateful you thought of me, and the video you sent made me feel extra special." 
    });

    let showIOSMessage = !!isIOS();



    return (
      <div className="screen-wrap">
        <NavLogo />
        <MainContent className="container bg-margin">

          <h1 className="headline-text text-center">{this.state.title}</h1>
          <h5 className="subheadline mb-3 text-center">Watch a surprise video made just for you:</h5>
          { this.state.showMultiPlayer ? (
            
            <MultiVideoPlayer videos={this.state.playlistVideos} showStats={this.state.showVideoStats} shareUrl={this.state.shareUrl}/> 

          ) : (
            <>
              <div className="row share-video-panel">
                <div className="col-12">
                  <Plyr
                    type="video" // or "vimeo"
                    poster={this.state.img}
                    url={this.state.video}
                    autoplay
                    onPlay={this.fizzPlayed}
                    />
                </div>
              </div>

              { this.state.showVideoStats && <VideoStatsBar shareUrl={this.state.shareUrl} /> }
            </>
          )}

          <ViewPageBanner bannerType={this.state.bannerType} something="asdf" />

          {this.renderOffer()}

          <div className="">
            <h2 className="headline-text mt-4 pt-4">Did this video brighten your day?</h2>
            <div className="mt-3 mb-3 text-left">
              <p>We hope this video makes you feel special. Download it now and share those happy feelings on social media or anywhere else. It will remain available to download for 6 months.</p>

              { canShare() ? (
                <div className="mt-1">
                  <button id="share-navigator" className="btn btn-link blue-link blue-link"  onClick={this.shareAny}  >
                      <i className="icon-mask" style={{ WebkitMaskImage:"url("+ShareOG+")"}} alt="Share Icon"></i>
                      Share This Video
                  </button>
                </div>
                ):
                <>

                  { smsLink && 
                    <div className="mt-1">
                      <a id="share-text" className="blue-link mb-2" href={smsLink} >
                        <i className="icon-mask" style={{ WebkitMaskImage:"url("+TextIcon+")"}} alt="Message Icon"></i>
                        Text
                      </a>
                    </div>
                  }
                  <div className="mt-1">
                    <a id="share-email" href={emailLink} className="blue-link" >
                      <i className="icon-mask" style={{ WebkitMaskImage:"url("+MailIcon+")"}} alt="Email Icon"></i>
                      Email</a>
                  </div>
                </>
              }
              <div className="mt-2">
                  <a href={downloadUrl} download="Video.mp4" className="blue-link  tag-download-view">
                    <i className="icon-mask" style={{ WebkitMaskImage:"url("+DownloadIconOG+")"}} alt="Download Icon"></i>
                    Download and save
                  </a>
              </div>
              { showIOSMessage &&
                <div className="mt-2">
                  <p><b>iOS users:</b> Your Video Greeting will download to the “Files” app on your phone.</p>
                </div>
              }

            </div>
          </div>

        <div className="row mt-4">
          <div className="col-md-6" >
            <h2 className="headline-text mb-3">Send a quick thank you { this.state.thankyouAnimation ?' for FREE':''}</h2>

              { this.state.thankyouAnimationImg ? (

                <div className="notSelectable">
                  <div className="action-wrapper w-100 d-flex justify-content-between align-items-baseline">
                    <Link to="/record-thank-you" className="mob_buttonIcon">
                      <i className="icon-mask" style={{ WebkitMaskImage:"url("+RecordIcon+")" }} alt="RecordIcon"></i>                      
                      Personalize
                    </Link>
                  </div>
                  <button
                    onClick={this.showPreview.bind(this, this.state.thankyouAnimation)}
                    className="btn btn-img video-thumb notSelectable"
                  >
                    <img src={this.state.thankyouAnimationImg} alt="video" />
                  </button>
                </div> 

              ) : (
                <>
                    <a href={emailLinkThankYou} className="blue-link btn-padded tag-send-thank-you">
                      <i className="icon-mask" style={{ WebkitMaskImage:"url("+MailIcon+")"}} alt="Email Icon"></i>
                      Email
                    </a>

                  { smsLinkThankYou && 
                      <div>
                        <a className="blue-link btn-padded tag-send-thank-you" href={smsLinkThankYou}>
                          <i className="icon-mask" style={{ WebkitMaskImage:"url("+TextIcon+")"}} alt="Copy Icon"></i>
                          Text message
                        </a>
                      </div>
                    }
                </>
              )}
            </div>
        </div> 

        
        { this.state.showComments && <UserComments fizzUUID={this.fizzUUID} /> }

        <StandardModal
            size='lg'
            show={this.state.previewTA}
            onHide={this.showPreview.bind(this, null)}
            confirmBtnText="Personalize This Video"
            onConfirm={ ()=>{ window.location = "/record-thank-you" } } 
            closeBtnText={false} >
              <Plyr
                type="video" // or "vimeo"
                url={this.state.previewTA ? this.state.previewTA : null}
                autoplay
                playsInline={true}
                options={{playsInline:true}}
              />
          </StandardModal>

        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }
}
