import React, { Component } from 'react';
import { Button, Modal, } from 'react-bootstrap';
import IconPlay from "../assets/images/HMK-Icons/icon-play.svg";
import IconPlayOutline from "../assets/images/HMK-Icons/icon-play-outline.svg";
import IconPause from "../assets/images/HMK-Icons/icon-pause.svg";
import IconCheck from "../assets/images/HMK-Icons/icon-check.svg";
import Equalizer from '../components/Equalizer';

export default class MusicModal extends Component {

	constructor(props) {
		super(props);

		this.state = {
			show: true,
			isPlaying: false,
			tracks: ['All'],
			trackCategories: [],
			selectedCategoryElemId: null,
		}
		this.loadProps(props);
		
	}

	componentWillReceiveProps(nextProps){
		this.getTrackCategories();
		var old = this.state.selectedMusic;
		this.loadProps(nextProps);
		if( old !== this.state.selectedMusic ) {
			if( this.props.onSelect ) {
				this.props.onSelect(this.state.selectedMusic);
			}
		}
	}

	componentDidMount() {
		this.audioRef = React.createRef();
		this.getTrackCategories();
	}

	loadProps( props ) {
		this.state.show = props.show;
		this.state.selectedMusic = this.getSelectedMusic(props.music);
		this.state.pickedMusic = this.state.selectedMusic;
		this.state.tracks = !!props.music && props.music.options;
		if( props.show ) {
			setTimeout(()=>{
				let el = this.playAudio(this.state.selectedMusic);
				if( el && el.parentElement && el.parentElement.scrollIntoView ){
					el.parentElement.scrollIntoView()
				}
			},10);
		}
		
	}


	close = () => {
		this.setState({
			show:false
		})
		if( this.props.onClose ) {
			this.props.onClose();
		}
	}

	save = ( ) => {
		if( this.props.onSave ) {
			this.props.onSave(this.state.pickedMusic);
		}
	}

	playAudio = (music) => { 		
		// Stop the old
		if(this.state.pickedMusic !== music && this.state.pickedMusic !== null) {
			this.audioRef.current.pause();
			this.setState({
				pickedMusic: music
			});
			// Load and play the new
			this.audioRef.current.load();
			this.audioRef.current.play();
		}
		else {
			this.audioRef.current.play();
		}
		// Play new
		// var x = document.getElementById('music-'+music.id);
		// if( x ) {
		// 	x.play();
		// 	return x;
		// }
	}

	handlePauseEvent = () => {
		this.setState(prevState => ({
			...prevState,
			isPlaying: false
		}));
	}

	handlePlayEvent = () => {
		this.setState(prevState => ({
			...prevState,
			isPlaying: true
		}));
	}

	togglePlay = () => {
		if (this.state.isPlaying) {
			this.audioRef.current.pause();
			console.log("Paused: ", this.audioRef.current.paused);
		}
		else {
			this.audioRef.current.play();
			console.log("Paused: ", this.audioRef.current.paused);
		}
	}

	handleCategoryFilter = (e) => {
		const target = e.target;
		
		const filterBy = target.textContent;
		if (filterBy === "All") {
			this.setState(prevState => ({
				...prevState,
				selectedCategoryElemId: `category-${filterBy}`,
				tracks: this.props.music.options
			}));
			return;
		}
		const filteredTracks = this.props.music.options.filter(track => {
			return track.name.includes(filterBy)
		});
		this.setState(prevState => ({
			...prevState,
			selectedCategoryElemId: `category-${filterBy}`,
			tracks: filteredTracks
		}), () => {console.log(this.state.tracks)});
	}

	getSelectedMusic = ( musicPickerObj ) => {
		if( musicPickerObj && musicPickerObj.options ) {
			for (var i = 0; i < musicPickerObj.options.length; i++) {
				let music = musicPickerObj.options[i];
				if( music.id === musicPickerObj.defaultMusicId ||  music.id === musicPickerObj.defaultId ) {
					return music;
				}
			}
		}
		return null;
	}

	getTrackCategories = () => {
		if (this.props.music && this.props.music.options.length) {
			const categories = this.props.music.options.filter(track => {
				return track.name.includes("-");
			}).map(track => {
				return track.name.split("-")[0].trim();
			}).filter((category, index, self) => {
				return self.indexOf(category) == index;
			});
			categories.unshift("All");
			this.setState(prevState => ({
				...prevState,
				selectedCategoryElemId: `category-${categories[0]}`, // This should be removed if the "All" option gets removed in future
				trackCategories: categories
			}));
		}
		else {
			console.log("options not loaded");
		}
	}

	render() {

		var styles = {
			musicModalScroll: {
				maxHeight: '400px',
				overflowY: 'scroll',
			}
		};

		if( this.state.tracks && this.state.tracks.length>1 ) {
			return (
				<Modal 
					show={this.state.show} 
					onHide={this.close} 
					fullscreen="lg-down"
					dialogClassName="music-modal"
				>
					<Modal.Header closeButton>
						<div className="d-flex flex-column align-items-center w-100 mr-n4">
							<small>
								<i>Your Current Track</i>
							</small>
							<div className="d-flex justify-content-between align-items-center w-100 my-3">
								<span 
									className="themed-icon-wrapper" 
									style={{
									maskImage: `url(${this.state.isPlaying ? IconPause : IconPlay})`,
									WebkitMaskImage: `url(${this.state.isPlaying ? IconPause : IconPlay})`
									}}
									onClick={this.togglePlay}
								>
									&nbsp;
								</span>
								<h3 className="picked-track-name"><em>{this.state.pickedMusic.name}</em></h3>
								<div className="equalizer-wrapper">
									<Equalizer play={this.state.isPlaying} />
								</div>
							</div>
							<audio ref={this.audioRef} onPause={this.handlePauseEvent} onPlay={this.handlePlayEvent}>
								<source src={this.state.pickedMusic.url} type="audio/mpeg" />
							</audio>
						</div>
					</Modal.Header>
					<Modal.Body style={styles.musicModalScroll}>
						<div className="d-flex align-items-center flex-wrap">
							{this.state.trackCategories.map(category => (
								<i 
									id={`category-${category}`}
									className={`track-category ${
										this.state.selectedCategoryElemId == "category-"+category 
										? "active" 
										: 
										""
									}`} 
									key={category} 
									onClick={this.handleCategoryFilter}
								>
									{category}
								</i>
							))}
						</div>
						<ul className="list-group music-picker-list">

							{this.state.tracks.map((music) =>{

								if( music.id==-2) {
									return null;
								}
								return(
									
									<li 
										key={music.id} 
										className="list-group-item music-track" 
										onClick={
											this.state.pickedMusic == music 
											?
											this.togglePlay
											:
											this.playAudio.bind(this, music)
										} 
									>	
										{this.state.pickedMusic == music ? 
											<span 
												className="themed-icon-wrapper" 
												style={{
												maskImage: `url(${this.state.isPlaying ? IconPause : IconPlay})`,
												WebkitMaskImage: `url(${this.state.isPlaying ? IconPause : IconPlay})`
												}}
												
											>
												&nbsp;
											</span>
											:
											<img src={IconPlayOutline} width="18px" height="21px" />
										}
										<span className={`track-name${this.state.pickedMusic == music ? " font-weight-bold mr-2" : ""}`}>
											{music.name}
										</span>
										{this.state.pickedMusic == music && <span 
											className="themed-icon-wrapper primary" 
											style={{
											maskImage: `url(${IconCheck})`,
											WebkitMaskImage: `url(${IconCheck})`
											}}
											onClick={this.togglePlay}
										>
											&nbsp;
										</span>}
									</li>
								)
							})}   

						</ul>

					</Modal.Body>
					<Modal.Footer>
						{this.props.status &&
							<span>{this.props.status}</span>
						}
						<div className="d-flex align-items-center w-100">
							<Button variant="primary  btn-block mr-2" onClick={this.save}  >
								Save
							</Button>
							<Button variant="outline-primary btn-block" onClick={this.close} >
								Cancel
							</Button>
						</div>
					</Modal.Footer>
				</Modal>

				);
		}
		return null;

	}
}