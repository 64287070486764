import React, { useEffect } from "react"
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import BackLink from "../components/BackLink";
import { getQRKey, getBaseURL } from "../helpers/URLHelper";
import NavLogo from "../components/NavLogo";
import { Link } from "react-router-dom";

export default function Terms () {

  useEffect( () => {
    document.title = 'Terms of Use | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "Learn the terms of use and legal restrictions for use of this website, including information on intellectual property, subscriptions, and more.";
    TrackingHelper.trackPage();
  }, [] );

  var styles = {
    header: {
      marginBottom: "15px"
    }
  };

  return(
    
    <div className="screen-wrap" >
      { getQRKey() ? 
        <NavLogo />
        :
        <NavBar />
      }
    

      <MainContent className="container">
        <BackLink title="Back"/>
        <h1 className="headline-text">Hallmark Terms of Use</h1>
        <div class="ugly_markup">
          <p>Effective Date: December 26, 2021</p>
          <p>IMPORTANT! PLEASE CAREFULLY READ THESE TERMS OF USE ("TERMS"), INCLUDING THE MANDATORY ARBITRATION PROVISION, WHICH REQUIRES THAT DISPUTES ARE RESOLVED BY FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL AND NOT A CLASS-WIDE OR CONSOLIDATED BASIS.</p>
          <p>These terms constitute a binding agreement between you and Hallmark Cards, Incorporated and its family of companies (“Hallmark,” “we” or “us”) governing your use of any website, mobile or other app, product, feature, or other service offered by us that posts a link to or otherwise provides these Terms (collectively, the “Hallmark Properties”). In addition, certain of the Hallmark Properties are governed by additional, service-specific terms (collectively, “Supplemental Terms”), including without limitation, Subscription Services Terms, Text Messaging Terms, and Purchase Terms. The Supplemental Terms are incorporated into these Terms by reference. To the extent there is a conflict between these Terms and the Supplemental Terms, the Supplemental Terms will control with respect to the applicable service(s).</p>
          <p>By accessing the Hallmark Properties and/or transacting with the Hallmark Properties, you agree to be bound by these Terms, and where applicable, the Supplemental Terms. Your use of the Hallmark Properties is also governed by our <Link className="term-privacy-link" to={getBaseURL("/privacy-policy")}>Privacy Policy</Link>. IF YOU DO NOT AGREE TO THESE TERMS, ANY APPLICABLE SUPPLEMENTAL TERMS, OR OUR PRIVACY POLICY, DO NOT ACCESS OR USE THE HALLMARK PROPERTIES.</p>
          <h3 class="section-title=">Contents</h3>
          <ul class="has-border-left gutter-left-medium">
          <li><a href="#section-changes-to-agreement" className="term-privacy-link">Changes to this Agreement</a></li>
          <li><a href="#section-changes-to-services" className="term-privacy-link">Changes to Services</a></li>
          <li><a href="#section-hallmark-content" className="term-privacy-link">Hallmark Content</a></li>
          <li><a href="#section-user-content" className="term-privacy-link">User Content</a></li>
          <li><a href="#section-intellectual-property-claims" className="term-privacy-link">Intellectual Property Claims</a></li>
          <li><a href="#section-user-conduct" className="term-privacy-link">User Conduct</a></li>
          <li><a href="#section-investigations" className="term-privacy-link">Investigations</a></li>
          <li><a href="#section-account-security" className="term-privacy-link">Security of Your Account</a></li>
          <li><a href="#section-subscriptions-trials" className="term-privacy-link">Subscriptions and Trial Offers</a></li>
          <li><a href="#section-equipment-requirements" className="term-privacy-link">Equipment Requirements and Fees</a></li>
          <li><a href="#section-purchase-terms" className="term-privacy-link">Purchase Terms</a></li>
          <li><a href="#section-sweepstakes-contests" className="term-privacy-link">Sweepstakes, Contests, and Promotions</a></li>
          <li><a href="#section-third-party-links" className="term-privacy-link">Third Party Links, Applications, and Content</a></li>
          <li><a href="#section-international-users" className="term-privacy-link">International Users</a></li>
          <li><a href="#section-indemnification" className="term-privacy-link">Indemnification</a></li>
          <li><a href="#section-suspension-and-termination" className="term-privacy-link">Suspension and Termination</a></li>
          <li><a href="#section-warranty-disclaimer" className="term-privacy-link">Disclaimer of Warranties and Conditions</a></li>
          <li><a href="#section-limitation-of-liability" className="term-privacy-link">Limitation of Liability</a></li>
          <li><a href="#section-binding-arbitration" className="term-privacy-link">BINDING ARBITRATION (“Arbitration Agreement”)</a></li>
          <li><a href="#section-exclusive-venue" className="term-privacy-link">Exclusive Venue</a></li>
          <li><a href="#section-governing-law" className="term-privacy-link">Governing Law</a></li>
          <li><a href="#section-mobile" className="term-privacy-link">Mobile</a></li>
          <li><a href="#section-app-stores" className="term-privacy-link">App Stores</a></li>
          <li><a href="#section-general-provisions" className="term-privacy-link">General Provisions</a></li>
          </ul>
          <section class="section" id="section-changes-to-agreement">
          <h3 class="section-title">Changes to This Agreement</h3>
          <p>To the extent allowed by applicable law, we reserve the right to change these Terms and the Supplemental Terms at any time without prior notice. When we make changes, the new Terms will be made available at the Hallmark Properties, and any new Supplemental Terms will be made available from within, or through, the affected services on the applicable Hallmark Properties. We may also provide notice to you in other ways, such as through contact information you have provided. Your continued use of any of the Hallmark Properties after the effective date of the revised Terms and/or Supplemental Terms as applicable (or engaging in such other conduct as we may reasonably specify) will constitute your consent to those changes to the fullest extent allowed by applicable law.</p>
          </section>
          <section class="section" id="section-changes-to-services">
          <h3 class="section-title">Changes to Services</h3>
          <p>Hallmark may modify, suspend, or discontinue any aspect of the products or services we offer through the Hallmark Properties (collectively “Services”), at any time for any reason, with or without notice to you, including the availability of any Hallmark Property, or any feature or Content (defined below) of a Hallmark Property.</p>
          </section>
          <section class="section" id="section-hallmark-content">
          <h3 class="section-title">Hallmark Content</h3>
          <p>Except with respect to User Content (defined in Section 3(c) below), Hallmark, its licensors, or suppliers own all rights, title, and interest in and to the intellectual property contained within the Hallmark Properties (collectively, “Hallmark Content”). The Hallmark Content is protected by United States and international laws. The Hallmark Content and the User Content are collectively referred to in these Terms as “Content.”</p>
          <p>You may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works from, distribute, perform, display, incorporate into another website, app, or other service; or in any way exploit any of the Hallmark Content, in whole or in part, except as set forth in these Terms, without the specific permission of Hallmark.</p>
          <p>Subject to your compliance with these Terms and any applicable Supplemental Terms, Hallmark grants to you a limited, revocable, non-exclusive, non-transferrable license to engage in the following activities for non-commercial purposes only:</p>
          <ul class="unordered-list gutter-bottom-large">
          <li>To browse, access, and view any of the Hallmark Properties and the Hallmark Content therein that is available to the public without registration; and, if you have registered or subscribed as required by the applicable Service, to browse, access, and view Hallmark Content available to such registered or subscribed viewers;.</li>
          <li>To use the Hallmark Properties, where applicable, to send personal, non-commercial messages to your family, friends, colleagues, and other individuals who you personally know..</li>
          <li>To link to the Hallmark Properties, provided, however, that your website or any third party websites that link to the Hallmark Properties: (a) must not frame or create a browser or border environment around any of Hallmark Content or otherwise mirror any part of the Hallmark Properties; (b) must not imply that Hallmark is endorsing or sponsoring it or its products or services, unless Hallmark has given it prior written consent; (c) must not present false information about, or disparage, tarnish, or otherwise, in Hallmark’s sole opinion, harm Hallmark or its Services; (d) must not use any Hallmark trademarks without the prior written permission from Hallmark; (e) must not contain content that could be construed as distasteful, offensive or controversial or otherwise objectionable (in Hallmark’s sole opinion); and (f) must be owned and controlled by you or the person or entity placing the link, or otherwise permit you to enable such link subject to these Terms. By linking to a Hallmark Property, you agree that you do and will continue to comply with the above linking requirements. Notwithstanding anything to the contrary contained in these Terms, Hallmark reserves the right to prohibit linking to the Hallmark Properties for any reason in our sole and absolute discretion even if the linking complies with the requirements described above.</li>
          </ul>
          <p>The foregoing license does not include any right to modify, publish, participate in the transfer or sale of, reproduction, or creation of derivative works from the Hallmark Content or the Hallmark Properties, except as and only to the extent expressly stated above.</p>
          </section>
          <section class="section" id="section-user-content">
          <h3 class="section-title">User Content</h3>
          <p>You and other users may have the ability to upload to, transmit through, or otherwise make available (collectively, “Upload”) through the Hallmark Properties personalized messages, audio, video, photographs, files, data, reviews, discussions in community forums, or other materials (collectively, “User Content”). You are solely and entirely responsible for User Content that you Upload using the Hallmark Properties. You acknowledge that other users of the Hallmark Properties, and not Hallmark, are similarly responsible for all User Content they Upload using the Hallmark Properties. You agree that you have no right, title, or interest in or to any Content that you have not Uploaded that appears on or through the Hallmark Properties.</p>
          <p>You acknowledge that Hallmark has no obligation to pre-screen, monitor, or investigate User Content, but that it reserves the right to do so.</p>
          <p>Subject to any applicable Account settings that we may choose to make available to you, and which you may then choose to select, you grant to Hallmark a fully paid, royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and fully sublicensable right (including any moral rights) and license to use, distribute, reproduce, modify, adapt, translate, create derivative works of, publicly perform, and publicly display your User Content, in whole or in part, together (at Hallmark’s option) with the names and likenesses of any persons identifiable therein, in any form, media, or technology now known or later developed, for the purposes of operating and providing the Hallmark Properties and their services to you and to other users. Notwithstanding the foregoing, with respect to User Content that is provided to Hallmark solely for the purpose of incorporating such User Content into a product or service ordered by you, you grant such right to Hallmark for the sole purpose of making and delivering products and services ordered by you.</p>
          <p>Except as otherwise described in our Privacy Policy, any User Content will be treated as non-confidential and non-proprietary and we will not be liable for any use or disclosure of User Content. You acknowledge and agree that your relationship with Hallmark is not a confidential, fiduciary, or other type of special relationship, and that your decision to submit any User Content does not impose any confidentiality obligations on Hallmark.</p>
          <p>It is our policy not to accept or consider content, information, ideas, suggestions or other materials other than those we have specifically requested, to which certain specific terms, conditions and requirements may apply. This is to avoid any misunderstandings if your ideas are similar to those we have developed or are developing independently. Accordingly, Hallmark does not accept unsolicited materials or ideas, and takes no responsibility for any materials or ideas so transmitted and you agree that any such ideas transmitted to Hallmark are included in the above license you grant to Hallmark for any User Content.</p>
          <p>You agree and understand that we are not obligated to post, keep or use your User Content.</p>
          <p>You represent and warrant that you are the owner of all rights, title, and interest in and to the User Content you Upload (or such User Content is in the public domain), or otherwise have all rights necessary to grant the license provided for in these Terms, including without limitation that any other holder of any worldwide intellectual property right, including moral rights (to the extent allowed by applicable law), in the User Content you Upload, has completely and effectively waived all such rights and validly and irrevocably granted to you the right to grant the license stated above. You further warrant that the User Content you Upload is accurate, does not violate these Terms, and will not cause injury to any person or entity.</p>
          <p>If you send an item (such as a card, ornament, or gift), whether such item is physical or digital, to another individual, you represent and warrant that you have the consent of the recipient to send that card or message. You acknowledge and agree that you, not Hallmark, are the sender of such card or message, and that we act merely as a delivery agent.</p>
          <ul class="unordered-list gutter-bottom-large">
          <li>Your User Content License to Hallmark</li>
          <li>No Confidential Relationship/No Unsolicited Materials</li>
          <li>Your Warranty to Hallmark</li>
          </ul>
          </section>
          <section class="section" id="section-intellectual-property-claims">
          <h3 class="section-title">Intellectual Property Claims</h3>
          <p>In accordance with the Digital Millennium Copyright Act of 1998 (the "DMCA"), Hallmark has a designated agent for receiving notices of copyright infringement, and we follow the notice and take down procedures of the DMCA. If you believe that your work has been copied in a way that constitutes copyright infringement, please provide our copyright agent the following information required by the Online Copyright Infringement Liability Limitation Act of the DMCA, 17 U.S.C. 512:</p>
          <ul class="unordered-list gutter-bottom-large">
          <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;</li>
          <li>An identification of the copyright work claimed to have been infringed, or, if multiple copyrighted works are covered by a single notification, a representative list of such works;</li>
          <li>An identification of the material that you claim is infringing or to be the subject of infringing activity, together with information reasonably sufficient for us to locate the material on the Hallmark Properties;</li>
          <li>Information reasonably sufficient to permit us to contact the complaining party;</li>
          <li>A written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law; and</li>
          <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
          </ul>
          <p>Please send this information to our Copyright Agent:</p>
          <p>Legal Division<br/>
          Hallmark Cards, Incorporated<br/>
          2501 McGee Trafficway MD 339<br/>
          Kansas City MO 64108<br/>
          trademark@hallmark.com</p>
          <p>If you believe that any content on the Hallmark Properties violates your exclusive rights other than copyrights, please provide us at least the following information: (a) your physical or electronic signature; (b) identification of the material that you claim is infringing your exclusive rights and information reasonably sufficient to permit us to locate the material; (c) an explanation of the exclusive rights that you own/have and why the you believe the content infringes those rights, sufficient for us to evaluate the complaint; and (d) accurate contact information for you. Please send your complaint to trademark@hallmark.com.</p>
          <p>It is often difficult to determine if your intellectual property rights have been violated or if the DMCA requirements have been met. We may request additional information before we remove any infringing material. If a dispute develops as to the correct owner of the rights in question, we reserve the right to remove your content along with that of the alleged infringer pending resolution of the matter.</p>
          </section>
          <section class="section" id="section-user-conduct">
          <h3 class="section-title">User Conduct</h3>
          <p>As a condition of your use of the Hallmark Properties, you agree not to use the Hallmark Properties for any purpose that is prohibited by these Terms, by applicable Supplemental Terms, by applicable law or in any other manner that we deem objectionable (in our sole discretion). You will not, and will not permit or assist any third party to, take any action or Upload any User Content on or through the Hallmark Properties that:</p>
          <ul class="unordered-list gutter-bottom-large">
          <li>Infringes on any copyright, trademark, patent, trade secret, right of publicity, right of privacy, or any other right of another person or entity;</li>
          <li>Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another’s privacy, tortious, obscene, offensive, profane, or vulgar;</li>
          <li>Promotes discrimination, bigotry, racism, hatred, harassment, or harm against any individual or group;</li>
          <li>Constitutes unauthorized or unsolicited advertising, junk mail, bulk mail, chain letters, or surveys;</li>
          <li>Discloses another person's address, phone number, e-mail address, credit card number, or any information that may be used to track, contact, or impersonate that individual or that is extremely personal in nature;</li>
          <li>Involves commercial activities and/or sales, such as contests, sweepstakes, or barter; communicating or facilitating any commercial advertisement or solicitation; marketing any goods or services; reselling, renting, leasing, or providing for payment the Services to any person;</li>
          <li>Impersonates any person or entity, including any employee or representative of Hallmark;</li>
          <li>Engages in or attempts to engage in any potentially harmful acts that are directed against the Hallmark Properties, including but not limited to violating or attempting to violate any security features of the Hallmark Properties; using manual or automated software or other means to scrape, crawl, spider, or similarly access any portion of the Hallmark Properties (except that we grant the operators of public search engines revocable permission to use spiders to copy materials from our websites for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials); introducing or sending viruses, worms, spyware, malware, or any other kind of harmful code into or through the Hallmark Properties; interfering or attempting to interfere with the proper functioning of or use by others of the Hallmark Properties, including by means of overloading, flooding, spamming, mail bombing, or crashing the Hallmark Properties;</li>
          <li>Engages in or attempts to engage in any of the following: disabling, bypassing, modifying, defeating, violating, removing, impairing, circumventing, or otherwise interfering with digital rights-management technology or other features or technology that limits or prevents use of the Hallmark Properties or the Content therein; and</li>
          <li>Automates the process of sending or viewing Content.</li>
          </ul>
          <p>You agree to not engage in the use, copying, or distribution of any of the Content other than expressly permitted in these Terms, including any use, copying, or distribution of User Content of third parties obtained through the Hallmark Properties for any commercial purposes. If you download or print a copy of the Content for personal use, you must retain all copyright and other proprietary notices contained therein.</p>
          <p>You agree that digital rights-management technology is an integral and inseparable part of any Hallmark Property that employs it. Tampering with or circumventing such digital rights-management technology or otherwise accessing or using the Hallmark Properties or Content in violation of these Terms and any applicable Supplemental Terms may constitute copyright infringement.</p>
          <p>YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH ANY OTHER USERS AND THIRD PARTIES WITH WHOM YOU INTERACT. Notwithstanding the foregoing, we reserve the right (but have no obligation) to intercede in any disputes that arise out of those interactions. You agree that Hallmark is not and will not be responsible for any liability incurred as the result of such interactions. We do not approve, endorse, or make any representations or warranties with respect to User Content. You use all User Content and interact with other users at your own risk.</p>
          <p>You acknowledge that if you post your User Content to a public area of the Hallmark Properties, or any third party site, including any information about your viewership of Hallmark Content, such as your viewership of movies via Hallmark Movies Now, you are choosing to do so, and the federal Video Privacy Protection Act of 1998 (18 U.S.C. § 2710), Connecticut General Statute § 53-450, Michigan Law § 445.1712, Maryland Code Article 27 § 583, and similarly worded state or other video privacy laws or regulations do not apply. However, to the extent that any of those laws or regulations do apply in any manner, you are providing your informed, written consent to the disclosure of that information pursuant to all applicable laws.</p>
          </section>
          <section class="section" id="section-investigations">
          <h3 class="section-title">Investigations</h3>
          <p>We reserve the right to investigate User Content, as well as violations of these Terms and any applicable Supplemental Terms, including by requesting evidence of your rights in and to the User Content. Hallmark reserves the right to refuse and/or remove any Content, and to suspend the shipping of any order or service relating to Content, for any reason it deems, in its sole discretion, to be appropriate. By using the Hallmark Properties, you irrevocably consent to such investigations and disclosures.</p>
          </section>
          <section class="section" id="section-account-security">
          <h3 class="section-title">Security of Your Account</h3>
          <p>You are responsible for the accuracy of the information included in your account, including updating your information as necessary, maintaining the confidentiality of your password. To the fullest extent allowed by law, all activity conducted in connection with your account will be your responsibility, as you are deemed to be in sole possession and control of the confidential password necessary to access your account. You must immediately notify Hallmark of any unauthorized access or tampering of your account, or suspected breach of security by contacting https://care.hallmark.com/s/contactsupport.</p>
          </section>
          <section class="section" id="section-subscriptions-trials">
          <h3 class="section-title">Subscriptions and Trial Offers</h3>
          <p>Hallmark subscriptions (including trial offers) are subject to the applicable Hallmark Subscription Service Terms, which are Supplemental Terms.</p>
          </section>
          <section class="section" id="section-equipment-requirements">
          <h3 class="section-title">Equipment Requirements and Fees</h3>
          <p>You must provide all equipment and software necessary to connect to the Hallmark Properties, including: (i) for any app we may offer (“App”): a mobile device or tablet that is suitable to connect with and use such App, and (ii) for the Hallmark Movies Now Services: a television, computer, Roku device, Apple TV, Amazon Fire TV, Android TV, a blu-ray player, Chromecast, or any other equipment that meets the technical requirements for our delivery system (which may require the use of plug-ins). You are solely responsible for any fees, including Internet connection or mobile fees, that you incur when accessing the Hallmark Properties.</p>
          </section>
          <section class="section" id="section-purchase-terms">
          <h3 class="section-title">Purchase Terms</h3>
          <p>Any purchase you make online through the Hallmark Properties are subject to the purchase terms or other applicable subscription service or other terms posted on the Hallmark Properties. Prices shown are for online purchases, and pricing in retail stores may vary.</p>
          </section>
          <section class="section" id="section-sweepstakes-contests">
          <h3 class="section-title">Sweepstakes, Contests, and Promotions</h3>
          <p>Any sweepstakes, contests or other promotions (any, a “Promotion”) that may be offered via the Hallmark Properties may be governed by a separate set of rules that may have eligibility requirements, such as certain age or geographic area restrictions, terms and conditions governing the Promotion, use of User Content, and disclosures about how your personal information may be used. It is your responsibility to read these rules to determine whether or not you want to and are eligible to participate, register and/or enter, and to determine the applicable terms and conditions of the Promotion. By participating in a Promotion, you will be subject to those official rules, and you agree to comply with and abide by such rules and the decisions of the identified sponsor(s).</p>
          </section>
          <section class="section" id="section-third-party-links">
          <h3 class="section-title">Third Party Links, Applications, and Content</h3>
          <p>There may be links from the Hallmark Properties, or communications you receive from the Hallmark Properties, to third party sites or properties. The Hallmark Properties may also include third party content that we do not control, maintain, or endorse. Accessing those third party sites requires you to leave the Hallmark Properties. In addition, to access certain Hallmark Properties, you may be required to use the sites and properties of a third party. We do not control those third party sites or any of the content contained therein and you expressly acknowledge and agree that we are in no way responsible or liable for any of those third party sites or properties, including, without limitation, their content, policies, failures, promotions, products, services, actions and/or any damages, losses, failures or problems caused by, related to or arising from those third parties or their sites. YOUR CORRESPONDENCE AND BUSINESS DEALINGS WITH OTHERS FOUND ON OR THROUGH A HALLMARK PROPERTY INCLUDING, WITHOUT LIMITATION, THE PAYMENT AND DELIVERY OF PRODUCTS AND SERVICES, AND ANY TERMS, CONDITIONS, WARRANTIES AND REPRESENTATIONS ASSOCIATED WITH ANY SUCH DEALINGS, ARE SOLELY BETWEEN YOU AND THE THIRD PARTY. HALLMARK ENCOURAGES YOU TO REVIEW ALL POLICIES, RULES, TERMS AND REGULATIONS, INCLUDING THE PRIVACY POLICIES AND TERMS OF USE OF EACH AND EVERY THIRD PARTY SITE THAT YOU VISIT.</p>
          </section>
          <section class="section" id="section-international-users">
          <h3 class="section-title">International Users</h3>
          <p>Some of the Hallmark Properties are accessible from countries around the world and may contain references to Services that are not available in your country. These references do not imply that we intend to announce such Services in your country. The Hallmark Properties are controlled and offered by us from its facilities in the United States. Hallmark makes no representations that the Hallmark Properties or our Services are appropriate or available for use in other countries. Those who access the Hallmark Properties or use our Services from other countries do so at their own volition and are responsible for compliance with local law.</p>
          </section>
          <section class="section" id="section-indemnification">
          <h3 class="section-title">Indemnification</h3>
          <p>To the fullest extent permitted by law, you agree to indemnify and hold Hallmark, its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (collectively, the "Hallmark Parties") harmless from any losses, costs, liabilities and expenses (including reasonable attorneys' fees) relating to or arising out of: (a) your User Content; (b) your use of, or inability to use, the Hallmark Properties or our Services; (c) your violation of these Terms or any applicable Supplemental Terms; (d) your violation of any rights of another party, including other users; or (e) your violation of any applicable laws, rules or regulations.</p>
          <p>We reserve the right, at our own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with us in asserting any available defenses.</p>
          <p>This provision does not require you to indemnify any of the Hallmark Parties for any unconscionable commercial practice by such party or for such party's fraud, deception, false promise, misrepresentation or concealment, suppression or omission of any material fact in connection with the Services provided under these Terms. You agree that the provisions in this Section 13 will survive any termination of your account, these Terms or any applicable Supplemental Terms, any Subscription Services, or your access to the Hallmark Properties.</p>
          </section>
          <section class="section" id="section-suspension-and-termination">
          <h3 class="section-title">Suspension and Termination</h3>
          <p>We reserve the right to suspend or terminate your access to all or some of our Hallmark Properties (including refusing to allow you to purchase digital or physical products or services), with or without notice, and with or without refund, if we, in our sole discretion, determine that you are in breach of these Terms or any applicable Supplemental Terms or have engaged in conduct that we, in our sole discretion, deem inappropriate.</p>
          <p>In the event of suspension or termination by Hallmark, we may delete or change your password(s) associated with any account you may have for Hallmark Properties; delete or change your account for such Hallmark Properties, including deleting your User Content, watchlist information, and/or viewing or transaction history; and bar your further use of such Hallmark Properties, as well as that of anyone sharing your email address, payment information, and/or physical address. You understand that such actions may be taken without any liability whatsoever to you for any suspension or termination, including for deletion of User Content.</p>
          <p>All provisions of these Terms and any applicable Supplemental Terms which by their nature should survive termination, will survive termination of your access to the Hallmark Properties, including without limitation, ownership provisions, warranty disclaimers, limitations of liability, and dispute resolution provisions.</p>
          <p>If your access to Hallmark Properties is terminated under these Terms, then you agree that you will not attempt to re-register with or access the Hallmark Properties through use of a different username or otherwise, and you acknowledge that you will not be entitled to receive a refund for fees related to those of our Hallmark Properties to which your access has been terminated. In the event that you violate the immediately preceding sentence, we reserve the right, in our sole discretion, to immediately take any or all of the actions set forth in these Terms without any notice or warning to you.</p>
          </section>
          <section class="section" id="section-warranty-disclaimer">
          <h3 class="section-title">Disclaimer of Warranties and Conditions</h3>
          <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF THE HALLMARK PROPERTIES AND OUR SERVICES IS AT YOUR SOLE RISK, AND THE HALLMARK PROPERTIES AND OUR SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITH ALL FAULTS. WE RESERVE THE RIGHT TO AMEND ERRORS AND UPDATE PRODUCT AND SERVICE INFORMATION AT ANY TIME WITHOUT PRIOR NOTICE. THE HALLMARK PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT ARISING FROM USE OF THE HALLMARK PROPERTIES OR OUR SERVICES.</p>
          <p>THE HALLMARK PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION THAT: OUR SERVICES WILL MEET YOUR REQUIREMENTS; YOUR USE OF OUR SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; THAT THE HALLMARK PROPERTIES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR THAT THE MATERIALS OF THE HALLMARK PROPERTIES ARE CORRECT, ACCURATE, OR RELIABLE.</p>
          <p>ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH OUR SERVICES IS ACCESSED AT YOUR OWN RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY, INCLUDING, BUT NOT LIMITED TO, YOUR COMPUTER SYSTEM AND ANY DEVICE YOU USE TO ACCESS THE HALLMARK PROPERTIES OR OUR SERVICES, OR ANY OTHER LOSS THAT RESULTS FROM ACCESSING SUCH CONTENT.</p>
          <p>OUR SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER DISRUPTIONS. THE HALLMARK PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION WITH RESPECT TO SERVICES, INCLUDING BUT NOT LIMITED TO, THE QUALITY, EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS OF SERVICES.</p>
          </section>
          <section class="section" id="section-limitation-of-liability">
          <h3 class="section-title">Limitations on Liability</h3>
          <ul class="unordered-list gutter-bottom-large">
          <li><strong>Disclaimer of Certain Damages.</strong></li>
          </ul>
          <p>YOU AGREE THAT IN NO EVENT WILL THE HALLMARK PARTIES BE LIABLE FOR ANY LOSS OF PROFITS, REVENUE OR DATA, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH OUR SERVICES, OR DAMAGES OR COSTS DUE TO LOSS OF PRODUCTION OR USE, PROCUREMENT OF SUBSTITUTE SERVICES, WHETHER OR NOT YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, THE HALLMARK PROPERTIES, OR OUR SERVICES, OR FROM ANY COMMUNICATIONS WITH OTHER USERS THEREOF, ON ANY THEORY OF LIABILITY, RESULTING FROM: (1) THE USE OR INABILITY TO USE THE HALLMARK PROPERTIES OR OUR SERVICES; (2) THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES RESULTING FROM ANY SERVICES PURCHASED OR OBTAINED THROUGH THE HALLMARK PROPERTIES; (3) CONDUCT OF ANY THIRD PARTY ON HALLMARK PROPERTIES; OR (4) ANY OTHER MATTER RELATED TO THE HALLMARK PROPERTIES OR OUR SERVICES, WHETHER BASED ON WARRANTY, COPYRIGHT, CONTRACT, OR ANY OTHER LEGAL THEORY. THE FOREGOING CAP ON LIABILITY WILL NOT APPLY TO LIABILITY OF A HALLMARK PARTY FOR ANY INJURY CAUSED BY A HALLMARK PARTY'S FRAUD OR FRAUDULENT MISREPRESENTATION.</p>
          <ul class="unordered-list gutter-bottom-large">
          <li><strong>Cap on Liability.</strong></li>
          </ul>
          <p>UNDER NO CIRCUMSTANCES WILL THE HALLMARK PARTIES BE LIABLE TO YOU FOR MORE THAN THE GREATER OF (A) THE TOTAL AMOUNT PAID TO HALLMARK BY YOU DURING THE ONE-MONTH PERIOD PRIOR TO THE ACT, OMISSION OR OCCURRENCE GIVING RISE TO SUCH LIABILITY OR (B) THE REMEDY OR PENALTY IMPOSED BY THE STATUTE UNDER WHICH SUCH CLAIM ARISES. THE FOREGOING CAP ON LIABILITY WILL NOT APPLY TO LIABILITY OF A HALLMARK PARTY FOR ANY INJURY CAUSED BY A HALLMARK PARTY'S FRAUD OR FRAUDULENT MISREPRESENTATION.</p>
          <ul class="unordered-list gutter-bottom-large">
          <li><strong>User Content.</strong></li>
          </ul>
          <p>EXCEPT FOR HALLMARK'S OBLIGATIONS TO PROTECT YOUR PERSONAL DATA AS SET FORTH IN THE HALLMARK PRIVACY POLICY, HALLMARK ASSUMES NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, MIS-DELIVERY, OR FAILURE TO STORE ANY CONTENT, USER CONTENT, USER COMMUNICATIONS, OR PERSONALIZATION SETTINGS.</p>
          <ul class="unordered-list gutter-bottom-large">
          <li><strong>Basis of the Bargain.</strong></li>
          </ul>
          <p>THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN HALLMARK AND YOU.</p>
          </section>
          <section class="section" id="section-binding-arbitration">
          <h3 class="section-title">BINDING ARBITRATION</h3>
          (“Arbitration Agreement”)
          <p>a. Applicability of Arbitration Agreement. You agree that any dispute or claim relating in any way to your access or use of the Hallmark Properties, to these Terms or any Supplemental Terms, or to any Services, or to any aspect of your relationship with Hallmark, will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims in small claims court if your claims qualify, so long as the matter remains in such court and advances only on an individual (non-class, non-representative) basis; and (2) you or Hallmark may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents). This Arbitration Agreement will apply, without limitation, to all claims that arose or were asserted before the effective date of these Terms or any prior version of this agreement.</p>
          <p>IF YOU AGREE TO ARBITRATION WITH HALLMARK, YOU ARE AGREEING IN ADVANCE THAT YOU WILL NOT PARTICIPATE IN OR SEEK TO RECOVER MONETARY OR OTHER RELIEF IN ANY LAWSUIT FILED AGAINST HALLMARK ALLEGING CLASS, COLLECTIVE, AND/OR REPRESENTATIVE CLAIMS ON YOUR BEHALF. INSTEAD, BY AGREEING TO ARBITRATION, YOU MAY BRING YOUR CLAIMS AGAINST THE HALLMARK IN AN INDIVIDUAL ARBITRATION PROCEEDING. IF SUCCESSFUL ON SUCH CLAIMS, YOU COULD BE AWARDED MONEY OR OTHER RELIEF BY AN ARBITRATOR. YOU ACKNOWLEDGE THAT YOU HAVE BEEN ADVISED THAT YOU MAY CONSULT WITH AN ATTORNEY IN DECIDING WHETHER TO ACCEPT THIS AGREEMENT, INCLUDING THIS ARBITRATION AGREEMENT.</p>
          <p>b. Arbitration Rules and Forum. The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement. To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to our registered agent, CT Corporation System, 818 West Seventh Street, Suite 930, Los Angeles, CA 90017. The arbitration will be conducted by JAMS, an established alternative dispute resolution provider. Disputes involving claims and counterclaims under $250,000, not inclusive of attorneys' fees and interest, will be subject to JAMS's most current version of the Streamlined Arbitration Rules and procedures available at http://www.jamsadr.com/rules-streamlined-arbitration/; all other claims will be subject to JAMS's most current version of the Comprehensive Arbitration Rules and Procedures, available at http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS's rules are also available at www.jamsadr.com or by calling JAMS at 800-352-5267. If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum. If the arbitrator finds that you cannot afford to pay JAMS's filing, administrative, hearing and/or other fees and cannot obtain a waiver from JAMS, Hallmark will pay them for you. In addition, Hallmark will reimburse all such JAMS's filing, administrative, hearing and/or other fees for claims totaling less than $10,000 unless the arbitrator determines the claims are frivolous. Likewise, Hallmark will not seek attorneys' fees and costs in arbitration unless the arbitrator determines the claims are frivolous. You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the country where you live or at another mutually agreed location. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.</p>
          <p>c. Authority of Arbitrator. The arbitrator, and not any federal, state or local court or agency will have exclusive authority to resolve any dispute related to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to, any claim that all or any part of this Arbitration Agreement is void or voidable. The arbitration will decide the rights and liabilities, if any, of you and Hallmark. The arbitration proceeding will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator will have the authority to grant motions dispositive of all or part of any claim. The arbitrator will have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum's rules, and these Terms (including the Arbitration Agreement). The arbitrator will issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The award of the arbitrator is final and binding upon you and us.</p>
          <p>d. Waiver of Jury Trial. YOU AND HALLMARK HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and Hallmark are instead electing that all claims and disputes will be resolved by arbitration under this Arbitration Agreement, except as specified in Section 17(a) above. An arbitrator can award on an individual basis the same damages and relief as a court and must follow this Agreement as a court would. However, there is no judge or jury in arbitration, and court review of an arbitration award is subject to very limited review.</p>
          <p>e. Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. In the event that this subparagraph is deemed invalid or unenforceable neither you nor we are entitled to arbitration and instead claims and disputes will be resolved in a court as set forth in Section 18.</p>
          <p>f. 30-Day Right to Opt Out. You have the right to opt out of the provisions of this Arbitration Agreement by sending written notice of your decision to opt out by contacting https://care.hallmark.com/s/contactsupport, within 30 days after first becoming subject to this Arbitration Agreement. Your notice must include your name and address, your Hallmark username (if any), the email address you used to set up your Hallmark account (if you have one), and an unequivocal statement that you want to opt out of this Arbitration Agreement. If you opt out of this Arbitration Agreement, all other parts of these Terms will continue to apply to you. Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may currently have, or may enter in the future, with us.</p>
          <p>g. Severability. If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable, then such specific part or parts will be of no force and effect and will be severed and the remainder of the Arbitration Agreement will continue in full force and effect.</p>
          <p>h. Survival of Agreement. This Arbitration Agreement will survive the termination of your relationship with Hallmark.</p>
          <p>i. Modification. Notwithstanding any provision in these Terms to the contrary, we agree that if Hallmark makes any future material change to this Arbitration Agreement, it will not apply to any individual claim(s) that you had already provided notice to Hallmark.</p>
          </section>
          <section class="section" id="section-exclusive-venue">
          <h3 class="section-title">Exclusive Venue</h3>
          <p>To the extent the parties are permitted to initiate litigation in a court, both you and Hallmark agree that all claims and disputes arising out of or related to these Terms or any Supplemental Terms will be litigated exclusively in the state or federal courts of Jackson County, Missouri.</p>
          </section>
          <section class="section" id="section-governing-law">
          <h3 class="section-title">Governing Law</h3>
          <p>These Terms, the Supplemental Terms, and any action related to them will be governed and interpreted by and under the laws of the State of Missouri, consistent with the Federal Arbitration Act, without giving effect to any principles that provide for the application of the law of another jurisdiction. The United Nations Convention on Contracts for the International Sale of Goods does not apply to these Terms or any Supplemental Terms.</p>
          </section>
          <section class="section" id="section-mobile">
          <h3 class="section-title">Mobile</h3>
          <p>The Hallmark Properties may offer features and services that are available to you via your mobile phone or other mobile device. These features and services may include, without limitation, the ability to upload content to the Hallmark Properties, receive messages from the Hallmark Properties (including, without limitation, push messages), download applications to your mobile phone or access Hallmark Properties features (collectively, the "Mobile Features"). We may charge for Mobile Features. Also, standard messaging, data and other fees may be charged by your carrier. Fees and charges will appear on your mobile bill or be deducted from your pre-paid balance. Your carrier may prohibit or restrict certain Mobile Features and certain Mobile Features may be incompatible with your carrier or mobile device. Contact your carrier with questions regarding these issues.</p>
          <p>If you have registered for Mobile Features that utilize your mobile number, you agree to notify Hallmark of any changes to or deactivation of your mobile number and update your account(s) on the Hallmark Properties to reflect this change.</p>
          </section>
          <section class="section" id="section-app-stores">
          <h3 class="section-title">App Stores</h3>
          <p><strong>General.</strong> You acknowledge and agree that the availability of any apps we may offer (“Apps”) are dependent on the third party from whom you received the App license (e.g., Apple App Store, Google Play, Roku, or Samsung Smart) ("App Store"). You acknowledge that these Terms are an agreement between you and us, and not with the App Store. We, not the App Store, are solely responsible for the Hallmark Properties and our Services, including the Apps, the content thereof, maintenance, support services, and warranty therefor, and addressing any claims relating thereto (e.g., product liability, legal compliance or intellectual property infringement). In order to use the Apps, you must have Internet access, including in some cases, wireless network services. You agree to pay all fees associated with such access. You also agree to pay all fees (if any) charged by the App Store in connection with the Hallmark Properties, including the Apps. You agree to comply with, and your license to use the Apps is conditioned upon your compliance with, all applicable third-party terms of agreement (e.g., the App Store's terms and policies) when using our Services, including the Apps. You acknowledge that the App Store (and its subsidiaries) are third-party beneficiaries of this Agreement and will have the right to enforce it.</p>
          <p><strong>Accessing and Downloading Apps from Apple.</strong> The following applies to any App accessed through or downloaded from the Apple App Store (an “Apple-Sourced App”):</p>
          <p>a. Acknowledgment. You acknowledge and agree that (i) these Terms are between you and Hallmark (or any third party developer that may own or operate the App) only, and not Apple, and (ii) Hallmark, not Apple, is solely responsible for the Apple Store Sourced App and content thereof.</p>
          <p>b. Scope of License. Your license to use the Apple Store Sourced App is non-transferable and limited to use on an Apple-branded product that you own or control, and as permitted by the “Usage Rules" set forth in the Apple Media Services Terms and Conditions.</p>
          <p>c. Maintenance and Support. You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Apple Store Sourced App.</p>
          <p>d. Warranty. In the event of any failure of the Apple Store Sourced App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the Apple Store Sourced App to you and to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Apple Store Sourced App. As between Hallmark and Apple, any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Hallmark.</p>
          <p>e. Product Claims. You and Hallmark acknowledge that, as between Hallmark and Apple, Apple is not responsible for addressing any claims you have or any claims of any third party relating to the Apple Store Sourced App or your possession and use of the Apple Store Sourced App, including, but not limited to: (i) product liability claims; (ii) any claim that the Apple Store Sourced App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.</p>
          <p>f. Intellectual Property Rights. You and Hallmark acknowledge that, in the event of any third-party claim that the Apple Store Sourced App or your possession and use of that Apple Store Sourced App infringes that third party's intellectual property rights, as between Hallmark and Apple, Hallmark, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by the Agreement.</p>
          <p>g. Legal Compliance. You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</p>
          <p>h. Third Party Terms. You must comply with any applicable third party terms of agreement when using the Apple Store Sourced App (e.g., your wireless data service agreement).</p>
          <p>i. Third Party Beneficiary. You and Hallmark acknowledge and agree that Apple, and Apple's subsidiaries, are third-party beneficiaries of the Agreement as related to your license of the Apple Store Sourced App, and that, upon your acceptance of the terms and conditions of the Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce the Agreement as related to your license of the Apple Store Sourced App against you as a third-party beneficiary thereof.</p>
          <p>j. Without limiting any other terms of the Agreement, you must comply with all applicable third-party terms of agreement when using the Apple Store Sourced App.</p>
          </section>
          <section class="section" id="section-general-provisions">
          <h3 class="section-title">General Provisions</h3>
          <ul class="unordered-list gutter-bottom-large">
          <li><strong>Electronic Communications.</strong> The communications between you and us use electronic means, whether you visit the Hallmark Properties or send us emails or other electronic communications, or whether we post notices on the Hallmark Properties or communicate with you via email or other electronic communications. You (1) consent to receive communications from us in an electronic form; and (2) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Hallmark provides to you electronically satisfy any legal requirement that such communications would satisfy if it were to be in writing. The foregoing does not affect your statutory rights.</li>
          <li><strong>Assignment.</strong> These Terms and any applicable Supplemental Terms, and your rights and obligations under each, may not be assigned, subcontracted, delegated or otherwise transferred by you without our prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. Hallmark may assign its rights and duties under these Terms or any Supplemental Terms to any party at any time without any notice to you.</li>
          <li><strong>Force Majeure.</strong> Hallmark will not be liable for any delay or failure to perform, including failure to deliver purchased Services, resulting from causes outside its reasonable control, including, but not limited to, acts of God, epidemic, pandemic, war, terrorism, riots, embargos, acts of civil or military authorities, fire, floods, accidents, strikes or shortages of transportation facilities, fuel, energy, labor, materials, or any other similar or dissimilar event or cause beyond the control of Hallmark.</li>
          <li><strong>Questions, Complaints, Claims.</strong> If you have any questions, complaints or claims with respect to the Hallmark Properties or our Services, please contact us at: https://care.hallmark.com/s/contactsupport.</li>
          <li><strong>Waiver.</strong> Any waiver or failure to enforce any provision of these Terms or any applicable Supplemental Terms on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.</li>
          <li><strong>Severability.</strong> If any portion of these Terms or any applicable Supplemental Terms is held invalid or unenforceable, that portion will be construed in a manner to reflect, as nearly as possible, the original intention of the parties, and the remaining portions will remain in full force and effect.</li>
          </ul>
          </section>
          </div>

          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>

      </MainContent>
      <Footer hideMobile={true} sticky={true}/>
    </div>
  );
}