var cacheStore = {};

class MemCache {

	getItem(key) {
		return cacheStore[key];
	}
	setItem(key,val) {
		cacheStore[key] = val;
	}
}


export default new MemCache()
