import ProgressUploader from "./ProgressUploader";

export default class UploadManager {

    constructor( fizzId, type, callacks ) {
        this.fizzId = fizzId;
        this.type = type;
    	this.callacks = callacks || {}

        this.uploadingList = [];
    }

    fireUpdate(){
    	if( this.callacks.onProgress ) {
	        this.callacks.onProgress( this.uploadingList );
    	}
    }

    startUpload = (files)=>{

	    for (var i = 0; i < files.length; i++) {
			let file = files[i];

			let uploader = new ProgressUploader(file, (uploader) => {
				// Progress
				console.log(uploader.getPercent());
				// Call set state to refresh
				this.fireUpdate();
			});
			uploader.key = Math.random();
			uploader.loadImage();

			this.uploadingList.push(uploader);
	    }
	    this.fireUpdate();

	    console.log("fireUpdate" , this.uploadingList.length)
			if( this.uploadingList.length>0 ) {
				this.startNext();
			}
    }


	startNext = ()=>{
		console.log("startNext")
		for (var i = 0; i < this.uploadingList.length; i++) {

			var uploader = this.uploadingList[i];
			if (uploader.status == "idle") {
				// Start one that is idle
				var started = uploader.start("POST", `/rest/fizz/${this.fizzId}/add-clip?type=${this.type}&size=${uploader.fileSizeMB}&isVideo=${uploader.isVideoParam}`);
				started
					.then(this.oneDone)
					.catch(()=>{
						this.oneDone(uploader);
					});
				break;
			}
		}
	}

	oneDone = (uploader) => {

		console.log("One DONE", uploader)

		if( this.callacks.onOneDone ){
			var shoudlRemoveThis = this.callacks.onOneDone( uploader );
			if( shoudlRemoveThis ) {
				console.log("SHOULD REMOVE", uploader, this.uploadingList, );
	            this.remove(uploader);
			}
		}



		var allDone = true;
		for (var i = 0; i < this.uploadingList.length; i++) {
		  if ( !this.uploadingList[i].isDone() ) {
		    allDone = false;
		    break;
		  }
		}

	    this.fireUpdate();

		if( !allDone ) {
		  this.startNext();
		} else {
		  this.allDone();
		}
	}

	allDone = ()=>{

		var hasErrors = false;
		for (var i = 0; i < this.uploadingList.length; i++) {
		  if ( this.uploadingList[i].hasError() ) {
		    hasErrors = true;
		    break;
		  }
		}
		if( !hasErrors ) {
			this.uploadingList = [];
	    	this.fireUpdate();
		}
		if( this.callacks.onAllDone ){
			this.callacks.onAllDone(hasErrors);
		}

	}

	remove = (uploader)=>{

		for (var j = 0; j < this.uploadingList.length; j++) {
			let upload = this.uploadingList[j]
			if ( uploader === upload || uploader.key==upload.key){
				this.uploadingList.splice( j, 1 )
				this.fireUpdate()
			}
        }

	}


}
