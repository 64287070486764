export default function LocalStorageHelper( fallback ) {

	return {

		setItem: ( key, val )=>{

      if( localStorage && localStorage.setItem ){
        return localStorage.setItem( key, val )
      } else if ( fallback ){
        return fallback.setItem( key, val );
      } 

      return null;

    },

		getItem: ( key )=>{

      if( localStorage && localStorage.getItem ){
        return localStorage.getItem( key  );
      } else if ( fallback ) {
        return fallback.getItem( key );
      }

      return null;

		}
	}
}

