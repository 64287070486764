import React, { useState, useEffect } from "react";
import NavBar, { onToggle } from "./NavBar";

export default ( props ) => {

  var [ show, setShow ] = useState(true)

  onToggle( (isNavOpen)=>{
    console.log("On Toggle isNavOpen:",isNavOpen)
    setShow(!isNavOpen)
  })


  return(
    <main className={props.className} style={{display:(show?'block':'none')}} >
      { props.children }
    </main>
  );
}
