import React, { Component } from "react";
import { apiFetch } from "../helpers/APIHelper";
import IdObjectMemCache from "../helpers/IdObjectMemCache";
import NavBar from "../components/NavBar";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import {CardElement, injectStripe, Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from '../components/CheckoutForm';
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import { isLoggedIn } from "../helpers/APIHelper";
import { getBaseURL } from "../helpers/URLHelper";


export class Checkout extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
          price: 0,
          name: "",
          fizzId: props.match.params.fizzId,
          isLoading: true,
          errorMessage: '',
          payError: '',
          payStatus: null,
          stripeAPIKey: null,
          img:null,
          paymentProcessing: false,
          isGuest: false,
          guestEmail: '',
        };
      }

      componentWillMount() {
        this._getData();
      }

      componentDidMount() {
        if (this.props.location.state) {
          const isGuestCheckout = this.props.location.state.isGuest;
          if (!isGuestCheckout && !isLoggedIn()) {
            window.location = getBaseURL("/login/" + this.state.fizzId);
            return;
          }
          this.setState({
            isGuest: isGuestCheckout
          });
        }
      }

      _getData() {

        var fizzCache = IdObjectMemCache('fizz').getItem(this.state.fizzId);
        console.log( "cached fizz",fizzCache );
        this.setState({
          img:fizzCache.img 
        });
        

        apiFetch(
          "/rest/fizz/" + this.state.fizzId + "/checkout",
          {
            method: "GET",
          },
          (json) => {
            console.log(json);
            IdObjectMemCache('fizz').setItem({
              id:this.state.fizzId,
              name:json.name,
              price:json.price,
              img:json.img
            });

            this.setState({
              price: json.price,
              name: json.name,
              img:json.img,
              isLoading: false,
              stripeAPIKey: json.publicKey,
              guestEmail:json.email
            });

            document.title = 'Checkout | Video Greeting Cards | Hallmark';
            document.getElementsByTagName( 'meta' )[3].content = "";
            TrackingHelper.trackPage();
          },
          (error) => {
            console.log(error);
            this.setState({
              errorMessage: error.message,
            });

            document.title = 'Checkout | Video Greeting Cards | Hallmark';
            document.getElementsByTagName( 'meta' )[3].content = "";
            TrackingHelper.trackPage();
          }
        );
      };

    handleResult(stripeResponse) {

        this.setState( { paymentProcessing: true } );

        if( stripeResponse.error ){ // Problem!

            this.setState({
                payError:stripeResponse.error.message,
                payStatus:null,
                paymentProcessing: false
            });

        } else { // Token was created!

            this.setState( { payError:null } );

            console.log( "Token", stripeResponse.token.id );

            // Get the token ID:
            var token = stripeResponse.token.id;


            // Submit the form:
            apiFetch(`/rest/payments/fizz/${this.state.fizzId}/pay`, {
                method:'POST',
                data: {
                  token:token,
                  price: this.state.price,
                  guestEmail: this.state.guestEmail || ""
                }
            },
            (json) => {
              
              // SUCCESS
              window.location = "/thank-you-digital/"+this.state.fizzId

            },
            (error) => {
              console.log(`Error::`, error);
              this.setState({
                payError:error.message || (error+""),
                payStatus:null,
                paymentProcessing: false
              })
            });



        }
    }

    handleEmailChange = (e) => {
      const email = e.target.value;
      this.setState(prevState => ({
        ...prevState,
        guestEmail: email
      }));
    }

        
    render(){

        if( this.state.isLoading ){
          return(
            <div className="screen-wrap">
              <NavBar/>
              <MainContent className="container bg-margin">
                <Spinner float={true}/>
              </MainContent>
              <Footer hideMobile={true} />
            </div>
          )
        }

      return(
        <div className="screen-wrap">
          <NavBar />
          <MainContent className="container bg-margin">
            <h1 className="headline-text">
              {this.state.isGuest ? "Guest Checkout" : "Checkout"}
            </h1>
              
            <div className="col-md-12 text-left">

              <div className="row mt-3 align-items-center justify-content-center">
                <div className="col-lg-12">
                  <div className="row align-items-center heading_video pl-1">
                    <div className="col-lg-10 col-8">
                      <p>{this.state.name}</p>
                    </div>
                    <div className="col-lg-2 col-4 text-right">
                      {this.state.img &&
                        <img
                          style={{ width: "100%" }}
                          src={this.state.img}
                          alt="img"
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
              
                <div className="col-md-6 order-md-2">
                  <h5 className="headline-text">Order summary</h5> 
                  <div className="row mt-3">
                    <div className="col-6 col-lg-8 text-left">
                      <p className="pt-2">{this.state.name}</p>
                    </div>
                    <div className="col-6 col-lg-4 text-right">
                      <p className="pt-2">{ this.state.price }</p>
                    </div>
                  </div>
                  <hr className="mt-0"/>
                  <div className="row mt-0 mb-3">
                    <div className="col-6 text-left">
                      <p>Total</p>
                    </div>
                    <div className="col-6 text-right">
                      <p>{ this.state.price }</p>
                    </div>
                  </div>
                </div>

                {this.state.stripeAPIKey &&
                  <div className="col-md-6 md-right-border">
                    <h5 className="headline-text mb-3">Payment information</h5>
                    <div>
                      <span className="has-float-label">
                        <label for="guestEmail">Your Email</label>
                        <input 
                          className="form-control" 
                          type="text" 
                          id="guestEmail" 
                          name="guestEmail"
                          onChange={this.handleEmailChange}
                          value={this.state.guestEmail}
                        />
                      </span>
                      <small>Your receipt and finished card will be sent to your email address. Please ensure all spelling is correct.</small>
                    </div>
                    <StripeProvider apiKey={this.state.stripeAPIKey}>
                      <Elements>
                        <div>
                          <CheckoutForm 
                            handleResult={this.handleResult.bind(this)} 
                            disabled={this.state.payStatus} 
                            shouldRequestToken={true} 
                            />
                          {this.state.payError && <p className="alert alert-danger">{this.state.payError}</p> }
                          {this.state.paymentProcessing && <Spinner float={true} />}
                        </div>
                      </Elements>
                    </StripeProvider>
                  </div>
                }


              </div>

            </div>
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }
}


export default Checkout;
