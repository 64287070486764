import React, { Component, StyleSheet } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { apiFetch } from "../helpers/APIHelper";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import StandardModal from "../modal/StandardModal";
import Spinner from "../components/Spinner";
import MainContent from "../components/MainContent";

import Plyr from "react-plyr";
import BackLink from "../components/BackLink";
import RecordIcon from "../assets/images/HMK-Icons/Icons_Record_Bold.svg";
import TrackingHelper from "../helpers/TrackingHelper";

export default class ThemeDetail extends Component {


  constructor(props) {
    super(props);

    this.state = {
      previewVideo: null, // Popup

      animations: [],
      themeName: "",
      themes: [],
      animationsHeader: null,
      animationsFooter1: null,
      animationsFooter2: null,
      backToPage:"",
      offer: null,
    };

    this.parseParams(props);
  }

  

  showPreview(ta) {
    this.setState({
      previewTA: ta,
    });

    TrackingHelper.trackPreview();
    
  }
  createVideo(animation) {
    window.location = "/create/"+ animation.id + '?' +
                    (this.state.pt ? "pt=" + this.state.pt : "") +
                    (this.state.accessKey ? "&key=" + this.state.accessKey : "") 

  }

  componentWillMount() {
    this.getData();
  }
  componentWillReceiveProps(nextProps) {
    this.parseParams(nextProps);
    this.getData();
  }


  parseParams(props) {
    console.log(props);
    const params = new URLSearchParams(props.location.search);
    console.log(params);
    this.state.themeId = props.match.params.themeId;
    this.state.pt = params.get("pt");
    this.state.backToPage = params.get("back");
    this.state.accessKey = params.get('key');
  }

  
  getData = () => {
    this.setState({
      loading: true,
    });
    apiFetch(
      "/rest/theme/" + this.state.themeId + "/theme-animation-v2?key=" + this.state.accessKey+"&cache=" + (Math.random()*99999999),
      {
        method: "GET",
      },
      (json) => {
        this.setState({
          loading: false,
          animations: json.animations,
          themes: json.themes,
          animationsHeader: json.animationsHeader,
          animationsFooter1: json.animationsFooter1,
          animationsFooter2: json.animationsFooter2,
          themeName: json.name,
          offer: json.offer,
        });
        document.title = json.seoTitle;
        document.getElementsByTagName( 'meta' )[3].content = json.seoDescription;
        TrackingHelper.trackPage();
      },
      (error) => {
        console.log(error);
        this.setState({
          loading: false,
          error: error.message + ""
        })
        TrackingHelper.trackPage();
      }
    );
  };



  render() {
    
    const styles = {
      icons: {
        width: "80%",
        margin: "8%",
      },
      themeImg: {
        width: "100%",
        // borderRadius: "10px",
        // boxShadow:
        //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      },
      themeImgWithLabel: {
        width: "100%",
        // borderRadius: "10px",
        // boxShadow:
        //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      },
      label: {
        backgroundColor: "white",
        width: "100%",
        padding: "5px 0 5px 10px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      },
    };

    var bgColor =
      this.state.themes && this.state.themes.length > 0 ? "#55d209de" : "#fff";
    
    var backLink = '/browse';
  
    if(this.state.backToPage=="landing") { 
      backLink = '/';
    }
    
    return (
      <div className="screen-wrap">
        <NavBar title="Select The Intro" />
        { this.state.offer && 
            <div className="text-center p-4" style={{ backgroundColor: '#61429A', width: '100%' }}>
              <div className="landing_title" style={{ color: 'white', fontSize: '22px' }} >You have <span style={{ fontWeight: 'bold', fontSize:'35px'}}>{this.state.offer.offerExpiresInDays}</span> to start your free Video Greeting</div>
            </div>
          
          }
        <MainContent className="container">

          <BackLink
            to={backLink }
            title="See all Video Greetings"
          />

          { this.state.loading &&
            <Spinner float={true} />
          }

          <h1 className="headline-text">{this.state.themeName}</h1>

          {this.state.error ? (
                <p className="alert alert-danger">{this.state.error}</p>
             ) : (
               <>
          <div className="row" style={{ backgroundColor: bgColor }}>
            <div className="col-md-12 text-center">
              {this.state.animationsHeader && (
                <h4 className="pt-4 theme_name" style={{ color: "#fff" }}>
                  {this.state.animationsHeader}
                </h4>
              )}

              <div className="row">
                {this.state.animations.map((animation, index) => {
                  var showBox = animation.name;
                  let createLink =
                    "/create/" +
                    animation.id + '?' +
                    (this.state.pt ? "pt=" + this.state.pt : "") +
                    (this.state.accessKey ? "&key=" + this.state.accessKey : "");
                  return (
                    <div
                      key={"ta-" + animation.id}
                      className="col-lg-4 col-12 text-left"
                    >
                      <div
                        style={{
                          ...styles.label,
                          visibility: showBox ? "initial" : "hidden",
                        }}
                        className="theme_name"
                      >
                        {animation.name} &nbsp;
                      </div>
                      <button
                        // to={createLink}
                        onClick={this.showPreview.bind(this, animation)}
                        className="btn btn-link p-0 video-thumb notSelectable"
                      >

                        { animation.overlay  &&
                          <img className="theme-animation-overlay" src={animation.overlay} alt='overlay'/>
                        }
                        <img
                          src={animation.image}
                          style={
                            showBox ? styles.themeImgWithLabel : styles.themeImg
                          }
                        />
                      </button>
                      <div className="action-wrapper w-100 d-flex justify-content-between align-items-baseline">
                        <Link to={createLink} className="mob_buttonIcon">
                          <i className="icon-mask" style={{ WebkitMaskImage:"url("+RecordIcon+")" }} alt="RecordIcon"></i>  
                          Personalize 
                        </Link>
                        { animation.oldPrice && 
                          <div className="greeting-price d-flex align-items-start strikethrough">
                            {animation.oldPrice}
                          </div>
                        }
                        { animation.price && 
                          <div className="greeting-price d-flex align-items-start">
                            {animation.price}
                          </div>
                        }
                      </div>
                    </div>
                  );
                })}
              </div>
              {this.state.animationsFooter1 && (
                <h5 className="pb-1" style={{ color: "#fff" }}>
                  {this.state.animationsFooter1}
                </h5>
              )}
              {this.state.animationsFooter2 && (
                <h5 className="pb-1" style={{ color: "#fff" }}>
                  {this.state.animationsFooter2}
                </h5>
              )}
            </div>
          </div>

          {this.state.themes && (
            <div className="row bg-white bg-margin ">
              {this.state.themes.map((themeList, index) => {
                return themeList.list.map((theme, index) => {
                  return (
                    <div
                      key={"t-" + theme.themeId}
                      className="col-sm-3 mt-2 mb-3 p-4 text-center"
                    >
                      <Link
                        to={
                          "/theme/" +
                          theme.themeId + '?' +
                          (theme.pt ? "pt=" + theme.pt : "") +
                          (this.state.accessKey ? "&key=" + this.state.accessKey : "")
                        }
                      >
                        <div className="text-center iconBoxBack">
                          <div
                            className="text-center iconBox"
                            style={{ backgroundColor: theme.color + "de" }}
                          >
                            <img
                              src={theme.img}
                              style={styles.icons}
                              style={styles.themeImg}
                            />
                          </div>
                        </div>
                        <h5 className="theme_name">{theme.name}</h5>
                      </Link>
                    </div>
                  );
                });
              })}
            </div>
          )}
           </>
          )}
          



          <StandardModal
            size='lg'
            show={this.state.previewTA}
            onHide={this.showPreview.bind(this, null)}
            confirmBtnText="Personalize This Video"
            onConfirm={ this.createVideo.bind(this,this.state.previewTA) } 
            closeBtnText={false} >
              <Plyr
                type="video" // or "vimeo"
                url={this.state.previewTA ? this.state.previewTA.video : null}
                autoplay
                playsInline={true}
                options={{playsInline:true}}
              />
          </StandardModal>




        </MainContent>

        <Footer hideMobile={true} />
      </div>
    );
  }
}
