import React, { Component, StyleSheet } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { apiFetch } from "../helpers/APIHelper";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import StandardModal from "../modal/StandardModal";
import Carousel from "react-multi-carousel";
import "../assets/css/LandingPage.css";
import "react-multi-carousel/lib/styles.css";
import Plyr from "react-plyr";
import CaretRightIcon from "../assets/images/HMK-Icons/Icons_Arrows_Caret_Right_Bold.svg";
import RecordIcon from "../assets/images/HMK-Icons/Icons_Record_Bold.svg";
import TrackingHelper from "../helpers/TrackingHelper";
import { getBaseURL } from "../helpers/URLHelper";
import shareInstantImage from "../assets/images/share-instantly.svg";

const responsive = {
  lg_xl: {
    breakpoint: { max:99999, min: 992 },
    items: 3,
  },
  md: {
    breakpoint: { max: 992, min: 768 },
    items: 2,
  },
  sm_xs: {
    // breakpoint: { max: 576, min: 0 },
    // items: 1,
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.

  },
};

export class LandingAssortment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      themes: [],
      previewVideo: null,
      error:null,
      themeAnimationId: this.props.themeAnimationId
    };
  }

  showPreview(ta) {
    this.setState({
      selectedTA: ta,
      previewVideo: ta && ta.video,
    });
    TrackingHelper.trackPreview();
  }

  componentWillMount() {
    this.getData();
  }
  componentWillReceiveProps(nextProps) {
  }

  getData = () => {
    this.setState({
      loading: true,
    });
    var ta = this.props.themeAnimationId ? this.props.themeAnimationId : ""

    apiFetch(
      "/rest/project-landing?taId=" + ta,
      {
        method: "GET",
      },
      (json) => {
        if (json && json.status === "success") {
          console.log( json );
          this.setState({
            themes: json.rows,
          });
        }
      },
      (error) => {
        console.log(error);
        // ok to not display error Eric and JD.
      }
    );
  };

  createVideo = ()=>{
    window.location = getBaseURL( '/create/'+this.state.selectedTA.id );
  }

  render() {

    let isPhone = true;

    return (
      <div>
        { this.props.customPage &&
          <div className="p-2 text-center custom-assortment-banner" >
            <section className="container" >
              <img style= {{maxWidth: '100%'}} src={shareInstantImage} alt="Share Instantly" />
            </section>
          </div>
        }
        <section>
          <div className="container mt-3 mb-3" style={{overflow:'hidden'}}>
            {this.state.themes.length > 0 && (
              <div>

                <div className="text-left">
                  <h4 className="landing_title" >{ this.props.customPage ? 'Choose your Video Greeting' : 'Shop Video Greetings'}</h4>
                </div>


                {this.state.themes.map(
                  (theme, index) =>
                    theme.list.length > 0 && (
                      <div className="mt-4" key={`${theme.name}-${index}`}>
                        <div className="theme_heading">
                          <div className="title">
                            <h5 className="landing_title">{theme.name}</h5>
                          </div>
                          <div className="View">
                          <p className="back_btn">
                            <Link to={`/theme/${theme.id}?back=landing`}>
                                See all
			                          <i className="icon-mask" style={{ WebkitMaskImage:"url("+CaretRightIcon+")", height:"14px", width:"14px", marginBottom:"-2px", marginLeft:"2px" }} alt="View Icon"></i>
                            </Link>
                            </p>
                          </div>
                        </div>


                        <div className="row ta-row">
                          {theme.list.slice(0, 4).map((item, index) => {

                            console.log("item check" + item);
                            return (
                              <div
                                className={`col-6 col-lg-3 video-count-${index}`}
                                key={`animation-${item.id}`}
                              >
                                <div className="notSelectable">
                                  <button
                                    onClick={this.showPreview.bind(
                                      this,
                                      item
                                    )}
                                    className="btn btn-img video-thumb notSelectable"
                                  >
                                    { item.overlay &&
                                      <img className="theme-animation-overlay" src={item.overlay} alt='overlay'/>
                                    }
                                    <img src={item.img} alt="video" />
                                  </button>
                                  <div className="action-wrapper w-100 d-flex justify-content-between align-items-baseline">
                                    {<Link to={getBaseURL( `/create/${item.id}` )} className="mob_buttonIcon">
                                      <i className="icon-mask" style={{ WebkitMaskImage:"url("+RecordIcon+")" }} alt="RecordIcon"></i>                      
                                      Personalize
                                    </Link>}
                                    { item.oldPrice && 
                                      <div className="greeting-price d-flex align-items-start strikethrough">
                                        { item.oldPrice }
                                      </div>
                                    }
                                    { item.price && 
                                      <div className={'greeting-price d-flex align-items-start' + ( this.props.customPage ? " strikethrough" : "" ) }>
                                        { item.price }
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>




                      </div>
                    )
                )}
              </div>
            )}
          </div>
        </section>



        <StandardModal
          size='lg'
          show={this.state.previewVideo}
          onHide={this.showPreview.bind(this, null)}
          confirmBtnText="Personalize this video"
          onConfirm={this.createVideo} 
          closeBtnText={false} >
          
            <Plyr
              type="video" // or "vimeo"
              url={this.state.previewVideo}
              autoplay
              options={{playsinline:true}}
            />

        </StandardModal>


      </div>
    );
  }
}

export default LandingAssortment;
