import React, { Component } from "react";
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import { apiFetch, generateDeviceId } from "../helpers/APIHelper";
import { Link } from "react-router-dom";

import BannerBar from "../components/BannerBar";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import { getQRKey } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";


var EMAIL_REGEX = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export default class ViewInviteContact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      errors: {},
      currentUserEmail: null
    }
  }

  componentDidMount(){

    document.title = 'Thank you for Contributing | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "All set. Your photo or video has made its way to the group surprise—thanks for being part of a very special moment.";
    TrackingHelper.trackPage();
  }


  handleChange = (event) => {

    var value = event.target.value;
    if( event.target.type=='checkbox' ){
      value = event.target.checked;
    }
    this.setState({
      [event.target.name]: event.target.value,
      errors:{}
    });

  };

  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({ 
      loginErrorMessage: null,
    });

    if (this.validate()) {
      this.setState({ 
        isSubmittingEmail: true
      });

      var data = {
        qrKey: getQRKey(),
        version: "web",
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        deviceId: generateDeviceId()
      };

      apiFetch(
        "/rest/user",
        {
          method: "PUT",
          data: data,
        },
        (json) => {
          this.setState({
            isSubmittingEmail: false
          });

          console.log( 'SUCCESS!!!!');
    
          // SUCCESS  -  redirect to finish register, login, or user already has/had a free offer
          if( json.afterUpdate == 'login' ){
            window.location = "/view-invite-trial-login";
          } else if ( json.afterUpdate == 'register' ){
            window.location = "/view-invite-trial-register";
          } else  {
            window.location = "/view-invite-thank-you";
          }

        },
        (error) => {
          console.log("Error::", error);
          this.setState({
            loginErrorMessage: error ? error.message : error + "",
            isSubmittingEmail: false
          });
        }
      );
    } 
  };


  validateFirstName = () =>{
    if (!this.state.firstName) {
      const errors = {firstName : "Please enter your first name."};
      this.setState(() => ({errors}));
    }
    else{
      this.setState(() => ({errors: {}}));
    }
  }

  validateLastName = () =>{
    if (!this.state.lastName) {
      const errors = {lastName : "Please enter your last name."};
      this.setState(() => ({errors}));
    }
    else{
      this.setState(() => ({errors: {}}));
    }
  }

  validateEmail = () =>{

    if (!this.state.email) {
      const errors = {email : "Please enter your email address."};
      this.setState(() => ({errors}));
    }
    else if(!EMAIL_REGEX.test(this.state.email)){
        const errors = {email : "Please enter valid email address."};
        this.setState(() => ({errors}));
      }
    else{
      this.setState(() => ({errors: {}}));
    }
  }



  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!this.state.firstName) {
      isValid = false;
      errors.firstName = "Please enter your first name.";
    }

    if (!this.state.lastName) {
      isValid = false;
      errors.lastName = "Please enter your last name.";
    }

    if (!this.state.email) {
      isValid = false;
      errors.email = "Please enter your email address.";
    }

    if (typeof this.state.email !== "undefined") {
      if (!EMAIL_REGEX.test(this.state.email)) {
        isValid = false;
        errors.email = "Please enter valid email address.";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }




  render() {

    if( this.state.loading || this.state.isSubmittingEmail ) {
      return (
        <div className="screen-wrap" >
          <NavLogo />
          <BannerBar />
          <MainContent className="container">
            <Spinner float={true} />
          </MainContent>
          <div className="simple-footer">
            <Footer hideMobile={false} />
          </div>
        </div>
      );
    }


    return (
      <div className="screen-wrap" >
        <NavLogo />
        <BannerBar />
        <MainContent className="container pb-5 pt-3">


        { this.state.loadingError ? (

          <p className="alert alert-danger">{this.state.error}</p>

        ) : (

          <form className="sign-form" onSubmit={this.handleSubmit}>
            <h1 className="headline-text">Success!</h1>
            <h5 className="subheadline">Your upload is done.  To see the finished project enter your email and join the private list of people who have contributed to this card.</h5>


            <div className="row dflex">
              <div className="col-md-6">
                <div className={`form-group ${this.state.errors.firstName ? "invalid": ""}`}>
                  <span className="has-float-label">
                    <label for="firstName"> First name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="firstName"
                      value={this.state.firstName}
                      onBlur ={this.validateFirstName}
                      onChange={this.handleChange}
                      id="firstName"
                      maxLength={15}
                    />
                  </span>
                  <small className="text-danger">
                    {this.state.errors.firstName}
                  </small>
                </div>
              </div>
              <div className="col-md-6">
                <div className={`form-group ${this.state.errors.lastName ? "invalid": ""}`}>
                  <span className="has-float-label">
                    <label for="lastName">Last name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="lastName"
                      value={this.state.lastName}
                      onBlur ={this.validateLastName}
                      onChange={this.handleChange}
                      id="lastName"
                      maxLength={20}
                    />
                  </span>
                  <small className="text-danger">
                    {this.state.errors.lastname}
                  </small>
                </div>
              </div>
            </div>

            <div className={`form-group ${this.state.errors.email ? "invalid": ""}`}>
              <span className="has-float-label">
                <label for="email">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={this.state.email}
                  onBlur ={this.validateEmail}
                  onChange={this.handleChange}
                  id="email"
                  required
                  maxLength={180}
                />
              </span>
              <small className="text-danger">
                {this.state.errors.email}
              </small>
            </div>


            <div className="row pt-3 align-items-center justify-content-center ">
              <div className="col-lg-8 col-xl-6 text-center ">
                <button
                  type="submit"
                  className="text-uppercase btn btn-primary btn-block mt-4"
                  disabled={
                    this.state.firstName.length < 1 ||
                    this.state.lastName.length < 1 ||
                    this.state.email.length < 1 ||
                    this.state.isSubmittingEmail
                  }
                >
                  Join List
                </button>

                <div className="mt-4">
                  <Link to={"/view-invite-trial-register" } className="blue-link" >SKIP</Link>
                </div>
              </div>
            </div>

          </form>

        )}

        </MainContent>

        <div className="simple-footer">
          <Footer hideMobile={false} />
        </div>
      </div>
    );
  }
}
