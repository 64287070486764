import { Component } from 'react';
import DonateBanner from "../components/DonateBanner";

function ViewPageBanner( props ) {

  switch(props.bannerType) {
    case "komen":
      return  <DonateBanner />
    default:
      return null
  }
}

export default ViewPageBanner;
