import React, { Component } from "react";
import { apiFetch } from "../helpers/APIHelper";
import MemCache from "../helpers/MemCache";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MailIcon from "../assets/images/HMK-Icons/Icons_Mail_Regular.svg";
import SMSIcon from "../assets/images/HMK-Icons/Icons_Share_Share_iOS_Bold.svg";
import ShareIcon from "../assets/images/HMK-Icons/Icons_External_Link_Bold.svg";
import DownloadIcon from "../assets/images/HMK-Icons/Icons_Download_File_Regular.svg";
import { getQRKey } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import { getMailToLink, getSMSLink, isMobile, canShare } from "../helpers/URLHelper";
import { toastManager } from "../components/Toaster";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
import BackLink from "../components/BackLink";
import { Button } from "react-bootstrap";
import Plyr from "react-plyr";
import Share from "../assets/images/HMK-Icons/Icons_Share_Share_iOS_Bold.svg";
import QRBoxLinkImg from "../assets/images/qr-box-link.jpg";
import SharePopup from "../modal/SharePopup";
import { share } from "../helpers/URLHelper";

export class QRBoxThankYou extends Component {
    constructor(props) {
        super(props);

        this.state = {
          price: 0,
          hasPaid: false,
          name: "",
          theme_name: "",
          shareUrl: "",
          fizzId: props.match.params.fizzId,
          isLoading: true,
          errorMessage: '',
          img:null,
          redirectLabel: 'Browse Video Greetings',
          error:null
        };
      }

      componentDidMount() {
        this._getData();
      }

      _getData() {

        var fizz = MemCache.getItem('fizz');
            console.log( "cached fizz",fizz );
        if( fizz && fizz.id == this.state.fizzId ){    
          console.log( { fizzID: fizz.id, img: fizz.img })
          this.setState({
            img:fizz.img,
            fizz: fizz,
          });
        }

        apiFetch(
          "/rest/fizz/" + this.state.fizzId  + "/detail",
          {
            method: "GET",
          },
          (json) => {
            console.log(" JSON STRINGIFY RESPONSE" + json);

            this.setState({
              name: json.item.name,
              hasPaid: json.item.hasPaid,
              shareUrl: json.item.shareUrl,
              downloadUrl: json.item.downloadUrl,
              isLoading: false,
              theme_name: json.item.name,
              price: json.item.price,
              img: json.item.img,
              isPhysical: json.item.isPhysical,
              sku: json.item.sku,
              video: json.item.video
            });

            //Tracking Info
            window.adobeDataLayer = { 
              occasion: json.item.themeName,
              type: 'Physical',
              sku: json.item.sku,
              price: ( json.item.isFree || json.item.isPhysical ) ? null : json.item.price,
              fizzUUID: json.item.uuid
            };
            TrackingHelper.trackPage();
            document.title = 'Thank you | Video Greeting Cards | Hallmark';
            document.getElementsByTagName( 'meta' )[3].content = "";
            
          },
          (error) => {
            console.log(error)
            this.setState({
              isLoading: false,
              error: error.message + ""
            })
          }
        );
      };

    gotoBrowsing = () => {
      window.location = "/browse";
    }

    copyToClipboard=()=>{
      navigator.clipboard.writeText(this.state.shareUrl);
      toastManager.showToast({
        message: 'Copied to Clipboard!'
      });

    }
    shareAny=()=>{
      navigator.share({
        url: this.state.shareUrl
      }).then(() => {
        // alert("Sent");
      }).catch((e)=>{
        // Canceled or something else
        // alert("Error"+e);
      })
    }

  
    render(){
      var hasClipboard = navigator && navigator.clipboard && navigator.clipboard.writeText;
      let smsLink = getSMSLink({body:this.state.shareUrl});
      let emailLink = getMailToLink({body:this.state.shareUrl});
      
      if( this.state.isLoading ){
        return(
          <div className="screen-wrap">
            <NavBar/>
            <MainContent className="container text-center pt-5">
              <Spinner/>
            </MainContent>
            <Footer hideMobile={true} />
          </div>
        )
      }

      // if( this.state.showScanMore ){
        return(
          <div className="screen-wrap">
          <NavBar/>
          <MainContent className="container">

            <div className="row mt-2">
              <div className="col-md-12 text-left">  
                <h1 className="headline-text">Nice job! Now for the best part...</h1>
                <p className="subheadline mt-3">
                  Now you can mail or give the cards to all your friends and family. Once they scan the code inside the card, they’ll see your Video Greeting, and feel all the love you put into it. 
                </p>
              </div>
            </div>


            <div className="row mt-2">
             
              <div className="col-md-8 pt-4 mb-4">
                <Plyr
                  ref={this.plyr}
                  type="video" // or "vimeo"
                  poster={this.state.img}
                  url={this.state.video}
                  controls={['play-large']}
                />

                <p className="subheadline mt-3 mb-1">
                  Share this Video Greeting digitally or save a copy.
                </p>
              
                { this.state.shareUrl &&
                  <>
                    { canShare() ?
                      
                      <button className="btn btn-link blue-link btn-padded" onClick={this.shareAny}>
                      <i className="icon-mask" style={{ WebkitMaskImage:"url("+ShareIcon+")"}} alt="Share Icon"></i>
                      Share this video
                      </button>
                    :
                      <>
                        <a href={emailLink} className="blue-link btn-padded">
                        <i className="icon-mask" style={{ WebkitMaskImage:"url("+MailIcon+")" }} alt="MailIcon"></i>
                        Email
                        </a>
                        
                        { smsLink && 
                          <div>
                          <a className="blue-link d-block d-md-none btn-padded pb-0" href={smsLink}>
                          <i className="icon-mask" style={{ WebkitMaskImage:"url("+SMSIcon+")"}} alt="Text Icon"></i>
                          Text message
                          </a>
                          </div>
                        }
                        
                        { hasClipboard &&
                          <div>
                          <button className="link blue-link btn-padded pt-2" onClick={this.copyToClipboard} >
                          <i className="icon-mask" style={{ WebkitMaskImage:"url("+ShareIcon+")"}} alt="Copy Icon"></i>
                          Copy link to paste anywhere
                          </button>
                          </div>
                        }
                      </>
                    }
                  
                    <div>
                      <a className="blue-link btn-padded tag-download-user" href={this.state.downloadUrl}>
                        <i className="icon-mask" style={{ WebkitMaskImage:"url("+DownloadIcon+")"}} alt="Download Icon"></i>
                        Download and save
                      </a>
                    </div>

                  </>
                }
              
              </div>

              <div className="col-md-4 text-left mb-4">  

                <h5 className="headline-text">
                  Need to link this video to another boxed set of cards?
                </h5>

                
                <p className="subheadline mt-3 mb-3">
                  Open your phone’s camera and scan the code <span className="font-weight-bold" >inside one of the cards</span> in your next box.
                </p>

                <div className="col-12 p-0">
                  <img className="mw-100" src={QRBoxLinkImg} alt="Linking video to QR box" />
                </div>

                <h5 className="headline-text">
                  Need to order another boxed set?
                </h5>

                <div className="mt-2 mb-3 ">
                  <a href="https://www.hallmark.com/video-greeting-cards/?prefn0=cards&prefv0=Boxed+Cards&srule=featured-best-sellers&icid=vg-thank-you#products" className="btn btn-outline-primary btn-block btn-lg-inline-block">
                      Shop the Cards
                  </a>
                </div>
              </div>
            </div>
            

            <SharePopup
                sendToast={this.props.sendToast}
                show={this.state.sharePopup}
                onHide={this.closePopup}
                info={this.state.sharePopup}
                sharetype={this.state.sharetype}
              />
        
          </MainContent>
          <Footer hideMobile={true} />
        </div>
        )
    }
}


export default QRBoxThankYou;
