import React from 'react';


export default class Spinner extends React.Component {

  constructor(props) {
    super(props);
  }

  
  render() {

  	var spinner = (
      <div className={'lds-spinner '+ (this.props.className || 'black') }><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    );


    if( this.props.float ) {
    	return (
            <div className="floating-spinner">
            	{spinner}
            </div>
    	);
    } else {
    	return spinner
    }
  }
}