import React from "react";

export default function NavLogo() {

  return (
    <nav className="navbar d-block text-center nav-shadow-alltime" >
      <img
		src={process.env.PUBLIC_URL + "/img/hmk-logo-horizontal.svg"}
		alt="Hallmark Video Greeting Cards"
        height="61"
      />
    </nav>
  );
}