import React, { Component } from "react";
import { Button,Modal } from 'react-bootstrap';
import "../assets/css/LandingPage.css";
import { apiFetch } from '../helpers/APIHelper';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Plyr from 'react-plyr';
import TrackingHelper from "../helpers/TrackingHelper";

const responsive = {
  md_lg_xl: {
    breakpoint: { max:99999, min: 768 },
    items: 3,
  },
  sm: {
    breakpoint: { max: 768, min: 576 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 120 // this is needed to tell the amount of px that should be visible.
  },
  xs:{
    breakpoint: { max: 576, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 60 // this is needed to tell the amount of px that should be visible.
  }
};

export class LandingVideo extends Component {

  constructor(props){
    super(props);

    this.state = {
      showPlayer: false,
      videoUrl: null,
      samples: []
    }
  }

  componentWillMount() {
    this.getData();
  }

  getData = () => {
    this.setState({
      loading: true,
    });

    if( this.props.samples ) {
      // NO NEED TO MAKE AN API CALL WE ALREADY HAVE THEM
      this.setState({
        samples:this.props.samples
      });
    } else {

      apiFetch( "/rest/config/sample-videos", {
          method: "GET",
        },
        (json) => {
          console.log( json)
          if (json && json.status === "success") {
            this.setState({
              samples:  json.samples
            });
          }
        },
        (error) => {
          console.log(error);
          // ok to not display error Eric and JD.
        }
      );

    }
  };

  closeVideo = () => {
    this.setState({ 
      videoUrl: null,
      showPlayer:false
     });
  }

  showVideo(type) {
    this.setState({
      videoUrl:type,
      showPlayer: true
    });

    TrackingHelper.trackPreview();
  }

  render() {
    return (
      <div className="video-segment">
        <div className="container-md" >
          <div className="text-center pt-5">
            <h5 className="landing_title" >Make it a group surprise!</h5>
            <p className="video_clips">
            Collect photos and videos from others, too. Easily invite them with just a link—no signups or spam involved. There’s no limit to how many people you can invite, or how many photos and videos you upload, either.
            </p>
          </div>
        </div>
        <section className="container-md  mt-4 pb-5 mb-4 p-0">
          
              <Carousel
                responsive={responsive}
                showDots={true}
                removeArrowOnDeviceType={["xs","sm","md_lg_xl"]}
                dotListClass="custom-dot-list-style"
                partialVisible ={true}

              >
                { this.state.samples.map( ( item, i )  =>
                  <div className="step_container"  key={i}>
                    <div className="notSelectable">
                      <button onClick={this.showVideo.bind(this, item.video)} 
                        className="btn btn-img video-thumb notSelectable">
                        <img src={item.img} alt="video" />
                        <span className="label-corner">{item.label}</span>
                      </button>
                    </div>
                  </div>
                  
                )}
              </Carousel>
        </section>

      <Modal size="lg" show={this.state.showPlayer} onHide={this.closeVideo}>
        <Modal.Body>
          
          <Plyr type="video" // or "vimeo"
            url={this.state.videoUrl} autoplay
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeVideo}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      </div>
    );
  }
}

export default LandingVideo;
