import React, { Component } from 'react';
import SpecialField from '../components/SpecialField';

export default class SpecialFieldEditor extends Component {

	constructor(props) {
		super(props);

		this.state = {
		}
	}
	onFieldChange = (field) => {
        if( this.props.onSpecialFieldsChange ) {
			console.log("change1" + this.props.onSpecialFieldsChange)
        	this.props.onSpecialFieldsChange(this.props.specialFields);
        }
	}

	render() {
		if( !this.props.specialFields || this.props.specialFields.length<=0 ) {
			return null;
		}

		return (
			<div>
				{this.props.specialFields.map((fieldGroup, index) => {
					return (
						<SpecialField key={"field-"+index} fieldGroup={fieldGroup} onFieldChange={this.onFieldChange} />
					)
				})}
			</div>
		)

	}
}