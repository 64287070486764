import React, { Component } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { apiFetch } from "../helpers/APIHelper";
import IdObjectMemCache from "../helpers/IdObjectMemCache";
import BackLink from "../components/BackLink";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import menuclose from "../assets/images/HMK-Icons/Icons_Menu Close_Bold.svg";
import TrackingHelper from "../helpers/TrackingHelper";
import MainContent from "../components/MainContent";
export class RecordPreviewFizz extends Component {
  constructor(props) {
    super(props);

    this.isFizzCheckingAllowed = true;

    this.state = {
      isLoading: true,
      fizzId: props.match.params.fizzId,
      isDone: false,
      isStatus: false,
      video: null,
      isVideoFinalised: false,
      showFinalClip: false,
      img: null,
    };
  }

  componentDidMount() {
    this.combineClips();

    document.title = 'Record Preview | Video Greeting Cards | Hallmark';
    document.getElementsByTagName( 'meta' )[3].content = "";
    TrackingHelper.trackPage();
  }

  componentWillUnmount() {
    clearTimeout(this.checkFizzTimeout);
    this.isFizzCheckingAllowed = false;
  }

  closeCurrentVideo = () => {
    // alert("close working");
    this.setState({
      showFinalClip: false,
    });
  };

  combineClips() {
    apiFetch(
      "/rest/fizz/" + this.state.fizzId + "/new-finalize",
      {
        method: "PUT",
      },
      (json) => {
        this.setState({
          img: json.img,
        });

        IdObjectMemCache("fizz").setItem({
          id: this.state.fizzId,
          img: json.img,
        });

        this.checkProcessingFizzes();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  checkProcessingFizzes() {
    var needsCheck = false;

    apiFetch(
      "/rest/fizz/process/" +
        this.state.fizzId +
        "?cache=" +
        Math.round(Math.random() * 100000000),
      {
        method: "GET",
      },
      (json) => {
        console.log(" JSON STRINGIFY RESPONSE" + json);
        this.setState({
          isDone: json.isDone,
          isStatus: json.status,
          isLoading: false,
          video: json.video,
        });

        if (!json.isDone && this.isFizzCheckingAllowed) {
          this.checkFizzTimeout = setTimeout(() => {
            this.checkProcessingFizzes();
          }, 3000);
        } else {
          TrackingHelper.trackPreview();
        }
      },
      (error) => {
        console.log("checking error code");
        console.log(error);
      }
    );
  }

  showFinalClipModal = () => {
    this.setState({ showFinalClip: true });
  };

  continueFinalClipmodal = () => {
    this.setState({ showFinalClip: false });
    window.location = "/checkout/" + this.state.fizzId;
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    console.log("videoimage " + this.state.isVideoImg);
    return (
      <div className="screen-wrap">
        <NavBar />
        <MainContent className="container bg-margin">
          <BackLink
            to={"/edit/"+this.state.fizzId }
            title="Back to Edit"
          />
          <h1 className="headline">Record</h1>

          <div className="text-center">
            {this.state.isLoading ? (
              <div className="floating-spinner text-center mt-4 mt-4">
                <Spinner />
              </div>
            ) : (
              <div className="position-relative">
                {!this.state.isDone && (
                  <div
                    className="floating-spinner"
                    style={{
                      position: "absolute",
                      width: "100%",
                      top: 0,
                      height: "100%",
                    }}
                  >
                    <Spinner />
                  </div>
                )}
                <img
                  className="video-fit"
                  src={this.state.img}
                  alt="fizz video"
                ></img>
              </div>
            )}
          </div>

          <div className="row mt-3 text-center">
            <div className="col-12 col-lg-12 text-center  mb-3">
              <button
                type="button"
                className="btn btn-primary w-100 w-lg-33"
                onClick={this.goBack}
              >
                Send
              </button>
            </div>
          </div>

          <div className="row mt-3 text-center">
            {this.state.isDone && (
              <div className="col-12 col-lg-6 text-center text-lg-right mb-3">
                <button
                  type="button"
                  className="btn btn-primary w-100 w-lg-33"
                  onClick={this.showFinalClipModal}
                >
                  FINALIZE
                </button>
              </div>
            )}

            <div
              className={
                "col-12 text-center " +
                (this.state.isDone ? "col-lg-6  text-lg-left" : "")
              }
            >
              <button
                type="button"
                className="btn btn-outline-primary w-100 w-lg-33"
                onClick={this.goBack}
              >
                RECORD AGAIN
              </button>

              {/* <button
                type="button"
                className="btn btn-outline-primary w-100 w-lg-33"
                onClick={this.goBack}
              >
                BACK TO EDIT
              </button> */}
            </div>
          </div>

          <Modal
            show={this.state.showFinalClip}
            onHide={this.closeCurrentVideo}
          >
            <Modal.Header className="text-left">
              <div className="icon-box" onClick={this.closeCurrentVideo}>
                <span className="close">
                  <img src={menuclose} alt="menuclose" />
                </span>
              </div>

              <h3 className="headline">Are you sure?</h3>
            </Modal.Header>
            <Modal.Body>
              You won't be able to edit your video greeting after this.
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-primary btn-block"
                onClick={this.continueFinalClipmodal}
              >
                YEP, IT'S DONE
              </Button>

              <button
                className="btn btn-outline-primary btn-block"
                onClick={this.closeCurrentVideo}
              >
                CANCEL
              </button>
            </Modal.Footer>
          </Modal>
        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }
}

export default RecordPreviewFizz;
